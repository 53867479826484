import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { setPreventScroll, clearPreventScroll } from 'components/utils/usePreventScroll';

import Checkbox from './Checkbox';
import phoneImg from 'resources/phone.png';
import closeButtonImg from 'resources/close.png';
import { createNiceCertificationSencdata } from 'api/API';
import { useNavigate } from 'react-router-dom';
import theme from 'styles/theme';

const PurchasePopUp = ({ selectedModel, visible, handleClose }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  const [allAgree, setAllAgree] = useState(false);

  const handleAllAgree = () => {
    setAllAgree(!allAgree);
    if (agree1 === true && agree2 === true) {
      setAgree1(false);
      setAgree2(false);
    } else {
      setAgree1(true);
      setAgree2(true);
    }
  };

  const [agree1, setAgree1] = useState(false);

  const handleAgree1 = () => {
    setAgree1(!agree1);
  };

  const [agree2, setAgree2] = useState(false);

  const handleAgree2 = () => {
    setAgree2(!agree2);
  };

  const certPopup = useRef();

  useEffect(() => {
    return () => certPopup.current?.close();
  }, []);

  useEffect(() => {
    if (agree1 === false || agree2 === false) {
      setAllAgree(false);
    }
  }, [agree1, agree2]);

  if (!visible) return null;

  const handleCertification = () => {
    if (!agree1 || !agree2) {
      alert('필수 약관에 동의해 주세요.');
      return;
    }
    console.log('selectedModel', selectedModel, window?.location?.origin);
    createNiceCertificationSencdata(selectedModel, window?.location?.origin)
      .then((res) => {
        const responseData = res.data;
        console.log('encodedData', responseData);
        document.form_chk.EncodeData.value = responseData;
        if (certPopup.current) {
          certPopup.current.close();
        }
        certPopup.current = window.open(
          '',
          'popupChk',
          'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
        );
        document.form_chk.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        document.form_chk.target = 'popupChk';
        document.form_chk.submit();
      })
      .catch((err) => {
        console.error(err);
        alert('본인인증 요청에 실패했습니다.');
      })
      .finally(() => {});
  };

  window.goToNextPage = (encodeData) => {
    navigate(
      `/reservation/payment?encodeData=${encodeURIComponent(
        encodeData,
      )}&selectedModel=${selectedModel}`,
    );
  };

  return (
    <Container>
      <DarkBackground />
      <PopupContainer>
        <Title>비회원 구매정보 수집 이용 동의서</Title>
        <ContentText>
          지앤더블유랩 주식회사(이하 회사’라 합니다)는 서비스 이용시 이용자로부터 아래와 같은
          개인정보를 수집하고 있습니다. 이용자는 본 개인정보 수집·이용 동의서에 따른 동의 시,
          &quot;필요한 최소한의 정보 외의 개인 정보&apos;＇수집 이용에 동의하지 아니할 권리가
          있습니다. 개인정보 처리에 대한 상세한 사항은 지앤더블유랩 홈페이지 (https://www.이하
          주소)에 공개한 개인정보처리방침을 참조하십시오. 다만, 본 동의서 내용과 상충 되는 부분은 본
          동의서의 내용이 우선합니다
        </ContentText>
        <CheckboxWrap>
          <Checkbox
            key="allAgree"
            defaultValue={false}
            type="checkbox"
            name="allAgree"
            handleClick={handleAllAgree}
            isChecked={allAgree}
          >
            모든 약관을 확인하고 전체 동의합니다.
          </Checkbox>
        </CheckboxWrap>
        <NoticeWrap>
          <NoticeLabel>비회원 필수 개인정보 수집 이용 동의서</NoticeLabel>
          <Notice>
            <ContentText>
              ※ 귀하께서는 필수항목 수집이용에 대한 동의를 거부하실 수 있으나, 이는 서비스 제공에
              필수적으로 제공 되어야 하는 정보이므로, 동의를 거부하실 경우 온라인 구매를 진행하실 수
              하실 수 없습니다.
              <br />
              <br />
              · 수집항목
              <br />
              - 주문자 정보 : 이름, 휴대전화번호, 이메일
              <br />- 수령인 정보 : 이름, 휴대전화번호
            </ContentText>
          </Notice>
          <CheckboxWrap>
            <Checkbox
              key="agree1"
              defaultValue={false}
              type="checkbox"
              name="agree1"
              handleClick={handleAgree1}
              isChecked={agree1}
            >
              필수 개인정보 수집·이용 동의서에 동의합니다. (필수)
            </Checkbox>
          </CheckboxWrap>
        </NoticeWrap>
        <NoticeWrap>
          <NoticeLabel>(비회원의) 개인정보 제3자 제공 동의서 (필수)</NoticeLabel>
          <Notice>
            <ContentText>
              회사는 수집된 개인정보를 이용자의 동의가 있는 경우에 한하여 아래와 같이 제3자에
              제공하고 있습니다.
              <br />
              <br />※ 귀하께서는 귀하의 개인정보를 제3에게 제공하는 것에 대한 동의를 거부하실 수
              있으나, 이는 서비스 제공에 필수적으로 제공되어야 하는 정보이므로, 동의를 거부하실 경우
              필수적인 서비스(주문, 배송)와 기타 편의 제공(사은품, 할인제공 등)을 하실 수 없습니다
            </ContentText>
          </Notice>
          <CheckboxWrap>
            <Checkbox
              key="agree2"
              defaultValue={false}
              type="checkbox"
              name="agree2"
              handleClick={handleAgree2}
              isChecked={agree2}
            >
              필수 개인정보 수집·이용 동의서에 동의합니다. (필수)
            </Checkbox>
          </CheckboxWrap>
        </NoticeWrap>
        <ListWrap>
          <List>
            고객님께서는 상기 항목에 동의를 거부할 수 있으나, 거부시 결제/배송 서비스에 제한이 있을
            수 있습니다.
          </List>
          <List>
            원활한 배송을 위해 유선 전화번호나 배송 메시지를 입력하고 싶은 경우에는 반드시 부가
            개인정보(선택)의 수집 및 이용에 동의하여 주십시오.
          </List>
        </ListWrap>
        <VerificationWrap>
          <NoticeLabel>본인인증</NoticeLabel>
          <ContentText>
            지앤더블유랩은 만 14세 미만 아동고객의 이용을 제한하여 연령 확인을 위한 본인인증 절차가
            필요합니다.
          </ContentText>
          <VerificationButton onClick={handleCertification}>
            <VerificationButtonText>[NICE 평가정보를 통한 본인 인증]</VerificationButtonText>
            <VerificationButtonImg src={phoneImg} alt="phone-img" />
            <VerificationButtonText>휴대폰 인증</VerificationButtonText>
          </VerificationButton>
          <form name="form_chk" method="post">
            <input type="hidden" name="m" value="checkplusService" />
            <input type="hidden" name="EncodeData" value="" />
          </form>
        </VerificationWrap>
      </PopupContainer>
      <CloseButton onClick={handleClose}>
        <CloseButtonImg src={closeButtonImg} alt="close-button-img" />
      </CloseButton>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;

  @media ${theme.mobile} {
    height: 90vh;
  }
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
`;

const PopupContainer = styled.div`
  position: relative;
  width: 900px;
  height: 700px;
  padding: 60px 65px;
  background-color: #e6e6e6;
  border: 1px solid #d6d6d6;
  border-radius: 20px;
  z-index: 21;
  overflow-y: scroll;

  @media ${theme.laptop} {
    width: 80%;
    height: 600px;
    padding: 30px 35px;
    background-size: cover;
  }

  @media ${theme.mobile} {
    width: 360px;
    flex: 1;
    padding: 30px 35px;
    background-size: cover;
  }
`;

const Title = styled.p`
  font-family: nanumGothicB;
  font-size: 24px;
  color: #000000;
  letter-spacing: -0.7;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #292d32;
  margin: 0 0 34px 0;

  @media ${theme.mobile} {
    font-size: 20px;
  }
`;

const ContentText = styled.p`
  font-family: nanumGothicR;
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.4;
  line-height: 22.4px;

  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

const CheckboxWrap = styled.div`
  margin: 20px 0 0 0;
`;

const NoticeWrap = styled.div`
  margin: 70px 0 0 0;

  @media ${theme.mobile} {
    margin: 35px 0 0 0;
  }
`;

const NoticeLabel = styled.p`
  font-family: nanumGothicB;
  font-size: 18px;
  color: #000000;
  letter-spacing: -0.5;
  margin: 0 0 20px 0;
`;

const Notice = styled.div`
  background-color: #d9d9d9;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px inset;
  padding: 29px 20px 18px;
  height: 170px;
  overflow-y: scroll;
`;

const ListWrap = styled.ul`
  margin: 70px 0 40px 0;
  padding: 0 0 0 25px;
`;

const List = styled.li`
  font-family: nanumGothicR;
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.4;
  line-height: 22.4px;
  list-style-type: disc;
`;

const VerificationWrap = styled.div`
  border-top: 1px solid #292d32;
  padding: 60px 0 0 0;

  @media ${theme.mobile} {
    padding: 30px 0 0 0;
  }
`;

const VerificationButton = styled.button`
  width: 395px;
  height: 170px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 45px auto 0;
  display: block;

  @media ${theme.mobile} {
    width: 247px;
    height: 125px;
    margin: 25px auto 0;
  }
`;

const VerificationButtonImg = styled.img`
  width: 41px;
  height: 75px;
  margin-top: 8px;

  @media ${theme.mobile} {
    width: 31px;
    height: 55px;
    margin-top: 4px;
  }
`;

const VerificationButtonText = styled.p`
  font-family: nanumGothicB;
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.4;
  margin: 10px 0 0 0;

  @media ${theme.mobile} {
    font-size: 12px;
    margin: 4px 0 0 0;
  }
`;

const CloseButton = styled.button`
  z-index: 1;
  background-color: transparent;
`;

const CloseButtonImg = styled.img`
  width: 50px;
  height: 50px;
  margin: 25px 0 0 0;
`;

export default PurchasePopUp;
