import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { setPreventScroll, clearPreventScroll } from 'components/utils/usePreventScroll';
import theme from 'styles/theme';

import Checkbox from './Checkbox';
import phoneImg from 'resources/phone.png';
import closeButtonImg from 'resources/close.png';

const TermsPopUp = ({ visible, handleClose }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  const [allAgree, setAllAgree] = useState(false);

  const handleAllAgree = () => {
    setAllAgree(!allAgree);
    if (agree1 === true && agree2 === true) {
      setAgree1(false);
      setAgree2(false);
    } else {
      setAgree1(true);
      setAgree2(true);
    }
  };

  const [agree1, setAgree1] = useState(false);

  const handleAgree1 = () => {
    setAgree1(!agree1);
  };

  const [agree2, setAgree2] = useState(false);

  const handleAgree2 = () => {
    setAgree2(!agree2);
  };

  useEffect(() => {
    if (agree1 === false || agree2 === false) {
      setAllAgree(false);
    }
  }, [agree1, agree2]);

  if (!visible) return null;
  return (
    <Container>
      <DarkBackground />
      <PopupContainer>
        <Title>이용약관</Title>
        <ContentText>
          제1장 총칙
          <br />
          <br />
          제1조(목적)
          <br />
          이 약관은 지앤더블유랩 주식회사(이하 "회사"라 합니다)가 운영하는 지점 및 온라인
          쇼핑몰(http://www.이하주소), 모바일 애플리케이션에서 제공하는 재화 거래 기타 서비스(이하
          "서비스"라 합니다)를 이용함에 있어 회사와 이용자의 권리•의무 기타 제반사항에 관하여
          규정함을 목적으로 합니다.
          <br />
          <br />
          제2조(정의)
          <br />
          “회사”란 지앤더블유랩 주식회사의 재화 또는 용역(이하 “재화 등”이라 합니다)을 거래할 수
          있는 컴퓨터, 휴대폰, 단말기 등 전기통신설비를 이용하여 설정한 가상의 영업장 또는 위
          영업장을 운영하는 사업자인 지앤더블유랩 주식회사을 의미합니다.
          <br />
          “이용자”란 회사에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 이용하거나 이용하고자
          하는 비회원을 의미합니다.
          <br />
          “비회원”이란 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 의미합니다.
          <br />
          “접근권한”이란 이용자의 이동통신단말장치 내에 저장되어 있는 정보 및 이동통신단말장치에
          설치된 기능에 대하여 접근할 수 있는 권한을 의미합니다.
          <br />
          <br />
          <br />
          제3조(약관의 명시와 개정)
          <br />
          회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자, 사업자 등록번호, 연락처(전화, 팩스,
          전자우편 등) 등을 이용자가 알 수 있도록 회사의 인터넷 홈페이지 전면에 게시합니다. 다만,
          약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.
          <br />
          회사는 이용자가 회원으로 가입하고자 하는 경우 서면 또는 전자적 방법에 의하여 약관의 내용을
          제공하며, 청약 철회, 배송 책임, 환불 조건 등과 같은 중요한 내용을 이용자가 이해할 수
          있도록 안내합니다.
          <br />
          회사는 약관의 규제 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률, 전자문서 및
          전자거래기본법, 전자서명법, 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
          법률, 방문판매 등에 관한 법률, 소비자기본법 등 관련법령을 위배하지 않는 범위에서 이 약관을
          개정할 수 있습니다.
          <br />
          회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 회사의
          인터넷 홈페이지 전면에 그 적용일자 7일 이전부터 적용 일자 전일까지 공지합니다. 다만,
          이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예 기간을 두고
          공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확히 비교하여 이용자가 알기
          쉽도록 표시합니다.
          <br />
          회사가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고
          그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미
          구매계약을 체결한 이용자가 변경된 조항의 적용을 받기를 원하는 뜻을 상기 제4항에 의한
          변경약관의 공지기간 내에 회사에 송신하여 회사의 동의를 받은 경우에는 변경된 조항이
          적용됩니다.
          <br />
          회사가 이용자에게 일정한 기간 내에 별도의 의사표시를 하지 않으면 개정약관에 동의한 것으로
          본다는 뜻을 명확하게 공지 또는 고지한 경우 위 기간이 경과한 때에 이용자가 개정약관에
          동의한 것으로 봅니다. 이 경우, 이용자가 개정약관의 적용을 원하지 않는다면 즉시 이용계약을
          해지할 수 있습니다.
          <br />
          이 약관에서 정하지 아니한 내용과 이 약관의 해석에 관하여는 약관의 규제에 관한 법률,
          전자상거래 등에서의 소비자보호에 관한 법률 기타 관계법령 및 상관례에 따릅니다.
          <br />
          <br />
          <br />
          제4조(서비스의 제공 및 변경)
          <br />
          회사는 다음과 같은 서비스를 이용자에게 제공합니다.
          <br />
          가.재화 등에 대한 정보 제공 및 구매계약의 체결
          <br />
          나.구매계약이 체결된 재화 등의 배송
          <br />
          다.기타 회사가 정하는 업무
          <br />
          회사는 재화 등의 품절, 기술적 사양의 변경 기타 불가피한 사정이 있는 경우 장차 체결되는
          계약에 의해 제공할 재화 등의 내용을 변경할 수 있습니다. 이 경우에는 변경된 상품 또는
          용역의 내용 및 제공일자를 명시하여 현재의 상품 또는 용역의 내용을 게시한 곳에 즉시
          공지합니다.
          <br />
          회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의
          변경 등의 사유로 변경할 경우에는 사유를 이용자에게 통지 가능한 주소 또는 연락처로 즉시
          통지합니다.
          <br />
          전항의 경우 회사는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, 회사가 고의 또는
          과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
          <br />
          <br />
          <br />
          제5조(서비스의 중단)
          <br />
          회사는 컴퓨터, 단말기 등 전기통신설비의 보수•점검•교체 및 고장, 통신의 두절 등의 사유가
          발생한 경우에는 온라인 서비스의 제공을 일시적으로 중단할 수 있습니다.
          <br />
          회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가
          입은 손해에 대하여 배상합니다. 단, 회사가 고의 또는 과실이 없음을 입증하는 경우에는
          그러하지 아니합니다.
          <br />
          사업 종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는
          경우에는 회사는 제8조에 정한 방법으로 이용자에게 통지하고 당초 회사에서 제시한 조건에 따라
          소비자에게 보상 합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는 이용자의
          포인트 등을 국내에서 통용되는 통화가치로 환산하여 이에 상응하는 현물 또는 현금으로
          지급합니다.
          <br />
          <br />
          <br />
          제2장 구매계약
          <br />
          <br />
          제6조(구매신청)
          <br />
          회사 이용자는 회사에서 다음과 같은 방법으로 구매를 신청하며, 회사는 이용자가 구매신청을
          함에 있어서 해당 내용을 알기 쉽게 제공하여야 합니다.
          <br />
          ①재화 등의 검색 및 선택
          <br />
          ②회사의 이용약관 및 개인정보 수집•제공•위탁 등에 대한 동의 (비회원의 경우)
          <br />
          ③받는 사람의 성명, 주소, 전화번호, 이메일, 휴대전화번호 등의 입력
          <br />
          ④청약철회권의 제한, 배송료•설치비 등 비용부담 기타 구매계약 관련사항에 대한 확인
          <br />
          ⑤위 4호의 사항을 확인하거나 거부하는 표시 (예. 마우스 클릭)
          <br />
          ⑥재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의
          <br />
          ⑦결제방법의 선택 및 대금결제
          <br />
          이용자가 전화로 구매신청을 하는 경우 신용카드 또는 무통장입금에 의한 대금결제만
          가능합니다.
          <br />
          <br />
          제7조(계약의성립)
          <br />
          회사는 제6조 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 단,
          미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
          법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
          <br />
          가.신청 내용에 허위, 기재누락, 오기가 있는 경우
          <br />
          나.미성년자가 청소년보호법에서 금지하는 재화 등을 구매하는 경우
          <br />
          다.국외 배송의 경우
          <br />
          라.기타 구매신청에 승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단하는 경우
          <br />
          회사의 승낙이 제10조 제1항의 수신확인통지 등의 형태로 이용자에게 도달한 시점에 계약이
          성립한 것으로 봅니다.
          <br />
          회사의 승낙의 의사표시에는 이용자의 구매신청에대한 확인 및 판매가능 여부, 구매신청의 정정
          취소 등에 관한 정보 등을 포함하여야 합니다.
          <br />
          회사는 이용자가 회사의 사전 승인 없이 재판매 등 부당한 목적으로 재화를 구매하는 경우
          이용자의 구매청약을 거절하거나, 승낙한 이후에도 계약을 취소할 수 있으며, 다음 각 호의 경우
          재판매 등 부당한 목적으로 구매하는 경우로 보아 청약 거절 등의 조치를 할 수 있습니다.
          <br />
          1) 이용자가 동일한 ID로 3개월 이내에 동일한 상품을 3개 이상 주문한 경우
          <br />
          2) 이용자가 동일한 주소로 3개월 이내에 동일한 상품을 3개 이상 주문한 경우
          <br />
          3) 이용자의 사업자등록증의 사업 종목에 가전제품, 컴퓨터, 통신장비, 부품 등이 기재된 경우
          기타 회사와 동일하거나 유사한 사업을 영위하는 것으로 판단되는 경우로서 상품을 1개 이상
          주문한 경우
          <br />
          이용자가 최근 1년 이내에 재판매 등 부당한 목적으로 재화를 구매하였거나 제4항 각호에
          해당하는 경우, 구매계약 성립의 취소, 계약의 해지, 회원자격상실에 따른 구매 제한이 될 수
          있으며, 회사는 이용자를 대상으로 진행하는 모든 할인, 증정, 사은 등 일체의 행사에 관하여
          해당 이용자의 참여, 적용 및 당첨을 제한할 수 있습니다.
          <br />
          <br />
          <br />
          제3장 대금결제
          <br />
          <br />
          제8조(지급방법)
          <br />
          회사에서 구매한 재화 등에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로 할 수
          있습니다. 단, 회사는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도
          추가하여 징수할 수 없습니다.
          <br />
          ①회사가 승인하는 신용카드의 카드 결제
          <br />
          ②기타 전자적 지급 방법에 의한 대금 지급 등<br />
          <br />
          <br />
          제9조(해외에서의 대금결제)
          <br />
          해외에서 구매 신청을 하는 경우에는 반드시 회사가 승인하는 신용카드로 대금을 결제하여야
          합니다. 다만, 제10조 제1항에 따라 구매계약이 성립하지 않을 수 있습니다.
          <br />
          <br />
          제10조(수신확인통지•구매신청 변경 및 취소)
          <br />
          회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
          <br />
          수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후
          즉시 구매신청 변경 및 취소를 요청할 수 있고, 회사는 배송 전에 이용자의 요청이 있는
          경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제
          17조의 청약철회 등에 관한 규정에 따릅니다.
          <br />
          <br />
          제11조(연체에 따른 절차)
          <br />
          이용자가 신용카드에 의하여 대금을 결제하였으나 이용자의 귀책사유로 인하여 해당
          신용카드회사로부터 대금을 지급받지 못한 경우 회사는 즉시 이용자의 회사의 서비스 이용을
          제한 또는 정지시킬 수 있습니다. 이 경우 이용자는 즉시 대금결제 방법을 변경하거나 회사가
          신용카드회사로부터 대금을 지급받을 수 있도록 필요한 조치를 취하여야 합니다.
          <br />
          전항의 경우 이용자가 회사에 등록한 연락처로 연락이 되지 않거나 이용자가 필요한 조치를
          취하지 않는 경우회사는 이용자와의 구매계약을 취소 또는 해제할 수 있으며, 회원자격을
          상실시킬 수 있습니다.
          <br />
          제4장 배송/취소/환불
          <br />
          <br />
          제12조(배송)
          <br />
          회사는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한
          날로부터 7일 이내에 재화 등을 배송할수 있도록 주문제작, 포장 등 기타의 필요한 조치를
          취합니다. 이때 회사는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인 할 수 있도록 적절한
          조치를 합니다.
          <br />
          회사는 이용자가 구매한 상품에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
          명시합니다. 만약 회사가 약정배송기간을 초과한 경우에는 그로 인한 이용자의 손해를
          배상하여야 합니다. 다만, 회사가 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
          <br />
          회사는 이용자의 요청이나 동의가 있는 경우 이용자의 휴대폰으로 교환권 등을 발송하거나
          이용자가 회사 또는 회사가 지정하는 매장에 방문하여 재화 등을 수령하는 방법으로 제1항의
          배송을 갈음할 수 있습니다.
          <br />
          <br />
          <br />
          제13조(환급)
          <br />
          회사는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는
          지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은
          날부터 3영업일 이내에 환급하거나, 환급에 필요한 조치를 취합니다.
          <br />
          <br />
          제14조(청약 철회 등)
          <br />
          회사와 재화 등의 구매에 관한 계약을 체결한 이용자는계약 내용에 관한 서면을 교부 받은 날
          (그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화
          등의 공급이 시작된 날을 말합니다) 부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만
          청약철회에 관하여 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령에 달리 정함이
          있는 경우에는 해당 법령에 따릅니다.
          <br />
          이용자는 재화 등을 배송 받은 경우 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수
          없습니다.
          <br />
          가.이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우
          <br />
          나.이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
          <br />
          다.시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의가치가현저히 감소한 경우
          <br />
          라.같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
          <br />
          마.그 밖에 거래의 안전을 위하여 관련 법령이 정하는 경우
          <br />
          제2항 가호 내지 라호의 경우에는 회사가 사전에 청약 철회 등이 제한되는 사실을 소비자가 쉽게
          알 수 있는 곳에 명기하거나 시용 상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약
          철회 등이 제한되지 않습니다.
          <br />
          이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시, 광고 내용과 다르거나
          계약 내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날
          또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
          <br />
          <br />
          제15조(청약철회 등의 효과)
          <br />
          회사는 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화등의 대금을
          환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에
          대하여전자상거래등에서의 소비자보호에 관한 법률 시행령 제21조의2에서 정하는 지연이자율을
          곱하여 산정한 지연이자를 지급합니다.
          <br />
          회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화
          등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의
          대금의 청구를 정지 또는 취소하도록 요청합니다.
          <br />
          청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 회사
          이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만, 재화 등의
          내용이 표시, 광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화
          등의 반환에 필요한 비용은 회사가 부담합니다.
          <br />
          이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 회사는 청약철회 시 그 비용을 누가
          부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
          <br />
          제5장 회사와 이용자의 의무사항
          <br />
          <br />
          제16조(회사의 접근권한)
          <br />
          회사는 온라인 서비스(모바일 포함)를 제공하기 위하여 접근권한이 필요한 경우 다음 각 호의
          사항을 이용자가 명확하게 인지할 수 있도록 알리고 이용자의 동의를 받습니다.
          <br />
          가. 해당 서비스를 제공하기 위하여 반드시 필요한 접근권한인 경우
          <br />
          1) 접근권한이 필요한 정보 및 기능의 항목
          <br />
          2) 접근권한이 필요한 이유
          <br />
          나. 해당 서비스를 제공하기 위하여 반드시 필요한 접근권한이 아닌 경우
          <br />
          가. 접근권한이 필요한 정보 및 기능의 항목
          <br />
          나. 접근권한이 필요한 이유
          <br />
          다. 접근권한 허용에 대하여 동의하지 아니할 수 있다는 사실
          <br />
          회사는 해당 서비스를 제공하기 위하여 반드시 필요하지 않은 접근권한을 설정하는데 이용자가
          동의하지 아니한다는 이유로 이용자에게 해당 서비스의 제공을 거부하지 않습니다.
          <br />
          <br />
          제16조의2(개인정보보호)
          <br />
          1.회사는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 신용정보의 이용
          및 보호에 관한 법률등 관련법령 및 개인정보 처리방침에 따라 이용자의 개인정보를 처리합니다.
          <br />
          2.회사는 이용자의 동의에 의하여 개인정보를 수집, 이용하며, 수집하는 개인정보의 항목은
          다음과 같습니다.
          <br />
          가. 민원사무 처리
          <br />
          - 성명, 휴대전화번호, 자택전화번호, 이메일, 본인인증결과값(CI), 통신사, I-PIN(본인확인용,
          저장하지 않음), 로그인ID, 비밀번호, 주문번호, 거래기록, 결제기록, 서비스 이용기록,
          민원내용
          <br />
          나. 재화 또는 서비스 제공
          <br />
          1) 개인 구매의 경우- 필수항목: 성명, 자택주소, 휴대전화번호, 로그인ID, 비밀번호, 인수자명,
          인수자주소, 인수자전화번호, 신용카드정보, 예금주명, 계좌번호, 결제기록,
          본인인증결과값(CI), 통신사, I-PIN(본인확인용, 저장하지 않음), My-PIN(본인확인용, 저장하지
          않음), 구매내역- 선택항목: 자택전화번호, 직장전화번호, 이메일, 희망배송일, 희망가격
          <br />
          2) 법인/사업자 구매의 경우- 필수항목: 법인/사업자명, 법인/사업자주소, 법인/사업자전화번호,
          대표자명, 대표자전화번호, 담당자명, 담당자전화번호, 인수자명, 인수자주소, 인수자전화번호,
          신용카드정보, 예금주명, 계좌번호, 결제기록, 구매내역- 선택항목:
          대표자휴대전화번호,담당자휴대전화번호, 팩스번호, 이메일, 희망배송일, 희망가격,구매목적
          <br />
          3) 해외 구매대행의 경우- 필수항목: 성명, 개인통관고유부호
          <br />
          다. 마케팅 등 활용
          <br />
          - 필수항목: 성명, 자택주소, 휴대전화번호, 로그인ID, 구매내역
          <br />
          - 선택항목: 성별, 생년월일, 자택전화번호, 직장전화번호, 이메일, 취미사항, 결혼여부,
          결혼기념일, 구입희망품목
          <br />
          라. 쿠키(Cookie)를 통한 수집항목
          <br />
          - 서비스이용기록, 접속로그, 쿠키(cookie), 접속IP정보, 결제기록, 이용정지기록
          <br />
          - 모바일 서비스 이용의 경우 : 단말기 모델명, 단말기 고유번호,단말기OS,통신사
          <br />
          3.회사가 정보주체 이외의 자로부터 개인정보를 수집하여 처리하는 때에는 정보주체에게
          개인정보 수집 출처, 개인정보의처리 목적,개인정보 처리의 정지를 요구할 권리가 있다는 사실을
          고지합니다. 단, 회사가 수집한정보에 연락처 등 정보주체에게알릴 수 있는 개인정보가 포함되지
          아니한 경우에는 고지하지 않습니다.
          <br />
          4.회사가 이용자의 개인정보를 제3자 제공하거나 목적 외 이용하는 경우 반드시 이용자의 동의를
          받습니다. 단, 법령에 의하여 허용되는 경우에는동의를 받지 않을 수 있습니다.
          <br />
          5.회사는 원활한 개인정보 업무처리를 위하여 개인정보 처리업무의 일부를 위탁하고 있습니다.
          위탁하는 업무의 내용과 개인정보 처리 업무를 위탁받는 자의 목록은 회사의 인터넷 홈페이지에
          지속적으로 게재하고 있으며, 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이
          반영하도록 하겠습니다.
          <br />
          6.이용자는 언제든지 회사에 대하여 개인정보 수집, 이용, 제3자 제공, 위탁 동의의 전부 또는
          일부를 철회할 수 있습니다. 단, 이용자가 필수수집 항목에 관한 동의의 전부 또는 일부를
          철회한 경우 회사는 이용자에게 관련 서비스를 제공하지 않을수 있습니다.
          <br />
          7.이용자는 언제든지 회사에 대하여 개인정보 열람, 정정, 처리정지 및 삭제를 요구할 수 있으며
          회사는 지체 없이 필요한 조치를 취할 것입니다.단, 법령에 의하여 허용되는 경우에는 그 사유를
          알리고 열람 등을 제한하거나 거절할수 있습니다.
          <br />
          8.이용자가 회원탈퇴, 사망 등의 사유로 본인이 게시한 게시물을 직접 삭제하기 어려운 사정이
          있는 경우 본인 또는 유족은 입증자료 등을 제출하여 타인의 접근배제를 요청할 수 있습니다.
          <br />
          9.회사는 개인정보 암호화, 임직원 교육, 접근제한, 출입통제 등 개인정보의 안전성 확보에
          필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.
          <br />
          10.기타 회사의 개인정보 처리에 관하여 이 약관 및 개인정보 처리방침이 적용되며, 개인정보
          처리방침은 인터넷 홈페이지 전면에 게시된 링크를 통하여 확인할 수 있습니다.
          <br />
          <br />
          제17조(회사의 의무)
          <br />
          회사는 법령과 이 약관이 금지하거나 공공의 질서와선량한 풍속에 반하는 행위를 하지 않으며 이
          약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다하여야
          합니다.
          <br />
          회사는 주민등록번호, 계좌정보, 신용카드정보 등 이용자의 개인정보가 정보통신망을 통하여
          공중에 노출되지 않도록 최대한 노력합니다.
          <br />
          회사는 이용자가 안전하게 온라인 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)
          보호를 위한 보안 시스템을 갖추어야 합니다. <br />
          회사가 상품이나 용역에 대하여 표시 • 광고의 공정화에 관한 법률에 위반하여 부당한
          표시•광고행위를 함으로써 이용자가 피해를 입은 때에는 이를 배상할 책임을 집니다.
          <br />
          회사는 이용자가 원하지 않는 영리목적의 광고성 문자, 전자우편 등을 발송하지 않습니다.
          <br />
          <br />
          제18조(이용자의 의무)
          <br />
          이용자는 다음 행위를 하여서는 안됩니다.
          <br />
          가.정보 변경시 허위 내용의 등록
          <br />
          나.회사가 공개하거나 보유한 정보 및 제3자가 게시한 정보의 권한 없는 변경
          <br />
          다.회사가 허용하는 정보 이외의 정보(컴퓨터 프로그램 등)등 전송 또는게시
          <br />
          라.회사 또는 제3자의 저작권 기타 권리를 침해하는 행위
          <br />
          마.회사의 업무 또는 제3자의 서비스 이용을 방해하는 행위
          <br />
          바.개인정보보호에관한 법령을 위반하여 개인정보를 거래하는 내용의 정보를유통하는행위
          <br />
          사.서비스를 부정한 목적 또는 방법으로 이용하는 행위
          <br />
          아.음란한 정보 전송, 공포심을 유발하는 정보 전송 등 회사의 서비스와 관련하여 법령,
          사회질서 및 공서양속에 반하는 행위
          <br />
          자.회사의 사전 승인 없이 재판매 등의 목적으로 재화를 구매하는 행위
          <br />
          차.회사의 서비스와 관련하여 법령, 사회질서 및 공서양속에 반하는 행위를 하는 경우
          <br />
          카.기타 회사가 객관적인 판단에 의하여 부적절하다고 판단하는 행위
          <br />
          회사는 제1항 위반과 관련하여 회사 또는 회사와 연결된 사이트에 부적절한 정보가 게시되거나
          부적절한 정보가 게시된 사이트와 회사가 연결된 경우 회사는 이용자 또는 행위자의 승낙없이
          해당 정보를 삭제하거나 회사와의 연결을 절단할 수 있습니다. 단, 회사는 이용자 또는 행위자에
          대하여 위와 같은 삭제 또는 절단 등을 할 의무를 부담하지 않습니다.
          <br />
          회사는 개별 서비스별 이용자 세부지침을 정하여 시행할 수 있으며, 이용자는 사전에 고지된
          지침의 내용을 준수하여 주시기 바랍니다.
          <br />
          <br />
          제6장 서비스
          <br />
          <br />
          제19조(수능일등앱 서비스)
          <br />
          회사는 수능일등앱 이용약관에 따라 다음의 단어문제 휴대폰잠금 서비스를 제공할 수 있습니다.
          <br />
          가. 영어단어 문제 제공
          <br />
          나. 영어단어 사용자 통계
          <br />
          다. 영어단어 문제 리스트 <br />
          라. 영어단어 오답리스트
          <br />
          마. 영어단어 문제 풀이 여부에 따른 휴대폰 잠금 서비스
          <br />
          바. 영어단어 회차별 상품 증정 서비스
          <br />
          <br />
          제20조(위치정보 서비스)
          <br />
          회사는 위치정보기반서비스 이용약관에 따라 다음의 위치정보기반서비스를 제공할 수 있습니다.
          <br />
          가. 위치확인 서비스
          <br />
          나. 볼륨키로 위급상황 신고
          <br />
          다. 휴대폰 움직임 확인 서비스
          <br />
          <br />
          제7장 기타
          <br />
          <br />
          제21조(연결 웹사이트와 피연결 웹사이트 간의 관계)
          <br />
          회사는 회사 사이트를 다른 사이트에 하이퍼 링크 방식 등에 의해 연결시킬 수 있습니다.
          <br />
          회사는 회사와 연결된 사이트에서 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는
          거래에 대해서 그 거래에 대한 보증 책임을 지지 않습니다.
          <br />
          <br />
          제22조(지식재산권의 귀속 및 이용제한) 회사가 작성한 저작물에 대한 저작권 기타 지식재산권은
          회사에 귀속합니다.
          <br />
          이용자는 회사를 이용함으로써 얻은 정보 중 회사에게 지식재산권이 귀속된 정보를 회사의 사전
          승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나
          제3자에게 이용하게 하여서는 안됩니다.
          <br />
          회사는 상품 및 서비스 개선, 정보 공유, 마케팅 활용 등의 목적 범위 내에서 이용자의 상품평
          기타 게시물을 회사의 다른 영업장, 회사 소속 계열회사의 영업장, 회사가 입점한 영업장 기타
          회사가 지정하는 임의의 장소, 매체 등으로 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여
          영리목적으로 이용하거나 제3자에게 이용하게 할 수 있습니다.
          <br />
          이용자는 언제든지 회사에 대하여 이용자 본인의 상품평 기타 게시물 이용에 관하여 열람, 정정,
          처리정지 및 삭제를 요구할 수있으며 회사는 지체 없이 필요한 조치를 취할것입니다. 단, 법령에
          의하여 허용되는 경우에는 그 사유를 알리고 열람 등을 제한하거나 거절할 수 있습니다.
          <br />
          본조에서 정한 방법 이외에 회사는이용자의 지식재산권을 이용하고자 하는 경우 당해 이용자에게
          사전에 통보하고 개별 약정을 체결하여야 합니다.
          <br />
          <br />
          제23조(분쟁해결)
          <br />
          회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상하기 위하여
          콜센터를 설치•운영합니다.
          <br />
          회사는 이용자의 의견 및 불만사항을 최대한 신속하게 처리합니다. 단, 신속한 처리가 곤란한
          경우에는 이용자에게 그 사유 및 처리일정을 통보해 드립니다.
          <br />
          회사와 이용자간에 발생한 분쟁은 '한국소비자원'(http://www.kca.go.kr)의 조정에 따를 수
          있습니다.
          <br />
          <br />
          제24조(재판관할권 및 준거법)
          <br />
          회사와 이용자간에 발생한 분쟁에 관한 소송은 제소 당시의 이용자의 주소 또는 거소를 관할하는
          법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
          거주자의 경우에는 서울중앙지방법원의 전속관할로 합니다.
          <br />
          회사와 이용자간의 분쟁에는 대한민국법을 적용합니다.
          <br />
          <br />
          제25조(약관 외 준칙)
          <br />이 약관에 명시되지 않은 사항은 관련법령 및 상관습에 의합니다.
        </ContentText>
      </PopupContainer>
      <CloseButton onClick={handleClose}>
        <CloseButtonImg src={closeButtonImg} alt="close-button-img" />
      </CloseButton>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
`;

const PopupContainer = styled.div`
  position: relative;
  width: 900px;
  height: 700px;
  padding: 60px 65px;
  background-color: #e6e6e6;
  border: 1px solid #d6d6d6;
  border-radius: 20px;
  z-index: 21;
  overflow-y: scroll;

  @media ${theme.mobile} {
    width: 400px;
    padding: 60px 35px;
  }
`;

const Title = styled.p`
  font-family: nanumGothicB;
  font-size: 24px;
  color: #000000;
  letter-spacing: -0.7;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #292d32;
  margin: 0 0 34px 0;
`;

const ContentText = styled.p`
  font-family: nanumGothicR;
  font-size: 16px;
  color: #292d32;
  letter-spacing: -0.4;
  line-height: 22.4px;
`;

const CloseButton = styled.button`
  z-index: 1;
  background-color: transparent;
`;

const CloseButtonImg = styled.img`
  width: 50px;
  height: 50px;
  margin: 25px 0 0 0;
`;

export default TermsPopUp;
