import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import useDetectOutsideClick from 'components/utils/useDetectOutsideClick';
import { BsFillCaretDownFill } from 'react-icons/bs';

const SelectOptions = ({ options, value, onSelect, style, active, defaultMessage }) => {
  const wrapperRef = useRef(null);
  const [visible, setVisible] = useDetectOutsideClick(wrapperRef, false);
  const [optionsScroll, setOptionScroll] = useState(false);

  useEffect(() => {
    //optionScroll
    if (options.length > 10) {
      setOptionScroll(true);
    }
  }, [options.length]);

  return (
    <>
      <DropdownWrapper
        ref={wrapperRef}
        onClick={() => setVisible(!visible)}
        style={style}
        align="center"
      >
        <SelectedDropdownWrap focus={visible || undefined} style={style}>
          {value ? (
            <SelectedLabel active={active}>{value.text}</SelectedLabel>
          ) : (
            <SelectedLabelGray>{defaultMessage}</SelectedLabelGray>
          )}
          <SelectIcon active={visible} />
        </SelectedDropdownWrap>
        {visible && (
          <DropdownList scroll={optionsScroll}>
            {options
              ? options.map((item, index) => (
                  <DropdownListItem
                    key={`${item}${index}`}
                    onClick={() => {
                      onSelect(item);
                      setVisible(false);
                    }}
                    border={index !== options.length - 1}
                  >
                    <DropdownListItemText>{item.text}</DropdownListItemText>
                  </DropdownListItem>
                ))
              : null}
          </DropdownList>
        )}
      </DropdownWrapper>
    </>
  );
};

const DropdownWrapper = styled.div`
  position: relative;
  display: table-cell;
  text-align: left;
  width: 100vw;
  vertical-align: middle;
  justify-content: center;
  cursor: pointer;
  ${(props) => (props.style ? props.style : null)}
`;

const SelectedDropdownWrap = styled.div`
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 5px 0;
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1;
  border-bottom: 1px solid #b3b3b3;
`;

const SelectedLabel = styled.p`
  overflow: hidden;
  font-weight: normal;
  font-size: 16px;
  color: #b3b3b3;
  font-family: 'NanumGothicB';

  ${(props) =>
    props.active === true &&
    css`
      color: #344152;
    `}

  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

const SelectedLabelGray = styled(SelectedLabel)`
  color: #b3b3b3;
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  top: 30px;
  min-height: 47px;
  margin: 0;
  padding: 0;
  background: #e6e6e6;
  cursor: pointer;
  list-style: none;
  z-index: 2;
  ${(props) =>
    props.scroll === true &&
    css`
      height: 200px;
      overflow-y: scroll;
    `}
`;

const DropdownListItem = styled.li`
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 40px;
  font-family: 'NanumGothicB';
  padding-right: 17px;
  & + & {
    border-top: 1px solid #d7d7d7;
  }
`;

const DropdownListItemText = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  color: #344152;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

const SelectIcon = styled(BsFillCaretDownFill)`
  font-size: 24px;
  position: absolute;
  right: 0;
  margin: 0 10px 0 0;
  color: #b3b3b3;
  z-index: 1;

  @media ${theme.mobile} {
    margin: 0;
  }
`;

//   const DropdownIcon = styled(Dropdown)`
//   ${(props) => props.focus === 'true' && 'transform: rotate(180deg);'}
// `;

export default SelectOptions;
