import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import PopUpDefault from 'components/atoms/PopUp';
import closeButtonImg from 'resources/close_black.png';

const RefundPopUp = ({ visible, payment, handleClose, handleConfirm, handleDeny }) => {
  useEffect(() => {
    if (!visible) {
      return;
    }
  }, [visible]);

  const handleClickDenyRefund = () => {
    handleDeny();
  };

  const handleClickConfirm = () => {
    handleConfirm();
  };

  return (
    <PopUpDefault width="1020" title="반품요청" visible={visible}>
      <Content>
        <CloseButton onClick={handleClose}>
          <CloseBtnImg src={closeButtonImg} alt="close-button-img" />
        </CloseButton>
        <CheckboxListWrap>
          <Row>
            <SubTitle>제품정보:</SubTitle>
            <SubValue>{payment?.product?.productName}</SubValue>
            <SubValue>{payment?.product?.modelDetail}</SubValue>
            <SubValue>{payment?.product?.colorName}</SubValue>
          </Row>
          <Row>
            <SubTitle>수량:</SubTitle>
            <SubValue>1</SubValue>
            <SubTitle>구매일자:</SubTitle>
            <SubValue>{payment?.createdDate}</SubValue>
          </Row>
          <Row>
            <SubTitle>구매자:</SubTitle>
            <SubValue>{payment?.receiverName}</SubValue>
            <SubTitle>연락처:</SubTitle>
            <SubValue>{payment?.receiverCellphone}</SubValue>
          </Row>
          <Row>
            <SubTitle>배송주소:</SubTitle>
            <SubValue>{`(${payment?.addressCode}) ${payment?.address} ${payment?.addressDetail}`}</SubValue>
          </Row>
        </CheckboxListWrap>
        <ButtonWrap>
          <InfoTxt>
            *결제 취소 수락의 경우 반드시 텔콤과 사운드마인드에 정보를 공유해 주셔야 합니다.
          </InfoTxt>
          <ButtonGray onClick={handleClickDenyRefund}>
            <ButtonText>반품 요청 반려</ButtonText>
          </ButtonGray>
          <ButtonRed onClick={handleClickConfirm}>
            <ButtonText>반품 수락</ButtonText>
          </ButtonRed>
        </ButtonWrap>
      </Content>
    </PopUpDefault>
  );
};

const Content = styled.div`
  padding: 40px 50px 30px;
`;

const CheckboxListWrap = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 40px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 0 0;
  align-items: center;
`;

const ButtonGray = styled.button`
  width: 160px;
  height: 38px;
  border-radius: 25px;
  background-color: #b3b3b3;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;

  & + & {
    margin: 0 0 0 12px;
  }
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const SubTitle = styled.span`
  font-size: 16px;
  font-family: nanumGothicB;
  color: #4c4c4c;
  margin-left: 50px;
`;

const SubValue = styled.span`
  font-size: 16px;
  font-family: nanumGothicR;
  color: #4c4c4c;
  margin-left: 43px;
`;

const InfoTxt = styled.span`
  font-size: 16px;
  font-family: nanumGothicR;
  color: #e81a0c;
`;

const ButtonRed = styled(ButtonGray)`
  width: 130px;
  background-color: #e81a0c;
`;

const ButtonText = styled.p``;

const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 20px;
`;

const CloseBtnImg = styled.img`
  widdth: 50px;
  height: 50px;
`;

export default RefundPopUp;
