import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import stores from 'stores';
import { observer } from 'mobx-react';

import DefaultPageLayout from 'components/templates/PageLayout';
import Pagination from 'components/atoms/Pagination';
import ListViewSettingPopUp from './ListViewSettingPopUp';
import ListExcelSettingPopUp from './ListExcelSettingPopUp';

import personIcon from 'resources/ic_guardian.png';
import newIcon from 'resources/ticket_star.png';
import settingIcon from 'resources/setting.png';
import { FiSearch } from 'react-icons/fi';
import { BiSolidDownArrow } from 'react-icons/bi';
import { LuArrowDownSquare, LuArrowUpSquare } from 'react-icons/lu';
import EditUserPopup from '../Word/EditUserPopup';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = ['E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'M1', 'M2', 'M3', 'H1', 'H2', 'H3', 'N'];

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 10,
      ticks: {
        color: '#b3b3b3',
      },
    },
    x: {
      grid: {
        display: false,
        color: '#d1d1d1',
      },
      ticks: {
        color: '#b3b3b3',
      },
    },
  },
  responsive: true,
};

export const options2 = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      min: 0,
      max: 1000,
      ticks: {
        color: '#b3b3b3',
      },
    },
    x: {
      grid: {
        display: false,
        color: '#d1d1d1',
      },
      ticks: {
        color: '#b3b3b3',
      },
    },
  },
  responsive: true,
};
const Dashboard = observer(() => {
  const { dashboardStore } = stores;
  const { getAdminStatus, adminstats, getUsers, users, totalUsersCount } = dashboardStore;

  const [inputs, setInputs] = useState({ keyword: '' });

  const { keyword } = inputs;

  const [visibleIds, setVisibleIds] = useState([]);

  useEffect(() => {
    const savedCheckedList = localStorage.getItem('@checkedList');
    if (!savedCheckedList || JSON.parse(savedCheckedList).length === 0) {
      let allIds = [];
      for (let i = 0; i < 22; i++) {
        allIds.push(i + 1);
      }
      setVisibleIds(allIds);
    } else {
      setVisibleIds(JSON.parse(savedCheckedList));
    }
  }, []);

  useEffect(() => {
    getAdminStatus();
  }, [getAdminStatus]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 20;

  useEffect(() => {
    getUsers(keyword, currentPage - 1);
  }, [getUsers, keyword, currentPage]);

  const [showListPopUp, setShowListPopUp] = useState(false);
  const [showExcelPopUp, setShowExcelPopUp] = useState(false);

  const handleShowListPopUp = () => {
    setShowListPopUp(true);
  };

  const handleCloseListPopUp = () => {
    setShowListPopUp(false);
  };

  const handleConfirmListPopup = (localVisibleIds) => {
    setVisibleIds(localVisibleIds);
  };

  const handleShowExcelSettingPopUp = () => {
    setShowExcelPopUp(true);
  };

  const handleCloseExcelSettingPopUp = () => {
    setShowExcelPopUp(false);
  };

  const handleConfirmExcelSettingPopUp = () => {};

  const data = {
    labels,
    datasets: [
      {
        barThickness: 8,
        label: 'Dataset 1',
        data: adminstats.answerRate && Object.values(adminstats.answerRate),
        backgroundColor: '#b3b3b3',
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        barThickness: 8,
        label: 'Dataset 2',
        data: adminstats.avgClass && Object.values(adminstats.avgClass),
        backgroundColor: '#b3b3b3',
      },
    ],
  };

  const ellipsize = (value, length) => {
    if (!value) {
      return '';
    }
    if (value.length > length) {
      return `${value.slice(0, length)}...`;
    }
    return value;
  };

  const [selectedUser, setSelectedUser] = useState('');
  const [showEditUserPopUp, setShowEditUserPopUp] = useState(false);

  const handleShowEditUserPopUp = (value) => {
    setSelectedUser(value);
    setShowEditUserPopUp(true);
  };

  const handleCloseEditUserPopUp = () => {
    setShowEditUserPopUp(false);
  };

  const refresh = () => {
    getUsers(keyword, currentPage - 1);
  };

  return (
    <DefaultPageLayout>
      <Wrap>
        <TopSection>
          <TopFlexWrap>
            <DistributionText>수능일등 Application Ver.1.0 (2024.01.30 배포됨)</DistributionText>
            <LeftWrap>
              <LeftBox>
                <LeftBoxLabel>
                  <PersonIcon src={personIcon} alt="gaurdian-icon" />
                  <LeftBoxLabelText>
                    수능일등
                    <br />총 가입자
                  </LeftBoxLabelText>
                </LeftBoxLabel>
                <Border></Border>
                <Number>{adminstats.totalUsersCount}</Number>
              </LeftBox>
              <LeftBox>
                <LeftBoxLabel>
                  <PersonIcon src={personIcon} alt="gaurdian-icon" />
                  <LeftBoxLabelText>
                    수능일등
                    <br />
                    이번 주<br />
                    가입자
                  </LeftBoxLabelText>
                </LeftBoxLabel>
                <Border></Border>
                <Number>{adminstats.totalUsersInThisWeekCount}</Number>
              </LeftBox>
            </LeftWrap>
            <GraphWrap>
              <GraphTitle>
                <GraphTitleText>Word Lock 정답률</GraphTitleText>
              </GraphTitle>
              <GraphBox>
                <Bar options={options} data={data} />
              </GraphBox>
            </GraphWrap>
            <GraphWrap>
              <GraphTitle>
                <GraphTitleText>Word Lock Class 진행률</GraphTitleText>
              </GraphTitle>
              <GraphBox>
                <Bar options={options2} data={data2} />
              </GraphBox>
            </GraphWrap>
          </TopFlexWrap>
        </TopSection>
        <BottomSection>
          <SettingWrap>
            <TabWrap>
              <Tab>
                <TabIconLeft src={newIcon} alt="new-icon" />
                <TabText>신규 상품권 발송 대상 (0명)</TabText>
              </Tab>
              <Tab onClick={handleShowListPopUp}>
                <TabText>목록 보기 설정</TabText>
                <TabIconRight src={settingIcon} alt="setting-icon" />
              </Tab>
            </TabWrap>
            <SearchWrap>
              <SearchInput
                placeholder="이용자이름 또는 검색어 입력"
                name="keyword"
                value={keyword}
                onChange={onChange}
                autoComplete="new-password"
              />
              <SearchIcon />
            </SearchWrap>
          </SettingWrap>
          <TableWrap>
            <TableHeader>
              {visibleIds.includes(1) && (
                <ThShelf width={60}>
                  No.
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(2) && (
                <ThShelf width={100}>
                  ID
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(3) && (
                <ThShelf width={70}>
                  이름
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(4) && (
                <ThShelf width={130}>
                  학교
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(5) && (
                <ThShelf width={50}>
                  학년
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(6) && (
                <ThShelf width={90}>
                  시작일
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(7) && (
                <ThShelf width={80}>
                  만점횟수
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(8) && (
                <ThShelf width={70}>
                  정답률
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(9) && (
                <ThShelf width={70}>
                  오답률
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(10) && (
                <ThShelf width={110}>
                  잠금해제 횟수
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(11) && (
                <ThShelf width={110}>
                  잠금해제 평균
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(12) && (
                <ThShelf width={60}>
                  Grade
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(13) && (
                <ThShelf width={60}>
                  Class
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(14) && (
                <ThShelf width={60}>
                  이메일
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(15) && (
                <ThShelf width={60}>
                  전화번호
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(16) && (
                <ThShelf width={60}>
                  보호자 연락처
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(17) && (
                <ThShelf width={60}>
                  모델명
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(18) && (
                <ThShelf width={60}>
                  회원가입 형태
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(19) && (
                <ThShelf width={60}>
                  사용상태
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(20) && (
                <ThShelf width={60}>
                  상품권 발급 횟수
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(21) && (
                <ThShelf width={60}>
                  학습초기화 횟수
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(22) && (
                <ThShelf width={60}>
                  지역별
                  <DownArrow />
                </ThShelf>
              )}
              {visibleIds.includes(23) && (
                <ThShelf width={60}>
                  보호자 이메일
                  <DownArrow />
                </ThShelf>
              )}
            </TableHeader>
            <TableBody>
              {users.map((item) => (
                <TableRow onClick={() => handleShowEditUserPopUp(item)}>
                  {visibleIds.includes(1) && <TbShelf>{item.id}</TbShelf>}
                  {visibleIds.includes(2) && <TbShelf>{ellipsize(item.username, 20)}</TbShelf>}
                  {visibleIds.includes(3) && <TbShelf>{item.name}</TbShelf>}
                  {visibleIds.includes(4) && <TbShelf>{item.schoolName}</TbShelf>}
                  {visibleIds.includes(5) && <TbShelf>{item.gradeTypeDesc}</TbShelf>}
                  {visibleIds.includes(6) && <TbShelf>{item.initializedAtDate}</TbShelf>}
                  {visibleIds.includes(7) && <TbShelf>{item.numberOfPerfect}</TbShelf>}
                  {visibleIds.includes(8) && <TbShelf>{item.correctRate}%</TbShelf>}
                  {visibleIds.includes(9) && <TbShelf>{item.incorrectRate}%</TbShelf>}
                  {visibleIds.includes(10) && <TbShelf>{item.unlockCount}</TbShelf>}
                  {visibleIds.includes(11) && <TbShelf>{item.unlockAvgCount}</TbShelf>}
                  {visibleIds.includes(12) && (
                    <TbShelf>
                      {item.schoolLevelTypeDesc} {item.gradeTypeDesc}
                    </TbShelf>
                  )}
                  {visibleIds.includes(13) && <TbShelf>{item.currentClass}</TbShelf>}
                  {visibleIds.includes(14) && <TbShelf>{item.email}</TbShelf>}
                  {visibleIds.includes(15) && <TbShelf>{item.cellphoneNumber}</TbShelf>}
                  {visibleIds.includes(16) && <TbShelf>{item.parentContactDesc}</TbShelf>}
                  {visibleIds.includes(17) && <TbShelf>{item.deviceType}</TbShelf>}
                  {visibleIds.includes(18) && <TbShelf>{item.authType}</TbShelf>}
                  {visibleIds.includes(19) && <TbShelf></TbShelf>}
                  {visibleIds.includes(20) && <TbShelf></TbShelf>}
                  {visibleIds.includes(21) && <TbShelf>{item.unlockCount}</TbShelf>}
                  {visibleIds.includes(22) && <TbShelf>{item.region}</TbShelf>}
                  {visibleIds.includes(23) && <TbShelf>{item.emailForSettingPassword}</TbShelf>}
                </TableRow>
              ))}
            </TableBody>
          </TableWrap>
          <PaginationWrap>
            <Pagination
              currentPage={currentPage}
              totalCount={totalUsersCount}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            ></Pagination>
            <Button onClick={handleShowExcelSettingPopUp}>
              <ButtonText>엑셀 내보내기</ButtonText>
              <ButtonIcon>
                <LuArrowDownSquare />
              </ButtonIcon>
            </Button>
          </PaginationWrap>
        </BottomSection>
      </Wrap>
      <ListViewSettingPopUp
        visible={showListPopUp}
        handleClose={handleCloseListPopUp}
        handleConfirm={handleConfirmListPopup}
      />
      <ListExcelSettingPopUp
        visible={showExcelPopUp}
        handleClose={handleCloseExcelSettingPopUp}
        handleConfirm={handleConfirmExcelSettingPopUp}
      />
      <EditUserPopup
        visible={showEditUserPopUp}
        selectedUser={selectedUser}
        handleRefresh={refresh}
        handleClose={handleCloseEditUserPopUp}
      />
    </DefaultPageLayout>
  );
});

const Wrap = styled.div`
  height: calc(100vh - 95px);
  background-color: #ffffff;
`;

const TopSection = styled.div`
  height: 350px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DistributionText = styled.p`
  position: absolute;
  right: 10px;
  top: -15px;
  color: #666666;
  font-size: 10px;
  font-family: nanumGothicB;
`;

const TopFlexWrap = styled.div`
  display: flex;
  position: relative;
`;

const LeftWrap = styled.div`
  margin: 0 10px 0 0;
`;

const LeftBox = styled.div`
  width: 349px;
  height: 140px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: rgba(76, 161, 175, 0.5) 0px 2px 4px;
  display: flex;
  align-items: center;

  & + & {
    margin: 10px 0 0 0;
  }
`;

const LeftBoxLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 94px;
`;

const PersonIcon = styled.img`
  width: 30px;
  height: 19px;
  margin: 0 0 7px 0;
`;

const LeftBoxLabelText = styled.p`
  color: #666666;
  font-size: 12px;
  font-family: nanumGothicB;
  text-align: center;
`;

const Border = styled.div`
  width: 1px;
  height: 98px;
  background-color: #d9d9d9;
`;

const Number = styled.p`
  color: #4ca1af;
  font-size: 64px;
  font-family: helveticaNeueB;
  text-align: center;
  width: 254px;
`;

const GraphWrap = styled.div`
  width: 498px;
  height: 290px;
  background-color: #ffffff;
  box-shadow: rgba(76, 161, 175, 0.5) 0px 2px 4px;
  border-radius: 15px;

  & + & {
    margin: 0 0 0 10px;
  }
`;

const GraphTitle = styled.div`
  border-radius: 15px 15px 0 0;
  background-color: #e6e6e6;
  height: 35px;
`;

const GraphTitleText = styled.p`
  color: #666666;
  font-size: 12px;
  font-family: nanumGothicB;
  text-align: center;
  padding: 10px 0 0 0;
`;

const GraphBox = styled.div`
  width: 450px;
  height: 200px;
  margin: 25px 15px;
`;

const BottomSection = styled.div`
  width: 1660px;
  margin: 0 auto;
  padding: 30px 0;
`;

const SettingWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TabWrap = styled.div`
  display: flex;
`;

const Tab = styled.button`
  height: 34px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  background-color: #666666;
  border-radius: 20px;

  & + & {
    margin: 0 0 0 15px;
  }
`;

const TabIconLeft = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 15px 0 0;
`;

const TabIconRight = styled(TabIconLeft)`
  margin: 0 0 0 10px;
`;

const TabText = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-family: nanumGothicB;
`;

const SearchWrap = styled.div`
  width: 315px;
  height: 34px;
  border-radius: 25px;
  background-color: #e6e6e6;
  padding: 7px 15px 7px 25px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  font-family: nanumGothicR;
  border-right: 2px solid #b3b3b3;
  width: 250px;
  -webkit-appearance: none;

  &::placeholder {
    color: #b3b3b3;
  }
`;

const SearchIcon = styled(FiSearch)`
  font-size: 20px;
  color: #b3b3b3;
  margin: 0 0 0 10px;
`;

const TableWrap = styled.table`
  width: 1660px;
  margin: 10px auto 0;
  border-bottom: 1px solid #b3b3b3;
`;

const TableHeader = styled.thead`
  height: 46px;
  background-color: #e6e6e6;
  justify-content: space-between;
  padding: 0 10px;
`;

const TableBody = styled.tbody`
  height: 400px;
  padding: 0 10px;
`;

const TableRow = styled.tr`
  height: 40px;

  &:hover {
    background-color: #d4e8fb;
  }
`;

const ThShelf = styled.th`
  color: #292d32;
  font-size: 14px;
  font-family: nanumGothicEB;
  text-align: center;
  padding: 15px 0;
`;

const DownArrow = styled(BiSolidDownArrow)`
  font-size: 12px;
  color: #666666;
  margin: 0 0 0 10px;
`;

const TbShelf = styled.td`
  color: #666666;
  font-size: 16px;
  font-family: nanumGothicR;
  padding: 10px 0;
`;

const PaginationWrap = styled.div`
  margin: 12px 0 0 0;
  height: 34px;
  position: relative;
  padding: 6px 0;
`;

const Button = styled.button`
  width: 180px;
  height: 34px;
  border-radius: 25px;
  background-color: #4ca1af;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;

  & + & {
    margin: 0 0 0 20px;
  }
`;

const ButtonText = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-family: nanumGothicR;
`;

const ButtonIcon = styled.p`
  color: #ffffff;
  font-size: 18px;
  margin: 4px 0 0 5px;
`;

export default Dashboard;
