import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { setPreventScroll, clearPreventScroll } from 'components/utils/usePreventScroll';

const PopUpDefault = ({ visible, title, children, width, height }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <Container>
      <DarkBackground />
      <PopupContainer width={width} height={height}>
        <Title>{title}</Title>
        <Content>{children}</Content>
      </PopupContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
`;

const PopupContainer = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: #ffffff;
  border-radius: 25px;
  z-index: 21;
  overflow-y: scroll;
`;

const Title = styled.div`
  height: 60px;
  background-color: #c3dfe4;
  font-family: nanumGothicB;
  font-size: 20px;
  color: #000000;
  letter-spacing: -0.6;
  padding: 20px 0 0 40px;
`;

const Content = styled.div``;

export default PopUpDefault;
