import { makeAutoObservable, runInAction } from 'mobx';
import AuthStore from 'stores/AuthStore';

import {
  cancelRefundPaymentApi,
  countTodayPaymentCount,
  getPaymentsApi,
  putProductssApi,
} from 'api/API';

class PaymentStore {
  payments = [];
  totalPayments = 0;
  todayPaymentCount = 0;

  constructor() {
    makeAutoObservable(this);
    this.authStore = AuthStore;
  }

  getPayments = async (pageNumber, pageSize) => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    getPaymentsApi(pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.payments = [...res.data.content];
          this.totalPayments = res.data.totalElements;
        });
      })
      .catch((e) => console.log(e));
  };

  getTodayPayments = async () => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    countTodayPaymentCount()
      .then((res) => {
        runInAction(() => {
          this.todayPaymentCount = res.data;
        });
      })
      .catch((e) => console.log(e));
  };

  cancelRefundRequest = async (body) => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    cancelRefundPaymentApi(body)
      .then((res) => {
        runInAction(() => {
          alert('환불 요청이 반려되었습니다.');
          this.getPayments(0, 15);
        });
      })
      .catch((e) => {
        console.log(e);
        alert('요청에 실패했습니다.');
      });
  };

  updateProduct = async (body) => {
    putProductssApi(body)
      .then((res) => {
        runInAction(() => {
          alert('저장되었습니다.');
        });
      })
      .catch((e) => {
        console.error(e);
        alert('요청에 실패했습니다.');
      });
  };
}

const paymentStore = new PaymentStore();

export default paymentStore;
