import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { usePagination, DOTS } from 'components/utils/usePagination';
import theme from 'styles/theme';
import { sliceArrayByLimit } from 'components/utils/usefulFunction';

import { BiSolidRightArrow, BiSolidLeftArrow } from 'react-icons/bi';

// const Pagination = ({ totalPage, limit, page, setPage }) => {
//   const [currentPageArray, setCurrentPageArray] = useState([]);
//   const [totalPageArray, setTotalPageArray] = useState([]);

//   useEffect(() => {
//     if (page % limit === 1) {
//       setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
//     } else if (page % limit === 0) {
//       setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
//     }
//   }, [page]);

//   console.log(totalPage, 'totalPage');

//   useEffect(() => {
//     const slicedPageArray = sliceArrayByLimit(totalPage, limit);
//     setTotalPageArray(slicedPageArray);
//     setCurrentPageArray(slicedPageArray[0]);
//   }, [totalPage]);

//   console.log(currentPageArray, totalPageArray, page, 'page');

//   return (
//     <div>
//       <nav>
//         <PageUl>
//           <Prev onClick={() => setPage(1)}>
//             <Icon>
//               <BiSolidLeftArrow />
//             </Icon>
//             맨 앞
//           </Prev>
//           {currentPageArray?.map((i) => (
//             <PageLi key={i + 1} onClick={() => setPage(i + 1)} active={page === i + 1}>
//               <DotLeft active={page === i + 1}>&#183;</DotLeft>
//               <PageLiText active={page === i + 1}>{i + 1}</PageLiText>
//               <DotRight active={page === i + 1}>&#183;</DotRight>
//             </PageLi>
//           ))}
//           <Next onClick={() => setPage(totalPage)}>
//             맨 뒤
//             <Icon>
//               <BiSolidRightArrow />
//             </Icon>
//           </Next>
//         </PageUl>
//       </nav>
//     </div>
//   );
// };

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 4, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(totalCount / pageSize);
  };

  const onPrevious = () => {
    onPageChange(1);
  };

  return (
    <PageUl>
      {/* Left navigation arrow */}
      <Prev onClick={onPrevious}>
        <Icon>
          <BiSolidLeftArrow />
        </Icon>
        맨 앞
      </Prev>
      {paginationRange?.map((pageNumber) => {
        return (
          <PageLi onClick={() => onPageChange(pageNumber)} active={currentPage === pageNumber}>
            <DotLeft active={currentPage === pageNumber}>&#183;</DotLeft>
            <PageLiText active={currentPage === pageNumber}>{pageNumber}</PageLiText>
            <DotRight active={currentPage === pageNumber}>&#183;</DotRight>
          </PageLi>
        );
      })}
      <Next onClick={onNext}>
        맨 뒤
        <Icon>
          <BiSolidRightArrow />
        </Icon>
      </Next>
    </PageUl>
  );
};

const PageUl = styled.ul`
  list-style: none;
  text-align: center;
  border-radius: 3px;
  font-family: 'helveticaNeueR';
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const PageLi = styled.li`
  font-size: 14px;
  cursor: pointer;
  color: #b3b3b3;
  display: flex;
  position: relative;
  width: 35px;
  height: 20px;
  align-items: center;

  ${(props) =>
    props.active === true &&
    css`
      color: #292d32;
      font-size: 16px;
      font-family: 'helveticaNeueB';
      z-index: 1;
    `}

  & + & {
    margin: 0 0 0 -3px;
  }
`;

const PageLiText = styled.p`
  text-align: center;
  width: 35px;
  margin: -1px 0 0 0;

  ${(props) =>
    props.active === true &&
    css`
      margin: -2px 0 0 0;
    `}
`;

const DotLeft = styled.p`
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 0px;
  ${(props) =>
    props.active === true &&
    css`
      top: 0px;
    `}
`;

const DotRight = styled.p`
  font-size: 14px;
  position: absolute;
  top: 1px;
  right: 0px;
  ${(props) =>
    props.active === true &&
    css`
      top: 0px;
    `}
`;

const Prev = styled.button`
  font-size: 14px;
  color: #b3b3b3;
  margin: 0 10px 0 0;
  display: flex;
  cursor: pointer;
  background-color: transparent;
  align-items: center;
`;

const Next = styled(Prev)`
  margin: 0 0 0 10px;
`;

const Icon = styled.div`
  margin: 1px 10px 0;
  font-size: 10px;
`;

export default Pagination;
