import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

import { setPreventScroll, clearPreventScroll } from 'components/utils/usePreventScroll';

import { TbSquareRoundedArrowRightFilled } from 'react-icons/tb';

import popUpBackground from 'resources/popup_black.png';
import eventImg from 'resources/event.png';
import closeButtonImg from 'resources/close_white.png';
import { useMediaQuery } from 'react-responsive';

const ReservationPopUp = ({ visible, handleClose, handleCloseDay, handleGoDetail }) => {
  const isMobile = useMediaQuery({
    query: '(max-width:1100px)',
  });

  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  if (!visible) return null;
  const handleEventPage = () => {
    if (isMobile) {
      window.open(
        'https://www.lottecard.co.kr/app/LPBNFDA_V591.lc?evn_bult_seq=8584&cco_evn_gubun=SU1',
      );
    } else {
      window.open(
        'https://m.lottecard.co.kr/app/LPBNFDA_V591.lc?evn_bult_seq=8583&cco_evn_gubun=SU1',
      );
    }
  };

  return (
    <Container>
      <DarkBackground />
      <PopupContainer>
        <EventImg src={eventImg} alt="event-img" />
        <EventText>
          <EventStrong>롯데카드 디지로카 London 카드로 12만원 캐시백 혜택 !!</EventStrong>
          <br />
          <Button onClick={handleEventPage}>
            <ButtonText>롯데카드 캐시백 프로모션 바로가기</ButtonText>
            <ButtonIcon>
              <TbSquareRoundedArrowRightFilled />
            </ButtonIcon>
          </Button>
          <br />
          *본 행사는 2024.03.01~2024.04.30 까지 진행되며, 참여하시는 고객님께{' '}
          <Underline>S24, S23FE, A24</Underline>
          기종에 한하여
          <br /> 롯데카드 12개월 할부 무이자 지원됩니다.
        </EventText>
        <CloseButton onClick={handleClose}>
          <CloseBtnImg src={closeButtonImg} alt="close-button-img" />
        </CloseButton>
        <CloseOneDayButton onClick={handleCloseDay}>오늘 그만 보기</CloseOneDayButton>
      </PopupContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const PopupContainer = styled.div`
  position: relative;
  width: 920px;
  height: 627px;
  border-radius: 25px;
  z-index: 10;
  background-image: url(${popUpBackground});
  background-size: contain;

  @media ${theme.laptop} {
    width: 480px;
    height: 600px;
    background-size: cover;
  }

  @media ${theme.mobile} {
    width: 360px;
    height: 600px;
    background-size: cover;
  }
`;

const EventImg = styled.img`
  display: block;
  width: 781px;
  height: 365px;
  margin: 30px auto;

  @media ${theme.laptop} {
    width: 400px;
    height: auto;
    margin: 120px auto 50px;
    object-fit: cover;
  }

  @media ${theme.mobile} {
    width: 300px;
    height: auto;
    margin: 120px auto 50px;
    object-fit: cover;
  }
`;

const EventText = styled.p`
  color: #b3b3b3;
  font-size: 16px;
  font-family: nanumGothicR;
  text-align: center;
  margin: 21px 0 25px 0;
  line-height: 22px;
  padding-left: 30px;
  padding-right: 30px;

  @media ${theme.laptop} {
    padding: 0 20px;
    font-size: 12px;
  }

  @media ${theme.mobile} {
    padding: 0 20px;
    font-size: 12px;
  }
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const EventStrong = styled.div`
  color: #feda49;
  font-family: nanumGothicEB;
  font-size: 24px;
`;

const Button = styled.button`
  padding-left: 24px;
  padding-right: 24px;
  height: 38px;
  border-radius: 25px;
  background-color: #4ca1af;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const ButtonText = styled.p``;

const ButtonIcon = styled.p`
  margin: 5px 0 0 10px;
  font-size: 20px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CloseBtnImg = styled.img`
  widdth: 50px;
  height: 50px;
`;

const CloseOneDayButton = styled.button`
  width: 136px;
  height: 32px;
  background-color: #292d32;
  border-radius: 25px;
  color: #ffffff;
  position: absolute;
  top: 30px;
  left: 30px;
  font-family: nanumGothicB;
  font-size: 16px;
`;

export default ReservationPopUp;
