import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import PopUpDefault from 'components/atoms/PopUp';
import { SquareCheckbox } from 'components/atoms/Checkbox';

const checkboxList = [
  {
    id: 1,
    checkbox: 'No.',
  },
  {
    id: 2,
    checkbox: 'ID',
  },
  {
    id: 3,
    checkbox: '이름',
  },
  {
    id: 4,
    checkbox: '학교',
  },
  {
    id: 5,
    checkbox: '학년',
  },
  {
    id: 6,
    checkbox: '시작일',
  },
  {
    id: 7,
    checkbox: '만점횟수',
  },
  {
    id: 8,
    checkbox: '정답률',
  },
  {
    id: 9,
    checkbox: '오답률',
  },
  {
    id: 10,
    checkbox: '잠금해제 횟수',
  },
  {
    id: 11,
    checkbox: '잠금해제 평균',
  },
  {
    id: 12,
    checkbox: 'Grade',
  },
  {
    id: 13,
    checkbox: 'Class',
  },
  {
    id: 14,
    checkbox: '이메일',
  },
  {
    id: 15,
    checkbox: '전화번호',
  },
  {
    id: 16,
    checkbox: '보호자 연락처',
  },
  {
    id: 17,
    checkbox: '모델명',
  },
  {
    id: 18,
    checkbox: '회원가입 형태',
  },
  {
    id: 19,
    checkbox: '사용상태',
  },
  {
    id: 20,
    checkbox: '상품권 발급 횟수',
  },
  {
    id: 21,
    checkbox: '학습 초기화 횟수',
  },
  {
    id: 22,
    checkbox: '지역별',
  },
  {
    id: 23,
    checkbox: '보호자 이메일',
  },
];

const ListViewSettingPopUp = ({ visible, handleClose, handleConfirm }) => {
  const [checkedList, setCheckedList] = useState([]);

  const handleCheck = useCallback(
    (checked, item) => {
      let result = null;
      if (checked) {
        result = checkedList.filter((el) => el !== item.id);
      } else {
        result = [...checkedList, item.id];
      }
      setCheckedList(result);
    },
    [checkedList],
  );

  useEffect(() => {
    if (!visible) {
      return;
    }
    const savedCheckedList = localStorage.getItem('@checkedList');
    if (!savedCheckedList || JSON.parse(savedCheckedList).length === 0) {
      setCheckedList(checkboxList.map((i) => i.id));
    } else {
      setCheckedList(JSON.parse(savedCheckedList));
    }
  }, [visible]);

  const handleClickConfirm = () => {
    localStorage.setItem('@checkedList', JSON.stringify(checkedList));
    handleConfirm(checkedList);
    handleClose();
  };

  return (
    <PopUpDefault width="1200" height="370" title="목록 보기 설정" visible={visible}>
      <Content>
        <CheckboxListWrap>
          {checkboxList.map((item) => (
            <CheckBoxWrap key={item.id}>
              <SquareCheckbox
                key={item.name}
                defaultValue={false}
                type="checkbox"
                name={item.name}
                handleClick={() => handleCheck(checkedList.includes(item.id), item)}
                isChecked={checkedList.includes(item.id) ? true : false}
              >
                {item.checkbox}
              </SquareCheckbox>
            </CheckBoxWrap>
          ))}
        </CheckboxListWrap>
        <ButtonWrap>
          <ButtonGray onClick={handleClose}>
            <ButtonText>취 소</ButtonText>
          </ButtonGray>
          <ButtonBlue onClick={handleClickConfirm}>
            <ButtonText>확 인</ButtonText>
          </ButtonBlue>
        </ButtonWrap>
      </Content>
    </PopUpDefault>
  );
};

const Content = styled.div`
  padding: 0 50px 30px;
`;

const CheckboxListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CheckBoxWrap = styled.div`
  padding: 5px 10px;
  background-color: #e6e6e6;
  border-radius: 3px;
  margin: 30px 0 0 5px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 65px 0 0 0;
`;

const ButtonGray = styled.button`
  width: 235px;
  height: 38px;
  border-radius: 25px;
  background-color: #b3b3b3;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;

  & + & {
    margin: 0 0 0 26px;
  }
`;

const ButtonBlue = styled(ButtonGray)`
  background-color: #4ca1af;
`;

const ButtonText = styled.p``;

export default ListViewSettingPopUp;
