import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import PopUpDefault from 'components/atoms/PopUp';
import SelectOptions from 'components/atoms/SelectOptions';
import { putWordApi } from 'api/API';

const EditWordPopup = ({ visible, selectedWord, handleRefresh, handleClose }) => {
  const levelOptions = [
    { text: '초등학교', code: 'Elementary' },
    { text: '중학교', code: 'Middle' },
    { text: '고등학교', code: 'High' },
  ];

  const gradeOptions = [
    { text: '1', code: '1' },
    { text: '2', code: '2' },
    { text: '3', code: '3' },
    { text: '4', code: '4' },
    { text: '수능', code: '수능' },
  ];

  const wordTypeOptions = [
    { text: '명사', code: 'Noun' },
    { text: '대명사', code: 'Pronoun' },
    { text: '동사', code: 'Verb' },
    { text: '형용사', code: 'Adjective' },
    { text: '부사', code: 'Adverb' },
    { text: '접속사', code: 'Conjunction' },
    { text: '전치사', code: 'Preposition' },
    { text: '감탄사', code: 'Interjection' },
    { text: '숙어', code: 'Idiom' },
  ];
  const [inputs, setInputs] = useState({
    id: selectedWord?.id,
    levelType: selectedWord?.levelType,
    wordType: selectedWord?.wordType,
    wordEnglish: selectedWord?.wordEnglish,
    wordKorean: selectedWord?.wordKorean,
    grade: selectedWord?.grade,
    classes: selectedWord?.classes,
    option1: selectedWord?.options ? JSON.parse(selectedWord?.options)['1'] : '',
    option2: selectedWord?.options ? JSON.parse(selectedWord?.options)['2'] : '',
    option3: selectedWord?.options ? JSON.parse(selectedWord?.options)['3'] : '',
    year: selectedWord?.year,
    month: selectedWord?.month,
    number: selectedWord?.number,
  });

  const {
    id,
    levelType,
    wordType,
    wordEnglish,
    wordKorean,
    grade,
    classes,
    option1,
    option2,
    option3,
    year,
    month,
    number,
  } = inputs;

  const setLevelType = (val) => {
    setInputs({ ...inputs, levelType: val.code });
  };

  const setWordType = (val) => {
    setInputs({ ...inputs, wordType: val.code });
  };

  const setGrade = (val) => {
    setInputs({ ...inputs, grade: val.code });
  };

  useEffect(() => {
    setInputs({
      id: selectedWord?.id,
      levelType: selectedWord?.levelType,
      wordType: selectedWord?.wordType,
      wordEnglish: selectedWord?.wordEnglish,
      wordKorean: selectedWord?.wordKorean,
      grade: selectedWord?.grade,
      classes: selectedWord?.classes,
      option1: selectedWord?.options ? JSON.parse(selectedWord?.options)['1'] : '',
      option2: selectedWord?.options ? JSON.parse(selectedWord?.options)['2'] : '',
      option3: selectedWord?.options ? JSON.parse(selectedWord?.options)['3'] : '',
      year: selectedWord?.year,
      month: selectedWord?.month,
      number: selectedWord?.number,
    });
  }, [selectedWord]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (
      !levelType ||
      !wordType ||
      !wordEnglish ||
      !wordKorean ||
      !grade ||
      !classes ||
      !option1 ||
      !option2 ||
      !option3
    ) {
      alert('필수 값을 입력해 주세요.');
      return;
    }
    const body = {
      id: id,
      classes: classes,
      levelType: levelType,
      grade: grade,
      wordEnglish: wordEnglish,
      wordType: wordType,
      wordKorean: wordKorean,
      options: JSON.stringify({ 1: option1, 2: option2, 3: option3 }),
      year: year,
      month: month,
      number: number,
    };
    putWordApi(body)
      .then((res) => {
        handleRefresh();
        handleClose();
      })
      .catch(() => {
        alert('요청에 실패했습니다');
      });
  };

  const selectedLevelType = useMemo(() => {
    return levelOptions.filter((i) => i.code === levelType)?.[0];
  }, [levelOptions, levelType]);

  const selectedGrade = useMemo(() => {
    return gradeOptions.filter((i) => i.code === grade)?.[0];
  }, [gradeOptions, grade]);

  const selectedWordType = useMemo(() => {
    return wordTypeOptions.filter((i) => i.code === wordType)?.[0];
  }, [wordType, wordTypeOptions]);

  return (
    <PopUpDefault width="1020" height="510" title="단어 편집" visible={visible}>
      <Content>
        <FlexWrap>
          <Box>
            <Label>
              <LabelText>Grade</LabelText>
            </Label>
            <div>
              <SelectOptions
                value={selectedLevelType}
                style={{ flex: 1, marginTop: 12 }}
                onSelect={(val) => setLevelType(val)}
                options={levelOptions}
              />
            </div>
          </Box>
          <Box>
            <Label>
              <LabelText>난이도</LabelText>
            </Label>
            <div>
              <SelectOptions
                value={selectedGrade}
                style={{ flex: 1, marginTop: 12 }}
                onSelect={(val) => setGrade(val)}
                options={gradeOptions}
              />
            </div>
          </Box>
          <Box>
            <Label>
              <LabelText>Class</LabelText>
            </Label>
            <Input
              type="number"
              name="classes"
              placeholder="class를 입력해 주세요"
              value={classes}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>Word</LabelText>
            </Label>
            <Input
              type="text"
              name="wordEnglish"
              placeholder="단어를 입력해 주세요"
              value={wordEnglish}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>Pos</LabelText>
            </Label>
            <SelectOptions
              value={selectedWordType}
              style={{ flex: 1, marginTop: 12 }}
              onSelect={(val) => setWordType(val)}
              options={wordTypeOptions}
            />
          </Box>
        </FlexWrap>
        <FlexWrap>
          <Box>
            <Label>
              <LabelText>Meaning</LabelText>
            </Label>
            <Input
              type="text"
              name="wordKorean"
              placeholder="뜻을 입력해 주세요"
              value={wordKorean}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>후보1</LabelText>
            </Label>
            <Input
              type="text"
              name="option1"
              placeholder="후보1을 입력해 주세요"
              value={option1}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>후보2</LabelText>
            </Label>
            <Input
              type="text"
              name="option2"
              placeholder="후보2를 입력해 주세요"
              value={option2}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>후보3</LabelText>
            </Label>
            <Input
              type="text"
              name="option3"
              placeholder="후보3을 입력해 주세요"
              value={option3}
              onChange={onChange}
            />
          </Box>
        </FlexWrap>
        <FlexWrap>
          <Box>
            <Label>
              <LabelText>Year</LabelText>
            </Label>
            <Input
              type="number"
              name="year"
              placeholder="년도를 입력해 주세요"
              value={year}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>Month</LabelText>
            </Label>
            <Input
              type="number"
              name="month"
              placeholder="월을 입력해 주세요"
              value={month}
              onChange={onChange}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>Number</LabelText>
            </Label>
            <Input
              type="number"
              name="number"
              placeholder="번호를 입력해 주세요"
              value={number}
              onChange={onChange}
            />
          </Box>
        </FlexWrap>
        <ButtonWrap>
          <ButtonGray>
            <ButtonText onClick={handleClose}>취 소</ButtonText>
          </ButtonGray>
          <ButtonBlue onClick={handleSave}>
            <ButtonText>저장</ButtonText>
          </ButtonBlue>
        </ButtonWrap>
      </Content>
    </PopUpDefault>
  );
};

const Content = styled.div`
  padding: 24px 35px 0;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
`;

const Box = styled.div`
  flex: 1;
  padding-right: 4px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const LabelText = styled.p`
  color: #3c8895;
  font-family: nanumGothicB;
  font-size: 16px;
  margin: 0 0 0 5px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;
  padding: 0 10px;
  -webkit-appearance: none;
  margin-top: 4px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 120px 0 0 0;
`;

const ButtonGray = styled.button`
  width: 235px;
  height: 38px;
  border-radius: 25px;
  background-color: #b3b3b3;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;

  & + & {
    margin: 0 0 0 26px;
  }
`;

const ButtonBlue = styled(ButtonGray)`
  background-color: #4ca1af;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.p``;

const ButtonIcon = styled.p`
  margin: 5px 0 0 10px;
`;

export default EditWordPopup;
