import React from 'react';
import styled from 'styled-components';
import { useCountdown } from 'components/utils/useCountdown';
import theme from 'styles/theme';

import time from 'resources/time.png';

const ExpiredNotice = () => {
  return <p>finish</p>;
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <CounterWrap>
      <TimeBox>
        <TimeImg src={time} alt="time-background-img" />
        <TimeText>{days}</TimeText>
        <DayText>일</DayText>
      </TimeBox>
      <Colon>:</Colon>
      <TimeBox>
        <TimeImg src={time} alt="time-background-img" />
        <TimeText>{hours < 10 ? `0${hours}` : hours}</TimeText>
        <DayText>시</DayText>
      </TimeBox>
      <Colon>:</Colon>
      <TimeBox>
        <TimeImg src={time} alt="time-background-img" />
        <TimeText>{minutes < 10 ? `0${minutes}` : minutes}</TimeText>
        <DayText>분</DayText>
      </TimeBox>
      <Colon>:</Colon>
      <TimeBox>
        <TimeImg src={time} alt="time-background-img" />
        <TimeText>{seconds < 10 ? `0${seconds}` : seconds}</TimeText>
        <DayText>초</DayText>
      </TimeBox>
    </CounterWrap>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ShowCounter days={0} hours={0} minutes={0} seconds={0} />;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  }
};

const CounterWrap = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.mobile} {
    justify-content: center;
  }
`;

const TimeBox = styled.div`
  width: 100px;
  height: 100px;
  position: relative;

  @media ${theme.mobile} {
    width: 65px;
    height: 65px;
  }
`;

const TimeImg = styled.img`
  width: 100px;
  height: 100px;
  @media ${theme.mobile} {
    width: 65px;
    height: 65px;
  }
`;

const TimeText = styled.p`
  color: #e6e6e6;
  position: absolute;
  top: 11px;
  left: 0;
  font-family: helveticaNeueB;
  font-size: 64px;
  width: 100px;
  text-align: center;

  @media ${theme.mobile} {
    font-size: 40px;
    width: 65px;
    top: 5px;
  }
`;

const DayText = styled.p`
  color: #ffffff;
  position: absolute;
  bottom: 5px;
  font-family: nanumGothicB;
  font-size: 10px;
  width: 100px;
  text-align: center;

  @media ${theme.mobile} {
    width: 65px;
  }
`;

const Colon = styled.p`
  font-size: 64px;
  font-family: nanumGothicB;
  margin: 0 5px;
`;

export default CountdownTimer;
