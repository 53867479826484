import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import appIcon from 'resources/app_icon_main.png';
import { Link, useLocation } from 'react-router-dom';
import theme from 'styles/theme';
import { RiMenu3Fill } from 'react-icons/ri';
import { Mobile, Others } from 'styles/MediaQuery';

const PageLayout = ({ children, moveToPurchase }) => {
  const location = useLocation();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  return (
    <Wrap>
      <Nav>
        <NavInnerWrap>
          <LogoWrap>
            <LogoImg onClick={() => (window.location.href = '/')} src={appIcon} alt="app-icon" />
            <LogoText>
              <span>수</span>능영어 일등급
              <br />
              <span>토</span>익 만점
              <br />
              <span>폰</span>
            </LogoText>
          </LogoWrap>
          {/* <Others>
            <MenuWrap>
              <MenuList>
                <Menu active={location.pathname === '/reservation'}>
                  <Link
                    style={
                      location.pathname === '/reservation'
                        ? { fontFamily: 'nanumGothicB', color: '#292d32' }
                        : {}
                    }
                    to="/reservation"
                  >
                    수토폰이 무엇인가요?
                  </Link>
                </Menu>
                <Menu active={location.pathname === '/reservation/orderConfirm'}>
                  <Link
                    style={
                      location.pathname === '/reservation/orderConfirm'
                        ? { fontFamily: 'nanumGothicB', color: '#292d32' }
                        : {}
                    }
                    to="/reservation/orderConfirm"
                  >
                    주문 확인하기
                  </Link>
                </Menu>
              </MenuList>
              <Button onClick={moveToPurchase}>구입하기</Button>
            </MenuWrap>
          </Others>
          <Mobile>
            <HamburgerMenu onClick={handleMobileMenu}>
              <RiMenu3Fill />
            </HamburgerMenu>
          </Mobile> */}
        </NavInnerWrap>
      </Nav>
      {children}
      <Footer>
        <FooterInnerWrap>
          <FooterText>
            판매자명/상호명: 지앤더블유랩(주) | 사업자등록번호: 690-87-03005 | 사업자 전화번호:
            010-9900-4523 <br />
            이메일: gnwlab@naver.com | 대표자명/담당자명: 서세욱 | 주소: 서울특별시 강남구 역삼로
            464-2, 4층 401호(대치동)
          </FooterText>
        </FooterInnerWrap>
      </Footer>
      {openMobileMenu && (
        <Mobile>
          <MobileNav>
            <MobileMenuWrap>
              <MobileMenuList>
                <MobileMenu active={location.pathname === '/reservation'}>
                  <Link
                    style={
                      location.pathname === '/reservation'
                        ? { fontFamily: 'nanumGothicB', color: '#292d32' }
                        : {}
                    }
                    to="/reservation"
                  >
                    수토폰이 무엇인가요?
                  </Link>
                </MobileMenu>
                <MobileMenu active={location.pathname === '/reservation/orderConfirm'}>
                  <Link
                    style={
                      location.pathname === '/reservation/orderConfirm'
                        ? { fontFamily: 'nanumGothicB', color: '#292d32' }
                        : {}
                    }
                    to="/reservation/orderConfirm"
                  >
                    주문 확인하기
                  </Link>
                </MobileMenu>
              </MobileMenuList>
              <Button onClick={moveToPurchase}>구입하기</Button>
            </MobileMenuWrap>
          </MobileNav>
        </Mobile>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-top: 180px;

  @media ${theme.laptop} {
    padding-top: 90px;
    min-width: 360px;
  }

  @media ${theme.mobile} {
    padding-top: 90px;
    min-width: 360px;
  }
`;

const Nav = styled.div`
  height: 180px;
  border-bottom: 3px solid #e6e6e6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 1;

  @media ${theme.laptop} {
    height: 90px;
  }

  @media ${theme.mobile} {
    height: 90px;
  }
`;

const NavInnerWrap = styled.div`
  width: 1070px;
  height: 180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media ${theme.laptop} {
    width: 100%;
    height: 90px;
    padding-left: 12px;
  }

  @media ${theme.mobile} {
    width: 100%;
    height: 90px;
    padding-left: 12px;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 25px 0;

  @media ${theme.laptop} {
    margin: 0 0 15px 0;
  }

  @media ${theme.mobile} {
    margin: 0 0 15px 0;
  }
`;

const LogoImg = styled.img`
  width: 128px;
  height: 128px;

  @media ${theme.laptop} {
    height: 60px;
    width: 60px;
  }

  @media ${theme.mobile} {
    height: 60px;
    width: 60px;
  }
`;

const LogoText = styled.p`
  font-family: nanumGothicB;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -1.2px;
  color: #666666;
  margin: 0 0 0 18px;

  & > span {
    color: #4ca1af;
  }

  @media ${theme.laptop} {
    font-size: 14px;
    line-height: 16px;
  }

  @media ${theme.mobile} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const MenuWrap = styled.div`
  display: flex;
  align-items: center;
`;

const MenuList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

const Menu = styled.li`
  color: #666666;
  font-size: 14px;
  font-family: nanumGothicR;
  padding: 20px 0;

  & + & {
    margin: 0 0 0 50px;

    @media ${theme.mobile} {
      margin: 0 0 0 20px;
    }
  }

  & > a {
    color: #666666;
  }

  ${(props) =>
    props.active === true &&
    css`
      border-bottom: 3px solid #4ca1af;
    `}
`;

const Button = styled.button`
  width: 106px;
  height: 30px;
  background-color: #b3b3b3;
  color: #ffffff;
  font-family: nanumGothicB;
  border-radius: 30px;
  margin: 0 0 0 50px;

  @media ${theme.mobile} {
    margin: 80px 0 0 0;
    width: 100%;
    font-size: 18px;
    height: 50px;
  }
`;

const Footer = styled.div`
  background-color: #d9d9d9;
`;

const FooterInnerWrap = styled.div`
  width: 1070px;
  height: 82px;
  margin: 0 auto;
  padding: 23px 0;

  @media ${theme.laptop} {
    width: auto;
    height: auto;
    display: block;
    padding: 23px 20px;
  }

  @media ${theme.mobile} {
    width: auto;
    height: auto;
    display: block;
    padding: 23px 20px;
  }
`;

const FooterText = styled.p`
  font-family: nanumGothicB;
  font-size: 10px;
  color: #000000;
  line-height: 18px;
`;

const HamburgerMenu = styled.div`
  font-size: 30px;
  margin: 0 10px 20px 0;
  cursor: pointer;
`;

const MobileNav = styled.div`
  background-color: #eeeeee;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 90px);
  z-index: 30;
  top: 90px;
  left: 0;
  padding: 30px 40px;
`;

const MobileMenuWrap = styled.div``;

const MobileMenuList = styled.ul`
  margin: 0;
  padding: 0;
`;

const MobileMenu = styled.li`
  color: #666666;
  font-size: 18px;
  font-family: nanumGothicR;
  padding: 20px 0;

  & + & {
    margin: 20px 0 0 0;
  }

  & > a {
    color: #666666;
  }

  ${(props) =>
    props.active === true &&
    css`
      border-bottom: 3px solid #4ca1af;
    `}
`;

export default PageLayout;
