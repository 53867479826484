import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import authStore from 'stores/AuthStore';

const DefaultPageLayout = ({ children }) => {
  const location = useLocation();
  const { signOut } = authStore;

  const handleLogout = () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
    signOut();
  };
  return (
    <Wrap>
      <Nav>
        <NavInnerWrap>
          <LogoWrap>G&W</LogoWrap>
          <MenuWrap>
            <MenuList>
              <Menu active={location.pathname === '/admin/wordManagement'}>
                <Link to="/admin/wordManagement">단어 문제 관리</Link>
              </Menu>
              <Menu active={location.pathname === '/admin/dashboard'}>
                <Link to="/admin/dashboard">사용자 현황 보기</Link>
              </Menu>
              <Menu active={location.pathname === '/admin/current'}>
                <Link to="/admin/current">단말기 현황 보기</Link>
              </Menu>
              <Menu active={location.pathname === '/admin/product'}>
                <Link to="/admin/product">판매 현황 관리</Link>
              </Menu>
            </MenuList>
            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          </MenuWrap>
        </NavInnerWrap>
      </Nav>
      {children}
    </Wrap>
  );
};

const Wrap = styled.div``;

const Nav = styled.div`
  height: 95px;
  border-bottom: 3px solid #e6e6e6;
`;

const NavInnerWrap = styled.div`
  width: 1660px;
  height: 95px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const LogoWrap = styled.p`
  font-size: 40px;
  color: #666666;
  font-family: helveticaNeueB;
  margin: 0 0 20px 0;
`;

const MenuWrap = styled.div`
  display: flex;
  align-items: center;
`;

const MenuList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

const Menu = styled.li`
  color: #666666;
  font-size: 14px;
  font-family: nanumGothicR;
  padding: 20px 0;

  & + & {
    margin: 0 0 0 35px;
  }

  & > a {
    color: #666666;
  }

  ${(props) =>
    props.active === true &&
    css`
      border-bottom: 3px solid #4ca1af;
      font-family: nanumGothicB;

      & > a {
        color: #292d32;
      }
    `}
`;

const LogoutButton = styled.button`
  margin: 0 0 0 35px;
  color: #292d32;
  font-size: 14px;
  font-family: nanumGothicB;
  background-color: transparent;
`;

export default DefaultPageLayout;
