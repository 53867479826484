import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import DefaultPageLayout from 'components/templates/PageLayout';
import stores from 'stores';
import { observer } from 'mobx-react';

import phoneModel1 from 'resources/model_fold_5.png';
import phoneModel2 from 'resources/model_flip_5.png';
import phoneModel5 from 'resources/model_s_24.png';
import phoneModelS242 from 'resources/model_s_24_2.png';
import { getDistinctModels } from 'api/API';

const Current = observer(() => {
  const { dashboardStore } = stores;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getDistinctModels()
      .then((res) => {
        const modelData = res.data;
        for (let i = 0; i < modelData.length; i++) {
          if (modelData[i].productName?.charCodeAt(0) === 65279) {
            modelData[i].productName = modelData[i].productName.substr(
              1,
              modelData[i].productName.length,
            );
          }
          if (modelData[i].model?.charCodeAt(0) === 65279) {
            modelData[i].model = modelData[i].model.substr(1, modelData[i].model.length);
          }
          if (modelData[i].modelDetail?.charCodeAt(0) === 65279) {
            modelData[i].modelDetail = modelData[i].modelDetail.substr(
              1,
              modelData[i].modelDetail.length,
            );
          }
          if (modelData[i].colorHex?.charCodeAt(0) === 65279) {
            modelData[i].colorHex = modelData[i].colorHex.substr(1, modelData[i].colorHex.length);
          }
          if (modelData[i].colorName?.charCodeAt(0) === 65279) {
            modelData[i].colorName = modelData[i].colorName.substr(
              1,
              modelData[i].colorName.length,
            );
          }
          modelData[i].colorHex = modelData[i].colorHex?.replace(/[^\x00-\x7F]/g, '');
        }
        setProducts(modelData);
      })
      .catch((err) => {
        console.error(err);
        alert('제품 데이터 로딩에 실패했습니다.');
      });
  }, []);

  return (
    <DefaultPageLayout>
      <Wrap>
        <Title>수능일등 단말기 현황</Title>
        <PhoneWrap>
          <PhoneFlexWrap>
            {products.map((item) => (
              <PhoneBox>
                <PhoneImgWrap>
                  {' '}
                  {item.model === 'SM-F731N' && <PhoneImg src={phoneModel2} alt="content-img" />}
                  {item.model === 'SM-F946N' && <PhoneImg src={phoneModel1} alt="content-img" />}
                  {item.model === 'SM-S928N' && <PhoneImg src={phoneModel5} alt="content-img" />}
                  {item.model === 'SM-S921N' && <PhoneImg src={phoneModelS242} alt="content-img" />}
                </PhoneImgWrap>
                <PhoneName>{item.productName}</PhoneName>

                <PhoneNum>{item.totalSellCount}</PhoneNum>
              </PhoneBox>
            ))}
          </PhoneFlexWrap>
        </PhoneWrap>
      </Wrap>
    </DefaultPageLayout>
  );
});

const Wrap = styled.div`
  height: calc(100vh - 95px);
  background-color: #f7f7f7;
  padding: 45px 0 0 0;
`;

const Title = styled.p`
  color: #000000;
  font-size: 36px;
  font-family: nanumGothicEB;
  text-align: center;
`;

const PhoneWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 30px 0;
  justify-content: center;
`;

const PhoneFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PhoneBox = styled.div`
  width: 240px;
  height: 330px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px 20px;
  margin: 10px 15px 10px 15px;
`;

const PhoneImgWrap = styled.div`
  width: 138px;
  height: 150px;
  margin: 0 auto;
`;

const PhoneImg = styled.img`
  height: 150px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const PhoneName = styled.p`
  color: #000000;
  font-size: 18px;
  font-family: nanumGothicB;
  text-align: center;
  margin: 20px 0 0 0;
`;

const PhoneNum = styled.p`
  color: #4ca1af;
  font-size: 60px;
  font-family: helveticaNeueB;
  text-align: center;
  margin: 5px 0 0 0;
`;

const SpecColor = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 20px;
  border: 3px solid #ffffff;
  cursor: pointer;
  background-color: ${(props) => props.colorHex || 'white'};
`;

export default Current;
