import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IoCaretDown } from 'react-icons/io5';
import { FaRegCircle, FaTimes } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from 'styles/theme';
import { Mobile, Others } from 'styles/MediaQuery';

import PageLayout from 'components/templates/ReservationPageLayout';
import CountdownTimer from 'components/atoms/CountdownTimer';
import PurchasePopUp from 'components/atoms/PurchasePopUp';
import ReservationPopUp from './ReservationPopUp';

import kakaoChannelImg from 'resources/logo_kakao_channel.png';
import DeliveryTruckImg from 'resources/delivery_truck.png';
import contentImg01 from 'resources/content_img_01.png';
import contentImg01Mobile from 'resources/content_img_01_mobile.png';
import content01 from 'resources/content_01.png';
import content01Mobile from 'resources/content_01_mobile.png';
import event01 from 'resources/event_01_re.png';
import event02 from 'resources/event_02.png';
import content02 from 'resources/content_02.png';
import content02Mobile from 'resources/content_02_mobile.png';
import content03 from 'resources/content_03.png';
import content03Mobile from 'resources/content_03_mobile.png';
import content04 from 'resources/content_04.png';
import content04Mobile from 'resources/content_04_mobile.png';
import content05_title from 'resources/content_05_title.png';
import content05_titleMobile from 'resources/content_05_title_mobile.png';
import content06 from 'resources/content_06.png';
import content06Mobile from 'resources/content_06_mobile.png';
import content07 from 'resources/content_07.png';
import content07Mobile from 'resources/content_07.png';
import phoneModel1 from 'resources/model_fold_5.png';
import phoneModel2 from 'resources/model_flip_5.png';
import phoneModel3 from 'resources/model_s_23_fe.png';
import phoneModel5 from 'resources/model_s_24.png';
import phoneModelS242 from 'resources/model_s_24_2.png';
import { getModels } from 'api/API';
import ReservationPopUp2 from './ReservationPopUp2';

const phone = [
  {
    name: '갤럭시 Z 폴드5',
    code: 'SM-F946N',
    img: phoneModel1,
    standardPrice: '2,306,560원',
    eventPrice: '1,801,310원',
    price1: '[롯데카드 캐시백]  -120,000',
    price2: '[예상 보상금액]  -385,280',
    commingSoon: false,
    volumn: '256',
    colorCode: '#000000',
    colorText: '블랙',
    colors: [{ code: '#000000', model: 'SM-F946NZKAKOO' }],
    display: [
      { name: '크기 (Main Display)', spec: '192.1 mm' },
      { name: '해상도 (Main)', spec: '2176 x 1812 (QXGA+)' },
      { name: '종류 (Main)', spec: 'Dynamic AMOLED 2X' },
      { name: '크기 (Sub)', spec: '157.3 mm' },
      { name: '해상도 (Sub)', spec: '2316 x 904 (HD+)' },
      { name: '종류 (Sub)', spec: 'Dynamic AMOLED 2X' },
    ],
    processor: [
      { name: 'CPU 속도', spec: '3.36 GHz,2.8 GHz,2 GHz' },
      { name: 'CPU 종류', spec: 'Octa-Core' },
    ],
    spen: true,
    camera: [
      { name: '후면 카메라 - 화소 (Multiple)', spec: '50.0 MP + 12.0 MP + 10.0 MP' },
      { name: '후면 카메라 - 조리개 값 (Multiple)', spec: 'F1.8 , F2.2 , F2.4' },
      { name: '후면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - OIS', spec: '예' },
      { name: '후면 카메라 - 줌', spec: '3배 광학줌, 최대 30배 디지털줌' },
      { name: '후면 카메라 - 플래쉬', spec: '예' },
      { name: '커버 카메라 - 화소', spec: '10.0 MP' },
      { name: '커버 카메라 - 조리개 값', spec: 'F2.2' },
      { name: '커버 카메라 - 오토 포커스', spec: '아니오' },
      { name: '동영상 녹화 해상도', spec: 'UHD 8K (7680 x 4320) @30fps' },
      { name: '슬로우 모션', spec: '960fps @FHD,240fps @FHD' },
      { name: '언더 디스플레이 카메라 - 화소', spec: '4.0 MP' },
      { name: '언더 디스플레이 카메라 - 조리개 값', spec: 'F1.8' },
      { name: '언더 디스플레이 카메라 - 오토 포커스', spec: '아니오' },
      { name: '언더 디스플레이 카메라 - OIS', spec: '아니오' },
    ],
    memory: [
      { name: '메모리 (GB)', spec: '12 GB' },
      { name: '스토리지(저장 용량) (GB)', spec: '256 GB' },
      { name: '사용 가능한 스토리지(저장 용량) (GB)', spec: '217.9 GB' },
    ],
    network: [{ name: 'SIM 개수', spec: 'Dual-SIM' }],
    battery: [
      {
        name: '인터넷 사용 시간(LTE) (Hours)',
        spec: '최대 17',
      },
      {
        name: '인터넷 사용 시간(Wi-Fi) (Hours)',
        spec: '최대 18',
      },
      {
        name: '비디오 재생 시간 (Hours, Wireless)',
        spec: '최대 21',
      },
      {
        name: '배터리 용량(mAh, Typical)',
        spec: '4400',
      },
      {
        name: '교체 가능',
        spec: '아니오',
      },
      {
        name: '오디오 재생 시간 (Hours, Wireless)',
        spec: '최대 73',
      },
      {
        name: '연속 통화시간(4G LTE) (Hours)',
        spec: '최대 40',
      },
    ],
    audioVideo: [
      {
        name: '스테레오 지원',
        spec: '예',
      },
      {
        name: '동영상 지원 포맷',
        spec: 'MP4,M4V,3GP,3G2,AVI,FLV,MKV,WEBM',
      },
      {
        name: '동영상 지원 해상도',
        spec: 'UHD 8K (7680 x 4320) @60fps',
      },
      {
        name: '오디오 지원 포맷',
        spec: 'MP3,M4A,3GA,AAC,OGG,OGA,WAV,AMR,AWB,FLAC,MID,MIDI,XMF,MXMF,IMY,RTTTL,RTX,OTA,DFF,DSF,APE',
      },
    ],
    outside: [
      { name: `크기\n(세로x가로x두께, mm)`, spec: '154.9 x 129.9 x 6.1' },
      { name: `접힌 상태시 크기\n(세로x가로x두께, mm)`, spec: '154.9 x 67.1 x 13.4' },
      { name: '무게(g)', spec: '253' },
    ],
  },
  {
    name: '갤럭시 Z 플립5',
    code: 'SM-F731N',
    img: phoneModel2,
    standardPrice: '1,641,090원',
    eventPrice: '1,307,430원',
    price1: '[롯데카드 캐시백]  -120,000',
    price2: '[예상 보상금액]  -213,660',
    commingSoon: false,
    volumn: '256',
    colors: [
      { code: '#7cb29b', model: 'SM-F731NLGAKOO' },
      { code: '#b8b8b8', model: 'SM-F731NZAAKOO' },
      { code: '#eae6ce', model: 'SM-F731NZEAKOO' },
    ],
    colorCode: '#eeedeb',
    colorText: '크림',
    display: [
      { name: '크기 (Main Display)', spec: '170.3 mm' },
      { name: '해상도 (Main)', spec: '2640 x 1080 (FHD+)' },
      { name: '종류 (Main)', spec: 'Dynamic AMOLED 2X' },
      { name: '크기 (Sub)', spec: '86.1 mm' },
      { name: '해상도 (Sub)', spec: '720 x 748' },
      { name: '종류 (Sub)', spec: 'Super AMOLED' },
    ],
    processor: [
      { name: 'CPU 속도', spec: '3.36 GHz,2.8 GHz,2 GHz' },
      { name: 'CPU 종류', spec: 'Octa-Core' },
    ],
    spen: false,
    camera: [
      { name: '후면 카메라 - 화소 (Multiple)', spec: '12.0 MP + 12.0 MP' },
      { name: '후면 카메라 - 조리개 값 (Multiple)', spec: 'F1.8 , F2.2' },
      { name: '후면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - OIS', spec: '예' },
      { name: '후면 카메라 - 줌', spec: '최대 10배 디지털줌' },
      { name: '전면 카메라 - 화소', spec: '10.0 MP' },
      { name: '전면 카메라 - 조리개 값', spec: 'F2.2' },
      { name: '전면 카메라 - 오토 포커스', spec: '아니오' },
      { name: '후면 카메라 - 플래쉬', spec: '예' },
      { name: '동영상 녹화 해상도', spec: 'UHD 4K (3840 x 2160) @60fps' },
      { name: '슬로우 모션', spec: '960fps @FHD,240fps @FHD' },
    ],
    memory: [
      { name: '메모리 (GB)', spec: '8 GB' },
      { name: '스토리지(저장 용량) (GB)', spec: '256 GB' },
      { name: '사용 가능한 스토리지(저장 용량) (GB)', spec: '217.9 GB' },
    ],
    network: [{ name: 'SIM 개수', spec: 'Dual-SIM' }],
    battery: [
      {
        name: '인터넷 사용 시간(LTE) (Hours)',
        spec: '최대 17',
      },
      {
        name: '인터넷 사용 시간(Wi-Fi) (Hours)',
        spec: '최대 17',
      },
      {
        name: '비디오 재생 시간 (Hours, Wireless)',
        spec: '최대 20',
      },
      {
        name: '배터리 용량(mAh, Typical)',
        spec: '3700',
      },
      {
        name: '교체 가능',
        spec: '아니오',
      },
      {
        name: '오디오 재생 시간 (Hours, Wireless)',
        spec: '최대 57',
      },
      {
        name: '연속 통화시간(4G LTE) (Hours)',
        spec: '최대 35',
      },
    ],
    audioVideo: [
      {
        name: '스테레오 지원',
        spec: '예',
      },
      {
        name: '동영상 지원 포맷',
        spec: 'MP4,M4V,3GP,3G2,AVI,FLV,MKV,WEBM',
      },
      {
        name: '동영상 지원 해상도',
        spec: 'UHD 8K (7680 x 4320) @60fps',
      },
      {
        name: '오디오 지원 포맷',
        spec: 'MP3,M4A,3GA,AAC,OGG,OGA,WAV,AMR,AWB,FLAC,MID,MIDI,XMF,MXMF,IMY,RTTTL,RTX,OTA,DFF,DSF,APE',
      },
    ],
    outside: [
      { name: `크기\n(세로x가로x두께, mm)`, spec: '165.1 x 71.9 x 6.9' },
      { name: `접힌 상태시 크기\n(세로x가로x두께, mm)`, spec: '85.1 x 71.9 x 15.1' },
      { name: '무게(g)', spec: '187' },
    ],
  },
  {
    name: '갤럭시 S24 Ultra',
    code: 'SM-S928N',
    img2: phoneModel5,
    standardPrice: '1,923,790원',
    eventPrice: '1,493,330원',
    price1: '[롯데카드 캐시백]  -120,000',
    price2: '[예상 보상금액]  -310,460',
    commingSoon: false,
    volumn: '256',
    colors: [
      { code: '#424242', model: 'SM-S928NZKEKOO' },
      { code: '#b8b8b8', model: 'SM-S928NZTEKOO' },
      { code: '#655876', model: 'SM-S928NZVEKOO' },
      { code: '#e5dba7', model: 'SM-S928NZYEKOO' },
    ],
    colorCode: '#000000',
    colorText: '블랙',
    display: [
      { name: '크기 (Main Display)', spec: '172.5 mm' },
      { name: '해상도 (Main)', spec: '3120 x 1440 (Quad HD+)' },
      { name: '종류 (Main)', spec: 'Dynamic AMOLED 2X' },
      { name: '색심도 (Main)', spec: '16 M' },
      { name: '최대 주사율 (Main)', spec: '120 Hz' },
    ],
    processor: [
      { name: 'CPU', spec: '갤럭시용 스냅드래곤8 3세대 모바일 플랫폼' },
      { name: 'CPU 속도', spec: '3.39 GHz, 3.1 GHz, 2.9 GHz, 2.2 GHz' },
      { name: 'CPU 종류', spec: 'Octa-Core' },
    ],
    spen: true,
    camera: [
      { name: '후면 카메라 - 화소 (Multiple)', spec: '200.0 MP + 50.0 MP + 12.0 MP + 10.0 MP' },
      { name: '후면 카메라 - 조리개 값 (Multiple)', spec: 'F1.7,F3.4,F2.2,F2.4' },
      { name: '후면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - OIS', spec: '예' },
      {
        name: '후면 카메라 - 줌',
        spec: '3배 및 5배 광학 줌, 광학 줌 수준의 2배 및 10배줌(적응형 픽셀 센서 활용), 최대 100배 디지털 줌',
      },
      { name: '전면 카메라 - 화소', spec: '12.0 MP' },
      { name: '전면 카메라 - 조리개 값', spec: 'F2.2' },
      { name: '전면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - 플래쉬', spec: '예' },
      { name: '후면 카메라 - Laser AF 센서', spec: '예' },
      { name: '동영상 녹화 해상도', spec: 'UHD 8K (7680 x 4320) | @30fps' },
      { name: '슬로우 모션', spec: '240fps @FHD,120fps @UHD' },
    ],
    memory: [
      { name: '메모리 (GB)', spec: '12 GB' },
      { name: '스토리지(저장 용량) (GB)', spec: '256 GB' },
      { name: '사용 가능한 스토리지(저장 용량) (GB)', spec: '231.0 GB' },
    ],
    network: [{ name: 'SIM 개수', spec: 'Dual-SIM' }],
    battery: [
      {
        name: '인터넷 사용 시간(LTE) (Hours)',
        spec: '최대 27',
      },
      {
        name: '인터넷 사용 시간(Wi-Fi) (Hours)',
        spec: '최대 28',
      },
      {
        name: '비디오 재생 시간 (Hours, Wireless)',
        spec: '최대 30',
      },
      {
        name: '배터리 용량(mAh, Typical)',
        spec: '5000',
      },
      {
        name: '교체 가능',
        spec: '아니오',
      },
      {
        name: '오디오 재생 시간 (Hours, Wireless)',
        spec: '최대 95',
      },
    ],
    audioVideo: [
      {
        name: '스테레오 지원',
        spec: '예',
      },
      {
        name: '동영상 지원 포맷',
        spec: 'MP4, M4V, 3GP, 3G2, AVI, FLV, MKV, WEBM',
      },
      {
        name: '동영상 지원 해상도',
        spec: 'UHD 8K (7680 x 4320) @60fps',
      },
      {
        name: '오디오 지원 포맷',
        spec: 'MP3, M4A, 3GA, AAC, OGG, OGA, WAV, AMR, AWB, FLAC, MID,MIDI, XMF, MXMF, IMY, RTTTL,RTX, OTA, DFF, DSF, APE',
      },
    ],
    outside: [
      { name: `크기\n(세로x가로x두께, mm)`, spec: '162.3 x 79.0 x 8.6' },
      { name: '무게(g)', spec: '232' },
    ],
  },
  {
    name: '갤럭시 S24',
    code: 'SM-S921N',
    img: phoneModelS242,
    standardPrice: '1,402,390원',
    eventPrice: '1,074,840원',
    price1: '[롯데카드 캐시백]  -120,000',
    price2: '[예상 보상금액]  -208,550',
    volumn: '256',
    colors: [
      { code: '#424242', model: 'SM-S921NZAEKOO' },
      { code: '#b8b8b8', model: 'SM-S921NZKEKOO' },
      { code: '#655876', model: 'SM-S921NZVEKOO' },
    ],
    colorCode: '#000000',
    colorText: '블랙',
    display: [
      { name: '크기 (Main Display)', spec: '156.4 mm' },
      { name: '해상도 (Main)', spec: '2340 x 1080 (FHD+)' },
      { name: '종류 (Main)', spec: 'Dynamic AMOLED 2X' },
      { name: '색상도 (Main)', spec: '16 M' },
      { name: '최대 주사율 (Main)', spec: '120 Hz' },
    ],
    processor: [
      { name: 'CPU', spec: '엑시노스 2400' },
      { name: 'CPU 속도', spec: '3.2 GHz, 2.9 GHz, 2.6 GHz, 1.95 GHz' },
      { name: 'CPU 종류', spec: 'Deca-Core' },
    ],
    spen: false,
    camera: [
      { name: '후면 카메라 - 화소 (Multiple)', spec: '50.0 MP + 10.0 MP + 12.0 MP' },
      { name: '후면 카메라 - 조리개 값 (Multiple)', spec: 'F1.8, F2.4, F2.2' },
      { name: '후면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - OIS', spec: '예' },
      {
        name: '후면 카메라 - 줌',
        spec: '3배 광학 줌, 광학 줌 수준의 2배 줌 (적응형 픽셀 센서 활용), 최대 30배 디지털 줌',
      },
      { name: '전면 카메라 - 화소', spec: '12.0 MP' },
      { name: '전면 카메라 - 조리개 값', spec: 'F2.2' },
      { name: '전면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - 플래쉬', spec: '예' },
      { name: '후면 카메라 - Laser AF 센서', spec: '예' },
      { name: '동영상 녹화 해상도', spec: 'UHD 8K (7680 x 4320) | @30fps' },
      { name: '슬로우 모션', spec: '240fps @FHD,120fps @UHD' },
    ],
    memory: [
      { name: '메모리 (GB)', spec: '8 GB' },
      { name: '스토리지(저장 용량) (GB)', spec: '256 GB' },
      { name: '사용 가능한 스토리지(저장 용량) (GB)', spec: '232.7 GB' },
    ],
    network: [{ name: 'SIM 개수', spec: 'Dual-SIM' }],
    battery: [
      {
        name: '인터넷 사용 시간(LTE) (Hours)',
        spec: '최대 25',
      },
      {
        name: '인터넷 사용 시간(Wi-Fi) (Hours)',
        spec: '최대 25',
      },
      {
        name: '비디오 재생 시간 (Hours, Wireless)',
        spec: '최대 29',
      },
      {
        name: '배터리 용량(mAh, Typical)',
        spec: '4000',
      },
      {
        name: '교체 가능',
        spec: '아니오',
      },
      {
        name: '오디오 재생 시간 (Hours)',
        spec: '최대 78',
      },
    ],
    audioVideo: [
      {
        name: '스테레오 지원',
        spec: '예',
      },
      {
        name: '동영상 지원 포맷',
        spec: 'MP4, M4V, 3GP, 3G2, AVI, FLV, MKV, WEBM',
      },
      {
        name: '동영상 지원 해상도',
        spec: 'UHD 8K (7680 x 4320) @60fps',
      },
      {
        name: '오디오 지원 포맷',
        spec: 'MP3, M4A, 3GA, AAC, OGG, OGA, WAV, AMR, AWB, FLAC, MID, MIDI, XMF, MXMF, IMY, RTTTL, RTX, OTA, DFF, DSF, APE',
      },
    ],
    outside: [
      { name: `크기\n(세로x가로x두께, mm)`, spec: '147 x 70.6 x 7.6' },
      { name: '무게(g)', spec: '167' },
    ],
  },
  {
    name: '갤럭시 S23FE',
    code: 'SM-S711N',
    img: phoneModel3,
    standardPrice: '1,105,390원',
    eventPrice: '866,390원',
    price1: '[롯데카드 캐시백]  -120,000',
    price2: '[예상 보상금액]  -119,000',
    commingSoon: false,
    volumn: '256',
    display: [
      { name: '크기 (Main Display)', spec: '163.1 mm' },
      { name: '해상도 (Main)', spec: '1080 x 2340 (FHD+)' },
      { name: '종류 (Main)', spec: 'Dynamic AMOLED 2X' },
      { name: '색심도 (Main)', spec: '16 M' },
      { name: '최대 주사율 (Main)', spec: '120 Hz' },
    ],
    processor: [
      { name: 'CPU 속도', spec: '2.8 GHz,2.5 GHz,1.8 GHz' },
      { name: 'CPU 종류', spec: 'Octa-Core' },
      { name: 'CPU 종류', spec: '삼성 엑시노스 2200' },
    ],
    spen: false,
    camera: [
      { name: '후면 카메라 - 화소 (Multiple)', spec: '50.0 MP + 5.0 MP + 8.0 MP' },
      { name: '후면 카메라 - 조리개 값 (Multiple)', spec: 'F1.8 , F2.2 , F2.4' },
      { name: '후면 카메라 - 오토 포커스', spec: '예' },
      { name: '후면 카메라 - OIS', spec: '예' },
      { name: '후면 카메라 - 줌', spec: '3배 광학줌, 최대 30배 디지털줌' },
      { name: '후면 카메라 - 플래쉬', spec: '예' },
      { name: '전면 카메라 - 화소', spec: '10.0 MP' },
      { name: '전면 카메라 - 조리개 값', spec: 'F2.4' },
      { name: '전면 카메라 - 오토 포커스', spec: '아니오' },
      { name: '전면 카메라 - OIS', spec: '아니오' },
      { name: '후면 카메라 - 플래쉬', spec: '예' },
      { name: '동영상 녹화 해상도', spec: 'UHD 8K (7680 x 4320) | @24fps' },
      { name: '슬로우 모션', spec: '960fps @HD,240fps @FHD' },
    ],
    memory: [
      { name: '메모리 (GB)', spec: '8 GB' },
      { name: '스토리지(저장 용량) (GB)', spec: '256 GB' },
      { name: '사용 가능한 스토리지(저장 용량) (GB)', spec: '235.7 GB' },
    ],
    network: [{ name: 'SIM 개수', spec: 'Dual-SIM' }],
    battery: [
      {
        name: '인터넷 사용 시간(LTE) (Hours)',
        spec: '최대 19',
      },
      {
        name: '인터넷 사용 시간(Wi-Fi) (Hours)',
        spec: '최대 20',
      },
      {
        name: '비디오 재생 시간 (Hours)',
        spec: '최대 22',
      },
      {
        name: '배터리 용량(mAh, Typical)',
        spec: '4500',
      },
      {
        name: '교체 가능',
        spec: '아니오',
      },
      {
        name: '오디오 재생 시간 (Hours)',
        spec: '최대 69',
      },
      {
        name: '연속 통화시간(4G LTE) (Hours)',
        spec: '최대 39',
      },
    ],
    audioVideo: [
      {
        name: '스테레오 지원',
        spec: '예',
      },
      {
        name: '동영상 지원 포맷',
        spec: 'MP4,M4V,3GP,3G2,AVI,FLV,MKV,WEBM',
      },
      {
        name: '동영상 지원 해상도',
        spec: 'UHD 8K (7680 x 4320) @60fps',
      },
      {
        name: '오디오 지원 포맷',
        spec: 'MP3,M4A,3GA,AAC,OGG,OGA,WAV,AMR,AWB,FLAC,MID,MIDI,XMF,MXMF,IMY,RTTTL,RTX,OTA,DFF,DSF,APE',
      },
    ],
    outside: [
      { name: `크기\n(세로x가로x두께, mm)`, spec: '158.0 x 76.5 x 8.2' },
      { name: '무게(g)', spec: '209' },
    ],
  },
];

const ReservationMain = () => {
  const targetDate = new Date('2024-05-01');
  const navigate = useNavigate();

  const [productColors, setProductColors] = useState({});
  const [productAmounts, setProductAmounts] = useState({});
  // useEffect(() => {
  //   getModels()
  //     .then((res) => {
  //       const modelData = res.data;
  //       let productAmountMap = {};
  //       let productColorMap = {};
  //       for (let i = 0; i < modelData?.length; i++) {
  //         if (modelData[i].model.charCodeAt(0) === 65279) {
  //           modelData[i].model = modelData[i].model.substr(1, modelData[i].model.length);
  //         }
  //         if (modelData[i].modelDetail.charCodeAt(0) === 65279) {
  //           modelData[i].modelDetail = modelData[i].modelDetail.substr(
  //             1,
  //             modelData[i].modelDetail.length,
  //           );
  //         }
  //         if (modelData[i].colorHex.charCodeAt(0) === 65279) {
  //           modelData[i].colorHex = modelData[i].colorHex.substr(1, modelData[i].colorHex.length);
  //         }
  //         if (productAmountMap[modelData[i]?.model]) {
  //           productAmountMap[modelData[i]?.model] += modelData[i]?.amount;
  //         } else {
  //           productAmountMap[modelData[i]?.model] = modelData[i]?.amount;
  //         }

  //         if (!productColorMap[modelData[i]?.model]) {
  //           if (modelData[i]?.amount > 0) {
  //             productColorMap[modelData[i]?.model] = [
  //               { model: modelData[i]?.modelDetail, code: modelData[i]?.colorHex },
  //             ];
  //           }
  //         } else {
  //           if (modelData[i]?.amount > 0) {
  //             productColorMap[modelData[i]?.model].push({
  //               model: modelData[i]?.modelDetail,
  //               code: modelData[i]?.colorHex,
  //             });
  //           }
  //         }
  //       }
  //       console.log(productAmountMap);
  //       console.log(productColorMap);
  //       setProductAmounts(productAmountMap);
  //       setProductColors(productColorMap);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       alert('제품 데이터 로딩에 실패했습니다.');
  //     });
  // }, []);

  // const [selectedModels, setSelectedModels] = useState([
  //   { key: 'SM-F946N', value: 'SM-F946NZKAKOO' },
  //   { key: 'SM-F731N', value: 'SM-F731NLGAKOO' },
  //   { key: 'SM-S928N', value: 'SM-S928NZKEKOO' },
  //   { key: 'SM-S921N', value: 'SM-S921NZAEKOO' },
  // ]);

  const [selectedModel, setSelectedModel] = useState();

  const [openSpec, setOpenSpec] = useState(false);
  const [mobileOpenSpec, setMobileOpenSpec] = useState('');

  const handleClickOpenSpec = () => {
    setOpenSpec(!openSpec);
  };

  const handleClickMobileOpenSpec = (id) => {
    if (mobileOpenSpec === id) {
      setMobileOpenSpec('');
    } else {
      setMobileOpenSpec(id);
    }
  };

  const [showPurchasePopUp, setShowPurchasePopUp] = useState(false);

  const handleOpenPurchasePopUp = (model) => {
    setSelectedModel(model);
    setShowPurchasePopUp(true);
  };

  const handleClosePurchasePopUp = () => {
    setShowPurchasePopUp(false);
  };

  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUp2, setShowPopUp2] = useState(false);

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };
  const handleClosePopUp2 = () => {
    setShowPopUp2(false);
  };

  const [hasCookie, setHasCookie] = useState(false);
  const [hasCookie2, setHasCookie2] = useState(false);
  const [cookies, setCookies] = useCookies();

  const getExpiredDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    console.log('expiredDate', date);
    return date;
  };

  useEffect(() => {
    if (!cookies['CSAT_WEB_COOKIE']) {
      setHasCookie(false);
      setShowPopUp(true);
    } else {
      setHasCookie(true);
      setShowPopUp(false);
    }

    if (!cookies['CSAT_WEB_COOKIE2']) {
      setHasCookie2(false);
      setShowPopUp2(true);
    } else {
      setHasCookie2(true);
      setShowPopUp2(false);
    }
  }, []);

  const handleCloseDay = () => {
    const expires = getExpiredDate(1);
    setCookies('CSAT_WEB_COOKIE', true, { path: '/reservation', expires });
    setCookies('CSAT_WEB_COOKIE', true, { path: '/', expires });
    setShowPopUp(false);
  };

  const handleCloseDay2 = () => {
    const expires = getExpiredDate(1);
    setCookies('CSAT_WEB_COOKIE2', true, { path: '/reservation', expires });
    setCookies('CSAT_WEB_COOKIE2', true, { path: '/', expires });
    setShowPopUp2(false);
  };

  const goToDetailRef = useRef();

  useEffect(() => {
    if (!showPopUp && goToDetailRef.current) {
      const rect = document.getElementById('detailSection').getBoundingClientRect();
      window.scrollTo({ top: rect.y + window.scrollY - 180, behavior: 'smooth' });
      goToDetailRef.current = false;
    }
  }, [showPopUp]);

  const goToDetail = () => {
    goToDetailRef.current = true;
    setShowPopUp(false);
  };

  const handleClickOrderConfirm = () => {
    navigate('/reservation/orderConfirm');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleClickKakaoChannel = () => {
    window.open('http://pf.kakao.com/_lxlKGG');
  };

  const startCertification = () => {};

  const moveToDetail = () => {
    console.log('moveToDetail');
    const rect = document.getElementById('detailSection').getBoundingClientRect();
    window.scrollTo({ top: rect.y + window.scrollY - 180, behavior: 'smooth' });
    goToDetailRef.current = false;
  };

  const handleClickLink = () => {
    window.open('http://www.sktsmart.com');
  };

  return (
    <PageLayout moveToPurchase={moveToDetail}>
      <ZeroSection>
        <ZeroInnerSection>
          <EventImg11 src={event01} />
        </ZeroInnerSection>
      </ZeroSection>
      <FirstSection>
        <EventImg12 src={event02} />
        <Link onClick={handleClickLink} />
        {/* <CountdownTimer targetDate={targetDate} /> */}
      </FirstSection>
      {/* <Content1Wrap>
        <Others>
          <ContentImg src={content01} alt="content-img" />
        </Others>
        <Mobile>
          <ContentImg src={content01Mobile} alt="content-img" />
        </Mobile>
      </Content1Wrap> */}
      {/* <Others>
        <Relative>
          <ContentImg1 src={contentImg01} alt="content-img" />
          <Click1
            onClick={() =>
              window.open(
                'https://www.lottecard.co.kr/app/LPBNFDA_V591.lc?evn_bult_seq=8584&cco_evn_gubun=SU1',
              )
            }
          ></Click1>
          <Click2
            onClick={() =>
              window.open(
                'http://www.lottecard.co.kr/app/LPBOHAA_V100.lc?bId=92957&vtCdKndC=P14718-A14718',
              )
            }
          ></Click2>
        </Relative>
      </Others> */}
      {/* <Mobile>
        <Relative>
          <ContentImg src={contentImg01Mobile} alt="content-img" />
          <Click1Mobile
            onClick={() =>
              window.open(
                'https://m.lottecard.co.kr/app/LPBNFDA_V591.lc?evn_bult_seq=8583&cco_evn_gubun=SU1',
              )
            }
          ></Click1Mobile>
          <Click2Mobile
            onClick={() =>
              window.open(
                'http://m.lottecard.co.kr/spa/card/booth?bId=92957&vtCdKndC=P14718-A14718',
              )
            }
          ></Click2Mobile>
        </Relative>
      </Mobile> */}
      <Others>
        <ContentImg2 src={content02} alt="content-img" />
      </Others>
      <Mobile>
        <ContentImg src={content02Mobile} alt="content-img" />
      </Mobile>
      <Others>
        <Wrapper>
          <ContentImg3 src={content03} alt="content-img" />
        </Wrapper>
      </Others>
      <Mobile>
        <ContentImg src={content03Mobile} alt="content-img" />
      </Mobile>
      <Others>
        <ContentImg4 src={content04} alt="content-img" />
      </Others>
      <Mobile>
        <ContentImg src={content04Mobile} alt="content-img" />
      </Mobile>
      {/* <FiveSection id="detailSection">
        <Others>
          <FiveSectionTitleImg src={content05_title} alt="content-title-img" />
        </Others>
        <Mobile>
          <FiveSectionTitleImg src={content05_titleMobile} alt="content-title-img" />
        </Mobile>
        <FiveSectionContentWrap>
          <Others>
            <FiveSectionPhoneMap>
              {phone.map((item) => (
                <FiveSectionPhone>
                  <PhoneTitle>{item.name}</PhoneTitle>
                  <PhoneCode>{item.code}</PhoneCode>
                  {!!productAmounts[item.code] && item.img && (
                    <PhoneImg src={item.img} alt="content-img" />
                  )}
                  {!!productAmounts[item.code] && item.img2 && (
                    <PhoneImg2 src={item.img2} alt="content-img" />
                  )}
                  {!productAmounts[item.code] && <PhoneImgGrey src={item.img} alt="content-img" />}
                  {!!productAmounts[item.code] && (
                    <>
                      <StikeThrough>
                        <OriginalPrice>기준가 {item.standardPrice}</OriginalPrice>
                      </StikeThrough>
                      <EventPriceText>{item.price1}</EventPriceText>
                      <EventPriceText>{item.price2}</EventPriceText>
                      <BlueLabel>수토폰 보상판매 적용가</BlueLabel>
                      <BluePrice>{item.eventPrice}</BluePrice>
                      <BigButton onClick={() => handleOpenPurchasePopUp(item.code)}>
                        구 매 하 기
                      </BigButton>
                    </>
                  )}
                  {!productAmounts[item.code] && <CommingSoonText>입고예정</CommingSoonText>}
                </FiveSectionPhone>
              ))}
            </FiveSectionPhoneMap>
            <MoreButton onClick={handleClickOpenSpec}>
              <ButtonText>스펙 상세보기</ButtonText>
              <CaretIcon active={openSpec} />
            </MoreButton>
            {openSpec && (
              <FiveSectionSpecWrap>
                <SpecTitle>용 량</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>{item.volumn && <SpecBoxText>{item.volumn} GB</SpecBoxText>}</SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>컬 러</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecFlexBox>
                      {productColors[item.code]?.map((colorItem) => (
                        <SpecColor color={colorItem?.code} />
                      ))}
                    </SpecFlexBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>디스플레이</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.display &&
                        item.display.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>프로세서</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.processor &&
                        item.processor.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>S펜 지원</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.spen ? (
                        <SpenIcon>
                          <FaRegCircle />
                        </SpenIcon>
                      ) : (
                        <SpenIcon>
                          <FaTimes />
                        </SpenIcon>
                      )}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>카메라</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.camera &&
                        item.camera.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>메모리 / 스토리지(저장용량)</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.memory &&
                        item.memory.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>네트워크</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.network &&
                        item.network.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>배터리</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.battery &&
                        item.battery.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>오디오 / 비디오</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.audioVideo &&
                        item.audioVideo.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
                <SpecTitle>외관 사양</SpecTitle>
                <SpecFlexWrap>
                  {phone.map((item) => (
                    <SpecBox>
                      {item.outside &&
                        item.outside.map((display) => (
                          <SpecBoxTextWrap>
                            <SpecBoxText>{display.name}</SpecBoxText>
                            <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                          </SpecBoxTextWrap>
                        ))}
                    </SpecBox>
                  ))}
                </SpecFlexWrap>
              </FiveSectionSpecWrap>
            )}
          </Others>
          <Mobile>
            <FiveSectionPhoneMap>
              {phone.map((item) => (
                <MobileFiveSectionPhoneWrap>
                  <FiveSectionPhone>
                    <PhoneTitle>{item.name}</PhoneTitle>
                    <PhoneCode>{item.code}</PhoneCode>
                    {!!productAmounts[item.code] && item.img && (
                      <PhoneImg src={item.img} alt="content-img" />
                    )}
                    {!!productAmounts[item.code] && item.img2 && (
                      <PhoneImg2 src={item.img2} alt="content-img" />
                    )}
                    {!productAmounts[item.code] && (
                      <PhoneImgGrey src={item.img} alt="content-img" />
                    )}
                    {!!productAmounts[item.code] && (
                      <>
                        <StikeThrough>
                          <OriginalPrice>기준가 {item.standardPrice}</OriginalPrice>
                        </StikeThrough>
                        <EventPriceText>{item.price1}</EventPriceText>
                        <EventPriceText>{item.price2}</EventPriceText>
                        <BlueLabel>수토폰 보상판매 적용가</BlueLabel>
                        <BluePrice>{item.eventPrice}</BluePrice>
                        <ButtonWrap>
                          <SmallButton>구매 혜택 보기</SmallButton>
                        </ButtonWrap>
                        <BigButton onClick={() => handleOpenPurchasePopUp(item.code)}>
                          구 매 하 기
                        </BigButton>
                      </>
                    )}
                    {!productAmounts[item.code] && <CommingSoonText>입고 예정</CommingSoonText>}
                  </FiveSectionPhone>
                  {item.code && (
                    <MoreButton onClick={() => handleClickMobileOpenSpec(item.code)}>
                      <ButtonText>스펙 상세보기</ButtonText>
                      <CaretIcon active={mobileOpenSpec === item.code} />
                    </MoreButton>
                  )}
                  {mobileOpenSpec === item.code && (
                    <FiveSectionSpecWrap>
                      <SpecTitle>용 량</SpecTitle>
                      <SpecBox>
                        {item.volumn && <SpecBoxText>{item.volumn} GB</SpecBoxText>}
                      </SpecBox>
                      <SpecTitle>컬 러</SpecTitle>

                      <SpecFlexBox>
                        {productColors[item.code]?.map((colorItem) => (
                          <SpecColor color={colorItem?.code}></SpecColor>
                        ))}
                      </SpecFlexBox>
                      <SpecTitle>디스플레이</SpecTitle>
                      <SpecBox>
                        {item.display &&
                          item.display.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>프로세서</SpecTitle>
                      <SpecBox>
                        {item.processor &&
                          item.processor.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>S펜 지원</SpecTitle>
                      <SpecBox>
                        {!!productAmounts[item.code] &&
                          (item.spen ? (
                            <SpenIcon>
                              <FaRegCircle />
                            </SpenIcon>
                          ) : (
                            <SpenIcon>
                              <FaTimes />
                            </SpenIcon>
                          ))}
                      </SpecBox>
                      <SpecTitle>카메라</SpecTitle>
                      <SpecBox>
                        {item.camera &&
                          item.camera.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>메모리 / 스토리지(저장용량)</SpecTitle>
                      <SpecBox>
                        {item.memory &&
                          item.memory.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>네트워크</SpecTitle>
                      <SpecBox>
                        {item.network &&
                          item.network.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>배터리</SpecTitle>
                      <SpecBox>
                        {item.battery &&
                          item.battery.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>오디오 / 비디오</SpecTitle>
                      <SpecBox>
                        {item.audioVideo &&
                          item.audioVideo.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                      <SpecTitle>외관 사양</SpecTitle>
                      <SpecBox>
                        {item.outside &&
                          item.outside.map((display) => (
                            <SpecBoxTextWrap>
                              <SpecBoxText>{display.name}</SpecBoxText>
                              <SpecBoxTextSmall>{display.spec}</SpecBoxTextSmall>
                            </SpecBoxTextWrap>
                          ))}
                      </SpecBox>
                    </FiveSectionSpecWrap>
                  )}
                </MobileFiveSectionPhoneWrap>
              ))}
            </FiveSectionPhoneMap>
          </Mobile>
        </FiveSectionContentWrap>
      </FiveSection> */}
      <Others>
        <ContentImg6 src={content06} alt="content-img" />
      </Others>
      <Mobile>
        <ContentImg src={content06Mobile} alt="content-img" />
      </Mobile>
      {/* <Others>
        <Center>
          <ContentImg7 src={content07} alt="content-img" />
        </Center>
      </Others>
      <Mobile>
        <ContentImg src={content07Mobile} alt="content-img" />
      </Mobile> */}
      <SevenSection>
        <SevenSectionContentWrap>
          <SevenSectionTitle>
            이용안내
            <br />꼭 확인하세요!
          </SevenSectionTitle>
          <SevenSectionText>
            [ 배송정책 안내 ]<br />
            <br />
            •배송지역 <br />- 도서 산간지역은 배송이 제한될 수 있습니다. <br />
            <br />
            •배송비 <br />- 전상품무료배송(단, 지1주도 등 일부 도서/산간지역은 별도 배송비가 발생할
            수 있습니다.) <br />
            <br />
            •배송기간 <br />- 주문결제 후 평군 3일 이내 배송 (일, 공휴일 제외) 단, 도서/산간지역은
            배송일이 추가적으로 소요될 수 있으며, 상품 보유현황에 따라배송일이 지연될수있습니다.
            또한 인수고객과의 통화불가, 수령지 주소가불명확할 경우 사전 연락(전화 또는 문자 등) 후
            배송이 지연되거나 주문이 취소될 수 였습니다. <br />
            <br />
            •배송방법 <br />- 배송 당일 오전에 SMS로 배송예정을 알리는 문자를 발송해 드립니다. 스팸
            처리 등으로 인해 문자를 받지 못하는 경우가 있으니 택배사 송장번호를 통해 배송 상황을
            확인해주시기 바랍니다. 안전한 배송을 위해 고객님이 주문 시 지정한 배송장소 외 제3자
            전달이나 임의 장소로의 배송은 제공하지 않습니다. <br />
            <br />
            •배송추적 <br />- 수토폰 상단 '주문 확인하기 -＞ (배송 사이트) 주문/배송조회’ 에서
            배송상태 및 택배 기사 전화번호 조회가 가능합니다. <br />
            <br />[ 교환/반품/AS 안내 ]<br />
            <br />
            •교환/반폼기간 <br />
            단순변심에 의한 상품의 교환·반풍은 실제 상품 등율 수령하신 날부터 7일 이내에 가능합니다.
            단, 상품안내 페이지에 표시된 교환/반품 기간이 7일보다 긴 경우에는 그 기간을 따릅니다.
            휴대폰 제품의 교환은 서비스센터에서 발급받은 불량 판정서 지참시 배송완료+7일 가능합니다.
            수령한 상품 등의 내용이 표시·광고 내용과다르거나오배송 등 계약내용과 다르게 이행된
            경우에는 수령 하신 날부터 3개월 이내, 그사실을 안 날 또는 알 수 있었던 날부터 30일
            이내에 가능합니다. 제품을 교환/반품 하가 전 고객센터로 미리 연락을 주셔야 합니다. 제품
            자체에 하자가 있는 경우 삼성전자 서비스센터(1588-3366)로 접수하시면 A/S 기사의 판정을
            거친 후 소비자분쟁해결기준에 의거하여 삼성 전자서비스 센터를 통해
            교환/환불/수리해드립니다. 미성년자인 고객이 구매계약을 체결한 후, 법정대리인이 그 계약에
            동의하지 아니하면 본인 또는 법정대리인이 취소할 수 있습니다. <br />
            <br />
            •교환/반품배송비 <br />
            구매하신 상품의 교환·반품을 하시는 경우애는 상품 등의 반환에 필요한 비용(2,500원)율
            고객님이 부담하셔야 합니다. 단, 고객님이 받으신 상품 등의 내용이 표시·광고 내용과
            다르거나 제품하자·오배송 등 계약내용과 다르게 이행되어 교환/반품을 하시는 경우에는,
            교환·반품 배송비는 무료입니다. <br />
            <br />
            •환불지연 배상금 지급안내 <br />
            대금 환불 및 환불 지연에 따른 배상금 지급 조건, 절차등은 전자상거래 등에서의
            소비자보호에 관한 법률에 따라 처리합니다. <br />
            <br />
            •A/S 안내
            <br />
            지앤더블유랩에서 판매되는 상품에 대해서는 가까운 삼성전자서비스센터를 방문하시면 A/S가
            가능합니다. (상품A/S) 일부제품의 경우 삼성전자 서비스센터에서 A/S가 불가능하므로, [스펙]
            -&gt; ［상품기본정보&#93; 의 [A/S 책임자와 전화번호] 정보를 확인하시기 바랍니다. <br />
            <br />
            •교환/반품 불가 안내 <br />- 교환/반품 불가사유 <br />- 고객님의 책임 었는 사유로 상품이
            멸실 또는 훼손된 경우
            <br />- (다만, 단순히 상품의 내용율 확인하기 위해 포장 등을 훼손한 경우는 제외)
            <br /> - 고객님의 사용으로 상품가치가 현저히 감소된 경우 <br />- 패키지 상품의 경우
            구성상품 일부의 개봉/설치/사용으로 상품가치가 현저히 감소된 경우 <br />- 무약정폰의 경우
            반품은 상품을수령하신 날부터 7일 이내, 통신사 개통 이전에만 가능합니다. <br />- 시간이
            경과되어 재판매가곤란할 정도로 상품가치가 상실된 경우 <br />- 복제가 가능한 상품의 경우
            그 원본인 상품의 포장이 훼손왼 경우 <br />- 설치완료 상품에 하자가 없는 경우 <br />-
            상품 등의 내용이 표시·광고 내용 및 계약내용과 같고, 별도의 하자가 없음에도 단순변심으로
            인한 교환을 요구하는 경우
            <br />- 기타, '전자상거래 등에서의 소비자보호에 관한 법률' 등 관계법령이 정하는
            교환/반품 제한사유에 해당되는 경우
          </SevenSectionText>
        </SevenSectionContentWrap>
      </SevenSection>
      {/* <EightSection>
        <EightSectionContentWrap>
          <EightSectionTitle>캐시백 혜택 유의사항</EightSectionTitle>
          <EightSectionText>
            · 이용 금액은 쿠폰 및 포인트 사용 등을 제외한 실제 카드 사용금액만 인정 됩니다.
            <br /> · 이 이벤트는 롯데카드사 또는 제휴사 사정에 따라 사전고지 없이 종료되거나 변경될
            수 있습니다.
            <br /> · 결제 취소금액은 이용금액에서 제외됩니다.
            <br /> · 계약체결 전 금융상품설명서와 약관을 확인하시기 바랍니다.
            <br /> · 서비스 제공업종은 롯데카드에 등록된 가맹점 업종을 기준으로 합니다.
            <br /> · 신용카드 발급이 부적정한 경우(개인신용평점 낮음 등) 카드발급이 제한될 수
            있습니다.
            <br /> · 카드이용대금과 이에 수반되는 모든 수수료를 지정된 대금결제일에 상환해야 합니다.
            <br /> · 금융소비자는 금소법 제19조제1항에 따라 해당상품 또는 서비스에 대하여 설명을
            받을 권리가 있으며, 그 설명을 듣고 충분히 이해한 후 거래하시기 바랍니다.
            <br /> · 지앤더블유랩(수토폰)은 롯데카드㈜의 신용카드 회원모집업무를 대리·중개합니다.
            <br />
            · 지앤더블유랩(수토폰)은 다수의 금융회사를 대리·중개합니다.
            <br /> · 지앤더블유랩(수토폰)은 롯데카드㈜의 금융상품 계약체결권한이 없습니다.
            <br /> · 지앤더블유랩(수토폰)은 금융관계법률에 따라 롯데카드(주)와 위탁계약을 체결한
            금융상품판매대리·중개업자입니다.
            <br />{' '}
            <EightSectionTitle>
              •연체이자율 : 회원별ㆍ이용상품별 약정이율 + 최대 3%, 법정 최고금리(연20%) 이내 단,
              연체 발생 시점에 약정이율이 없는 경우 아래와 같이 약정이율을 적용
              <br /> - 일시불 거래 연체 시 : 거래 발생 시점의 최소기간(2개월) 유이자 할부 금리
              <br /> - 무이자 할부 거래 연체 시 : 거래 발생 시점의 동일한 할부 계약기간의 유이자
              할부 금리
              <br /> •상환능력에 비해 신용카드 사용액이 과도할 경우 귀하의 개인신용평점이 하락할 수
              있습니다.
              <br /> •개인신용평점 하락 시 금융거래와 관련된 불이익이 발생할 수 있습니다.
              <br /> •일정기간 원리금을 미납할 경우 모든 원리금을 변제할 의무가 발생할 수 있습니다.
            </EightSectionTitle>
            <br /> ※ 준법감시인 심의필 제2024-E06076호 (2024.04.01~2024.04.30)
            <br /> ※ 여신금융협회 심의필 제2024-C1h-01648호 (2024.02.16 ~ 2025.02.15)
          </EightSectionText>
        </EightSectionContentWrap>
      </EightSection> */}
      {/* <PurchasePopUp
        visible={showPurchasePopUp}
        selectedModel={selectedModel}
        handleClose={handleClosePurchasePopUp}
      /> */}
      {/* {!hasCookie && (
        <ReservationPopUp
          visible={showPopUp}
          handleClose={handleClosePopUp}
          handleCloseDay={handleCloseDay}
          handleGoDetail={goToDetail}
        />
      )} */}
      {!hasCookie2 && (
        <ReservationPopUp2
          visible={showPopUp2}
          handleClose={handleClosePopUp2}
          handleCloseDay={handleCloseDay2}
        />
      )}

      {/* <Content1ButtonWrap bottom={200} onClick={handleClickOrderConfirm}>
        <DeliveryTruckImgTag src={DeliveryTruckImg} />
        <Content1ButtonTxt>주문현황</Content1ButtonTxt>
      </Content1ButtonWrap>
      <Content1ButtonWrap onClick={handleClickKakaoChannel}>
        <KakaoImg src={kakaoChannelImg} />
        <Content1ButtonTxt>문의하기</Content1ButtonTxt>
      </Content1ButtonWrap> */}
    </PageLayout>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

const ZeroSection = styled.div`
  width: 100%;
  background: rgb(245, 245, 245);
  @media ${theme.laptop} {
    padding: 24px 24px 0 24px;
  }
  @media ${theme.mobile} {
    padding: 24px 24px 0 24px;
  }
`;

const ZeroInnerSection = styled.div`
  width: 1070px;
  margin: 0 auto;
  padding: 0px 0 0 0;
  position: relative;

  @media ${theme.laptop} {
    width: auto;
    display: block;
    padding: 0px 0 0 0;
  }
  @media ${theme.mobile} {
    width: auto;
    display: block;
    padding: 0px 0 0 0;
  }
`;

const Link = styled.div`
  cursor: pointer;
  width: 450px;
  height: 80px;
  position: absolute;
  top: 44%;
  left: 12%;
  @media ${theme.laptop} {
    width: 250px;
  }
  @media ${theme.mobile} {
    top: 42%;
    left: 12%;
  }
`;

const FirstSection = styled.div`
  width: 1070px;
  margin: 0 auto;
  padding: 0px 0 80px 0;
  position: relative;

  @media ${theme.laptop} {
    width: auto;
    display: block;
    padding: 0;
  }
  @media ${theme.mobile} {
    width: auto;
    display: block;
    padding: 0;
  }
`;

const EventImg11 = styled.img`
  width: 90%;
  margin: 0 auto;

  @media ${theme.laptop} {
    width: 100%;
    margin: 0 auto;
  }
  @media ${theme.mobile} {
    width: 100%;
    margin: 0 auto;
  }
`;
const EventImg12 = styled.img`
  width: 100%;
  margin: 0 auto;
  margin-top: -23px;

  @media ${theme.laptop} {
    width: 100%;
    margin: 0 auto;
    margin-top: -23px;
  }
  @media ${theme.mobile} {
    width: 100%;
    margin: 0 auto;
    margin-top: -15px;
  }
`;

const FirstSectionLeft = styled.div``;

const SubTitle = styled.p`
  color: #2c81fb;
  font-size: 20px;
  font-family: nanumGothicB;
`;
const Title = styled.p`
  color: #000000;
  font-size: 32px;
  font-family: nanumGothicEB;
  margin: 5px 0 0 0;
`;
const Period = styled.p`
  color: #666666;
  font-size: 20px;
  font-family: helveticaNeueB;
  margin: 15px 0 0 0;
`;

const Desc = styled.p`
  color: #666666;
  font-size: 12px;
  font-family: helveticaNeueB;
  margin: 15px 0 0 0;

  & > span {
    color: #2c81fb;
  }

  @media ${theme.mobile} {
    margin: 15px 0 30px 0;
  }
`;

const Content1Wrap = styled.div`
  position: relative;
`;

const ContentImg = styled.img`
  width: 100vw;
  height: 770px;
  object-fit: cover;

  @media ${theme.laptop} {
    width: 100%;
    height: auto;
    min-width: 360px;
  }

  @media ${theme.mobile} {
    width: 100%;
    height: auto;
    min-width: 360px;
  }
`;

const Content1Desc = styled.div`
  font-size: 24px;
  line-height: 1.5;
  font-family: nanumGothicEB;
  color: #ff4400;
  position: absolute;
  right: calc(50vw - 400px);
  bottom: 235px;

  @media ${theme.mobile} {
    font-size: 10px;
    right: 6%;
    bottom: 30%;
  }
`;

const Content1ButtonWrap = styled.div`
  background: #11111172;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: fixed;
  cursor: pointer;
  bottom: 80px;
  right: calc(50vw - 520px);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 2px 2px 9px 3px grey;
  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom}px;
    `}

  @media ${theme.mobile} {
    right: 20px;
  }
`;

const DeliveryTruckImgTag = styled.img`
  height: 32px;
`;

const KakaoImg = styled.img`
  width: 32px;
  height: 32px;
`;

const Relative = styled.div`
  position: relative;
`;

const Click1 = styled.span`
  z-index: 2;
  cursor: pointer;
  bottom: 450px;
  position: absolute;
  background: transparent;
  width: 800px;
  height: 100px;
  left: calc(50vw - 400px);
`;

const Click2 = styled.span`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  bottom: 80px;
  background: transparent;
  width: 360px;
  height: 80px;
  left: calc(50vw - 180px);
`;
const Click1Mobile = styled.span`
  z-index: 2;
  cursor: pointer;
  bottom: 230px;
  position: absolute;
  background: transparent;
  width: 85%;
  height: 40px;
  left: 10%;
`;

const Click2Mobile = styled.span`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  background: transparent;
  width: 40%;
  height: 40px;
  left: 33%;
`;

const Content1ButtonTxt = styled.div`
  font-family: nanumGothicB;
  font-size: 13px;
  color: white;
  margin-top: 8px;
`;

const ContentImg1 = styled(ContentImg)`
  height: 1480px;
  margin-top: 48px;
`;

const ContentImg2 = styled(ContentImg)`
  height: 714px;
  margin-top: 48px;
`;

const ContentImg3 = styled(ContentImg)`
  width: 668px;
  height: 668px;
  margin: 111px auto;
`;

const ContentImg4 = styled(ContentImg)`
  height: 1957px;
`;

const FiveSection = styled.div`
  width: 1070px;
  margin: -40px auto 0;

  @media ${theme.laptop} {
    width: auto;
  }

  @media ${theme.mobile} {
    width: auto;
  }
`;

const FiveSectionTitleImg = styled.img`
  width: 1070px;

  @media ${theme.laptop} {
    width: 100%;
    height: auto;
    min-width: 360px;
  }

  @media ${theme.mobile} {
    width: 100%;
    height: auto;
    min-width: 360px;
  }
`;

const FiveSectionContentWrap = styled.div`
  margin: 60px 0 0 0;
`;

const FiveSectionPhoneMap = styled.div`
  display: flex;

  @media ${theme.laptop} {
    display: block;
  }

  @media ${theme.mobile} {
    display: block;
  }
`;

const MobileFiveSectionPhoneWrap = styled.div`
  @media ${theme.mobile} {
    & + & {
      margin: 50px 0 0 0;
    }
  }
`;

const FiveSectionPhone = styled.div`
  width: 212px;
  height: 540px;
  background-color: #e6e6e6;
  border-radius: 20px 20px 0 0;
  padding: 30px 15px 40px;
  position: relative;

  & + & {
    margin: 0 0 0 2px;
  }

  @media ${theme.laptop} {
    margin: 0 auto;
    width: 300px;
  }

  @media ${theme.mobile} {
    margin: 0 auto;
    width: 300px;
  }
`;

const PhoneTitle = styled.p`
  font-size: 20px;
  color: #292d32;
  font-family: nanumGothicEB;
  text-align: center;
`;

const PhoneCode = styled.p`
  font-size: 12px;
  color: #666666;
  font-family: nanumGothicR;
  text-align: center;
  margin: 5px 0 0 0;
`;

const PhoneImg = styled.img`
  height: 180px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
`;

const PhoneImg2 = styled.img`
  height: 155px;
  object-fit: cover;
  display: block;
  margin: 24px auto;
`;

const PhoneImgGrey = styled.img`
  height: 180px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  opacity: 0.4;
`;

const GrayLabel = styled.p`
  font-size: 10px;
  color: #b3b3b3;
  font-family: nanumGothicB;
  text-align: right;
  margin: 20px 0 0 0;
`;

const GrayPrice = styled.p`
  font-size: 12px;
  color: #b3b3b3;
  font-family: nanumGothicEB;
  text-align: right;
  margin: 3px 0 0 0;
`;

const OriginalPrice = styled(GrayPrice)`
  color: #b3b3b3;
  margin-top: 32px;
`;

const StikeThrough = styled(GrayPrice)`
  color: #e81a0c;
  text-decoration: line-through;
`;

const EventPriceText = styled(GrayPrice)`
  color: #e54887;
  font-family: nanumGothicB;
`;

const BlueLabel = styled(GrayLabel)`
  font-size: 12px;
  color: #4ca1af;
`;

const BluePrice = styled(GrayPrice)`
  font-size: 28px;
  color: #4ca1af;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 0 0;
`;

const SmallButton = styled.button`
  width: 123px;
  height: 22px;
  border-radius: 30px;
  background-color: #666666;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 10px;
`;

const BigButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 30px;
  background-color: #4ca1af;
  color: #ffffff;
  font-family: nanumGothicEB;
  font-size: 16px;
  margin: 43px 0 0 0;
`;

const CommingSoonText = styled.p`
  font-family: nanumGothicEB;
  font-size: 24px;
  color: #808080;
  text-align: center;
  position: absolute;
  bottom: 200px;
  width: calc(100% - 30px);
`;

const MoreButton = styled.button`
  width: calc(100% - 2px);
  height: 50px;
  background-color: #b3b3b3;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${theme.laptop} {
    margin: 0 auto;
    width: 300px;
  }

  @media ${theme.mobile} {
    margin: 0 auto;
    width: 300px;
  }
`;

const ButtonText = styled.p`
  color: #000000;
  font-family: nanumGothicB;
  font-size: 16px;
`;

const CaretIcon = styled(IoCaretDown)`
  font-size: 20px;
  color: #666666;
  margin: 0 0 0 5px;

  ${(props) =>
    props.active === true &&
    css`
      transform: rotate(180deg);
    `}
`;

const ContentImg6 = styled(ContentImg)`
  height: 1391px;
`;

const ContentImg7 = styled(ContentImg)`
  width: 1072px;
  height: 392px;
  margin: 12px auto;
`;

const Center = styled.div`
  text-align: center;
`;

const SevenSection = styled.div`
  background-color: #666666;
`;

const SevenSectionContentWrap = styled.div`
  width: 1070px;
  margin: -4px auto;
  padding: 65px 0 67px 0;

  @media ${theme.laptop} {
    width: auto;
    display: block;
    padding: 65px 20px 67px;
  }

  @media ${theme.mobile} {
    width: auto;
    display: block;
    padding: 65px 20px 67px;
  }
`;

const SevenSectionTitle = styled.p`
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.2px;
`;

const SevenSectionText = styled.p`
  color: #ffffff;
  font-family: nanumGothicR;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.16px;
  margin: 47px 0 0 0;

  @media ${theme.mobile} {
    font-size: 14px;
  }
`;

const EightSection = styled.div`
  background-color: #f5f5f5;
`;

const EightSectionContentWrap = styled.div`
  width: 1070px;
  margin: -4px auto;
  text-align: left;
  padding: 65px 0 67px 0;

  @media ${theme.laptop} {
    width: auto;
    display: block;
    text-align: left;
    padding: 65px 20px 67px;
  }

  @media ${theme.mobile} {
    width: auto;
    display: block;
    text-align: left;
    padding: 65px 20px 67px;
  }
`;

const EightSectionTitle = styled.p`
  color: #808080;
  font-family: nanumGothicB;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.2px;
  margin: 24px 0 24px 0;
`;

const EightSectionText = styled.p`
  color: #808080;
  font-family: nanumGothicR;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.16px;
  margin: 3px 0 0 0;

  @media ${theme.mobile} {
    font-size: 14px;
  }
`;

const FiveSectionSpecWrap = styled.div``;

const SpecTitle = styled.p`
  background-color: #ffffff;
  width: calc(100% - 2px);
  font-size: 16px;
  color: #000000;
  font-family: nanumGothicB;
  text-align: center;
  height: 28px;
  padding: 4px 0 0 0;

  @media ${theme.mobile} {
    margin: 0 auto;
    width: 300px;
  }
`;

const SpecFlexWrap = styled.div`
  display: flex;
`;

const SpecBox = styled.div`
  width: 212px;
  background-color: #e6e6e6;
  padding: 40px 15px;
  & + & {
    margin: 0 0 0 2px;
  }

  @media ${theme.laptop} {
    margin: 0 auto;
    width: 300px;
  }

  @media ${theme.mobile} {
    margin: 0 auto;
    width: 300px;
  }
`;

const SpecFlexBox = styled(SpecBox)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpecBoxText = styled.p`
  text-align: center;
  font-size: 12px;
  font-family: nanumGothicEB;
  color: #292d32;
  letter-spacing: -0.9px;
  white-space: pre-wrap;
`;

const SpecColor = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 20px;
  border: 3px solid #ffffff;
  background-color: ${(props) => props.color};
  margin: 0 8px 0 8px;
`;

const SpecBoxTextWrap = styled.div`
  & + & {
    margin: 15px 0 0 0;
  }
`;

const SpecBoxTextSmall = styled(SpecBoxText)`
  font-family: nanumGothicR;
  white-space: normal;
  word-wrap: break-word;
`;

const SpenIcon = styled.div`
  font-size: 30px;
  color: #666666;
  text-align: center;
`;

export default ReservationMain;
