import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import PopUpDefault from 'components/atoms/PopUp';
import SelectOptions from 'components/atoms/SelectOptions';
import profileIcon from 'resources/profile.svg';
import bookmarkIcon from 'resources/bookmark.svg';
import callIcon from 'resources/call.svg';
import schoolIcon from 'resources/ic_shcool.svg';
import locationIcon from 'resources/location.svg';
import messageIcon from 'resources/message.svg';
import passwordIcon from 'resources/password.svg';
import { putUserApi, resetPasswordApi, resetSettingPasswordApi } from 'api/API';

const schoolLevelTypeOptions = [
  { text: '초등학교', code: 'Elementary' },
  { text: '중학교', code: 'Middle' },
  { text: '고등학교', code: 'High' },
];

const gradeTypeOptions = [
  { text: '1학년', code: 'First' },
  { text: '2학년', code: 'Second' },
  { text: '3학년', code: 'Third' },
  { text: '4학년', code: 'Fourth' },
  { text: '5학년', code: 'Fifth' },
  { text: '6학년', code: 'Sixth' },
  { text: 'N-수생', code: 'N_th' },
];

const EditUserPopup = ({ visible, selectedUser, handleRefresh, handleClose }) => {
  const [inputs, setInputs] = useState({
    id: selectedUser?.id,
    name: selectedUser?.name,
    cellphoneNumber: selectedUser?.cellphoneNumber,
    email: selectedUser?.email,
    region: selectedUser?.region,
    schoolName: selectedUser?.schoolName,
    gradeType: selectedUser?.gradeType,
    schoolLevelType: selectedUser?.schoolLevelType,
    parentContact: selectedUser?.parentContact,
    parentContact2: selectedUser?.parentContact2,
    parentContact3: selectedUser?.parentContact3,
    settingPassword: selectedUser?.settingPassword,
    emailForSettingPassword: selectedUser?.emailForSettingPassword,
  });

  const [password, setPassword] = useState('');
  const [settingPassword, setSettingPassword] = useState('');

  const {
    id,
    name,
    cellphoneNumber,
    email,
    region,
    schoolName,
    gradeType,
    schoolLevelType,
    parentContact,
    parentContact2,
    parentContact3,
    emailForSettingPassword,
  } = inputs;

  useEffect(() => {
    setInputs({
      id: selectedUser?.id,
      name: selectedUser?.name,
      cellphoneNumber: selectedUser?.cellphoneNumber,
      email: selectedUser?.email,
      region: selectedUser?.region,
      schoolName: selectedUser?.schoolName,
      gradeType: selectedUser?.gradeType,
      schoolLevelType: selectedUser?.schoolLevelType,
      parentContact: selectedUser?.parentContact,
      parentContact2: selectedUser?.parentContact2,
      parentContact3: selectedUser?.parentContact3,
      emailForSettingPassword: selectedUser?.emailForSettingPassword,
    });

    setPassword(selectedUser?.passwordRawTemp);
    setSettingPassword(selectedUser?.settingPasswordRawTemp);
  }, [selectedUser]);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'settingPassword') {
      setSettingPassword(value);
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    if (!name || !cellphoneNumber) {
      alert('필수 값을 입력해 주세요.');
      return;
    }
    const body = {
      id: id,
      name: name,
      cellphoneNumber: cellphoneNumber,
      email: email,
      region: region,
      schoolName: schoolName,
      gradeType: gradeType,
      schoolLevelType: schoolLevelType,
      parentContact: parentContact,
      parentContact2: parentContact2,
      parentContact3: parentContact3,
      emailForSettingPassword: emailForSettingPassword,
    };
    putUserApi(body)
      .then((res) => {
        handleRefresh();
        handleClose();
      })
      .catch(() => {
        alert('요청에 실패했습니다');
      });
  };

  const handleResetPassword = () => {
    resetPasswordApi({ id: id })
      .then((res) => {
        setPassword(res.data);
        alert('요청에 성공했습니다.');
      })
      .catch(() => alert('요청에 실패했습니다.'));
  };

  const handleResetSettingPassword = () => {
    resetSettingPasswordApi({ id: id })
      .then((res) => {
        setSettingPassword(res.data);
        alert('요청에 성공했습니다.');
      })
      .catch(() => alert('요청에 실패했습니다.'));
  };

  const setGradeType = (val) => {
    setInputs({ ...inputs, gradeType: val.code });
  };

  const setSchoolLevelType = (val) => {
    setInputs({ ...inputs, schoolLevelType: val.code });
  };

  const selectedGradeType = useMemo(() => {
    return gradeTypeOptions.filter((i) => i.code === gradeType)?.[0];
  }, [gradeType]);

  const selectedSchoolLevelType = useMemo(() => {
    return schoolLevelTypeOptions.filter((i) => i.code === schoolLevelType)?.[0];
  }, [schoolLevelType]);

  return (
    <PopUpDefault width="1180" height="630" title="사용자 계정 정보" visible={visible}>
      <Content>
        <FlexWrap>
          <Box>
            <Label>
              <LabelIcon src={profileIcon} alt="profile-icon" />
              <LabelText>이름</LabelText>
            </Label>
            <Input type="text" name="nickname" value={name} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={callIcon} alt="call-icon" />
              <LabelText>전화번호</LabelText>
            </Label>
            <Input type="text" name="cellphoneNumber" value={cellphoneNumber} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={messageIcon} alt="message-icon" />
              <LabelText>계정 이메일</LabelText>
            </Label>
            <Input type="text" name="email" value={email} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={passwordIcon} alt="password-icon" />
              <LabelText>계정 비밀번호</LabelText>
            </Label>
            <Row>
              <PasswordInput disabled type="password" value="****" />
              <ButtonReset onClick={handleResetPassword}>비밀번호 초기화</ButtonReset>
            </Row>
            <PasswordDesc>
              * 비밀번호 초기화시 계정 이메일로 임시 비밀번호가 발송됩니다.
            </PasswordDesc>
            <Row>
              <Input disabled value={password} />
              <InfoDesc>
                *발송된 임시 비밀번호 입니다. 사용자 비밀번호 변경시 초기화 합니다.
              </InfoDesc>
            </Row>
            <InfoDesc>*사용자가 비밀번호 변경 전까지만 유효 합니다.</InfoDesc>
          </Box>
        </FlexWrap>
        <FlexWrap>
          <Box>
            <Label>
              <LabelIcon src={locationIcon} alt="location-icon" />
              <LabelText>지역</LabelText>
            </Label>
            <Input type="text" name="region" value={region} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={schoolIcon} alt="school-icon" />
              <LabelText>학교</LabelText>
            </Label>
            <Input type="text" name="schoolName" value={schoolName} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={bookmarkIcon} alt="bookmark-icon" />
              <LabelText>초/중/고</LabelText>
            </Label>
            <SelectOptions
              value={selectedSchoolLevelType}
              style={{ flex: 1, marginTop: 12 }}
              onSelect={(val) => setSchoolLevelType(val)}
              options={schoolLevelTypeOptions}
            />
          </Box>
          <Box>
            <Label>
              <LabelText>학년</LabelText>
            </Label>
            <SelectOptions
              value={selectedGradeType}
              style={{ flex: 1, marginTop: 12 }}
              onSelect={(val) => setGradeType(val)}
              options={gradeTypeOptions}
            />
          </Box>
        </FlexWrap>
        <Divider />
        <FlexWrap>
          <Box>
            <Label>
              <LabelIcon src={callIcon} alt="call-icon" />
              <LabelText>보호자 전화번호 1</LabelText>
            </Label>
            <Input type="text" name="parentContact" value={parentContact} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={callIcon} alt="call-icon" />
              <LabelText>보호자 전화번호 2</LabelText>
            </Label>
            <Input type="text" name="parentContact2" value={parentContact2} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={callIcon} alt="call-icon" />
              <LabelText>보호자 전화번호 3</LabelText>
            </Label>
            <Input type="text" name="parentContact3" value={parentContact3} onChange={onChange} />
          </Box>
          <Box>
            <Label>
              <LabelIcon src={passwordIcon} alt="password-icon" />
              <LabelText>학습설정 비밀번호</LabelText>
            </Label>
            <Row>
              <PasswordInput disabled value="****" type="password" />
              <ButtonReset onClick={handleResetSettingPassword}>비밀번호 초기화</ButtonReset>
            </Row>
            <PasswordDesc>
              * 비밀번호 초기화시 보호자 이메일로 임시 비밀번호가 발송됩니다.
            </PasswordDesc>
            <Row>
              <Input disabled value={settingPassword} />
              <InfoDesc>
                *발송된 임시 비밀번호 입니다. 사용자 비밀번호 변경시 초기화 합니다.
              </InfoDesc>
            </Row>
            <InfoDesc>*사용자가 비밀번호 변경 전까지만 유효 합니다.</InfoDesc>
          </Box>
        </FlexWrap>
        <FlexWrap>
          <Box>
            <Label>
              <LabelIcon src={messageIcon} alt="message-icon" />
              <LabelText>보호자 이메일</LabelText>
            </Label>
            <Input
              type="text"
              name="emailForSettingPassword"
              value={emailForSettingPassword}
              onChange={onChange}
            />
          </Box>
          <Box />
          <Box />
          <Box />
        </FlexWrap>
        <ButtonWrap>
          <ButtonGray>
            <ButtonText onClick={handleClose}>취 소</ButtonText>
          </ButtonGray>
          <ButtonBlue onClick={handleSave}>
            <ButtonText>계정정보 저장</ButtonText>
          </ButtonBlue>
        </ButtonWrap>
      </Content>
    </PopUpDefault>
  );
};

const Content = styled.div`
  padding: 24px 35px 0;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Box = styled.div`
  flex: 1;
  padding-right: 4px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const LabelIcon = styled.img`
  width: 17px;
  height: 17px;
`;

const LabelText = styled.p`
  color: #3c8895;
  font-family: nanumGothicB;
  font-size: 16px;
  margin: 0 0 0 5px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;
  padding: 0 10px;
  -webkit-appearance: none;
  margin-top: 4px;
`;

const PasswordInput = styled(Input)`
  flex: 1;
`;

const PasswordDesc = styled.div`
  color: #e81a0c;
  font-size: 10px;
  font-weight: 300;
  margin-top: 4px;
`;

const InfoDesc = styled(PasswordDesc)`
  color: #2c81fb;
  padding-left: 4px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
`;

const ButtonReset = styled.button`
  flex: 1;
  height: 40px;
  border-radius: 4px;
  background-color: #ff4400;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
`;

const ButtonGray = styled.button`
  width: 235px;
  height: 38px;
  border-radius: 25px;
  background-color: #b3b3b3;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;

  & + & {
    margin: 0 0 0 26px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #4ca1af;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ButtonBlue = styled(ButtonGray)`
  background-color: #4ca1af;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.p``;

const ButtonIcon = styled.p`
  margin: 5px 0 0 10px;
`;

export default EditUserPopup;
