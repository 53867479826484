import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import DefaultPageLayout from 'components/templates/PageLayout';
import UploadExelPopUp from './UploadExelPopUp';
import stores from 'stores';
import { observer } from 'mobx-react';
import Pagination from 'components/atoms/Pagination';

import { FiSearch } from 'react-icons/fi';
import { LuArrowDownSquare, LuArrowUpSquare } from 'react-icons/lu';

import vocaIcon from 'resources/ic_voca_sel.png';
import { API_URL } from 'api/API';
import axios from 'axios';
import EditWordPopup from './EditWordPopup';

const WordManagement = observer(() => {
  const { dashboardStore, authStore } = stores;
  const { getNewWords, totalWords, words } = dashboardStore;

  const [tab, setTab] = useState('Elementary');
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 20;

  const [inputs, setInputs] = useState({ keyword: '' });

  const { keyword } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  useEffect(() => {
    getNewWords(tab, keyword, currentPage - 1, PageSize);
  }, [tab, getNewWords, currentPage, keyword]);

  const handleTab = (tab) => {
    setTab(tab);
  };

  const refresh = () => {
    getNewWords(tab, keyword, currentPage - 1, PageSize);
  };

  const [showExcelPopUp, setShowExcelPopUp] = useState(false);

  const handleShowExcelPopUp = () => {
    setShowExcelPopUp(true);
  };

  const handleCloseExcelPopUp = () => {
    setShowExcelPopUp(false);
  };

  const [selectedWord, setSelectedWord] = useState();

  const [showEditWordPopUp, setShowEditWordPopUp] = useState(false);

  const handleShowEditPopupPopUp = (value) => {
    setSelectedWord(value);
    setShowEditWordPopUp(true);
  };

  const handleCloseEditPopupPopUp = () => {
    setShowEditWordPopUp(false);
  };

  const jsonParse = (string) => {
    return JSON.parse(string);
  };

  const handleExportExcel = () => {
    window.location.href = `${API_URL}/admin/new-words/export/by-excel?levelType=${tab}&token=${axios.defaults.headers.common[
      'Authorization'
    ].replace('Bearer ', '')}`;
  };

  return (
    <DefaultPageLayout>
      <Wrap>
        <TopSection>
          <LeftWrap>
            <TitleWrap>
              <IconImg src={vocaIcon} alt="voca-icon" />
              <Title>단어 잠금 DB</Title>
            </TitleWrap>
            <TabWrap>
              <Tab onClick={() => handleTab('Elementary')} active={tab === 'Elementary'}>
                초급 영어
              </Tab>
              <Tab onClick={() => handleTab('Middle')} active={tab === 'Middle'}>
                중급 영어
              </Tab>
              <Tab onClick={() => handleTab('High')} active={tab === 'High'}>
                고급 영어
              </Tab>
            </TabWrap>
          </LeftWrap>
          <SearchWrap>
            <SearchInput
              name="keyword"
              value={keyword}
              onChange={onChange}
              placeholder="단어검색"
              autoComplete="new-password"
            />
            <SearchIcon />
          </SearchWrap>
        </TopSection>
        <TableWrap>
          <TableHeader>
            <ThShelf width={60}>No.</ThShelf>
            <ThShelf width={70}>Grade</ThShelf>
            <ThShelf width={200}>Words</ThShelf>
            <ThShelf width={60}>POS</ThShelf>
            <ThShelf width={210}>Meaning</ThShelf>
            <ThShelf width={210}>후보 1</ThShelf>
            <ThShelf width={210}>후보 2</ThShelf>
            <ThShelf width={210}>후보 3</ThShelf>
            <ThShelf width={60}>Year</ThShelf>
            <ThShelf width={80}>Month</ThShelf>
            <ThShelf width={90}>Number</ThShelf>
          </TableHeader>
          <TableBody>
            {words.map((item) => (
              <Table onClick={() => handleShowEditPopupPopUp(item)}>
                <TbShelf width={60}>{item.id}</TbShelf>
                <TbShelf width={70}>
                  {tab === 'Elementary' && '초급 '}
                  {tab === 'Middle' && '중급 '}
                  {tab === 'High' && '고급 '}
                  {item.grade}
                </TbShelf>
                <TbShelf width={200}>{item.wordEnglish}</TbShelf>
                <TbShelf width={60}>
                  {item.wordType === 'Noun' && '명사'}
                  {item.wordType === 'Pronoun' && '대명사'}
                  {item.wordType === 'Verb' && '동사'}
                  {item.wordType === 'Adjective' && '형용사'}
                  {item.wordType === 'Adverb' && '부사'}
                  {item.wordType === 'Preposition' && '전치사'}
                  {item.wordType === 'Conjunction' && '접속사'}
                  {item.wordType === 'Idiom' && '숙어'}
                  {item.wordType === 'Interjection' && '감탄사'}
                </TbShelf>
                <TbShelf width={210}>{item.wordKorean}</TbShelf>
                <TbShelf width={210}>{jsonParse(item.options)[1]}</TbShelf>
                <TbShelf width={210}>{jsonParse(item.options)[2]}</TbShelf>
                <TbShelf width={210}>{jsonParse(item.options)[3]}</TbShelf>
                <TbShelf width={60}>{item.year}</TbShelf>
                <TbShelf width={80}>{item.month}</TbShelf>
                <TbShelf width={90}>{item.number}</TbShelf>
              </Table>
            ))}
          </TableBody>
        </TableWrap>
        <PaginationWrap>
          <Pagination
            currentPage={currentPage}
            totalCount={totalWords}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </PaginationWrap>
        <ButtonWrap>
          <Button onClick={handleExportExcel}>
            <ButtonText>엑셀 내보내기</ButtonText>
            <ButtonIcon>
              <LuArrowDownSquare />
            </ButtonIcon>
          </Button>
          <Button>
            <ButtonText onClick={handleShowExcelPopUp}>엑셀 올리기</ButtonText>
            <ButtonIcon>
              <LuArrowUpSquare />
            </ButtonIcon>
          </Button>
        </ButtonWrap>
      </Wrap>
      <UploadExelPopUp visible={showExcelPopUp} handleClose={handleCloseExcelPopUp} />
      <EditWordPopup
        visible={showEditWordPopUp}
        selectedWord={selectedWord}
        handleRefresh={refresh}
        handleClose={handleCloseEditPopupPopUp}
      />
    </DefaultPageLayout>
  );
});

const Wrap = styled.div`
  height: calc(100vh - 95px);
  background-color: #f7f7f7;
  padding: 50px 0 0 0;
`;

const TopSection = styled.div`
  width: 1660px;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrap = styled.div`
  display: flex;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

const IconImg = styled.img`
  width: 34px;
  height: 34px;
`;

const Title = styled.p`
  color: #000000;
  font-size: 20px;
  font-family: nanumGothicB;
`;

const TabWrap = styled.div`
  display: flex;
  margin: 0 0 0 50px;
`;

const Tab = styled.div`
  cursor: pointer;
  width: 125px;
  height: 34px;
  background-color: #b3b3b3;
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  font-family: nanumGothicR;
  text-align: center;
  padding: 7px 0;

  & + & {
    margin: 0 0 0 10px;
  }

  ${(props) =>
    props.active === true &&
    css`
      background-color: #000000;
      font-family: nanumGothicEB;
    `}
`;
const SearchWrap = styled.div`
  width: 315px;
  height: 34px;
  border-radius: 25px;
  background-color: #e6e6e6;
  padding: 7px 15px 7px 25px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  font-family: nanumGothicR;
  border-right: 2px solid #b3b3b3;
  width: 250px;
  -webkit-appearance: none;

  &::placeholder {
    color: #b3b3b3;
  }
`;

const SearchIcon = styled(FiSearch)`
  font-size: 20px;
  color: #b3b3b3;
  margin: 0 0 0 10px;
`;

const TableWrap = styled.div`
  width: 1660px;
  margin: 0 auto;
  border-bottom: 1px solid #b3b3b3;
`;

const TableHeader = styled.div`
  height: 46px;
  background-color: #e6e6e6;
  display: flex;
  justify-content: space-between;
`;

const TableBody = styled.div`
  height: 600px;
  overflow-y: scroll;
`;

const Table = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: #d4e8fb;
  }
`;

const ThShelf = styled.div`
  width: ${(props) => props.width}px;
  color: #292d32;
  font-size: 14px;
  font-family: nanumGothicEB;
  text-align: center;
  padding: 15px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TbShelf = styled(ThShelf)`
  color: #666666;
  font-size: 16px;
  font-family: nanumGothicR;
  padding: 10px 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 1660px;
  margin: 10px auto 0;
  justify-content: flex-end;
`;

const Button = styled.button`
  width: 180px;
  height: 36px;
  border-radius: 25px;
  background-color: #4ca1af;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin: 0 0 0 20px;
  }
`;

const ButtonText = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-family: nanumGothicR;
`;

const ButtonIcon = styled.p`
  color: #ffffff;
  font-size: 18px;
  margin: 4px 0 0 5px;
`;

const PaginationWrap = styled.div`
  margin: 12px 0 0 0;
  height: 34px;
  position: relative;
  padding: 6px 0;
`;

export default WordManagement;
