import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

import PageLayout from 'components/templates/ReservationPageLayout';

import contentImg from 'resources/group_488.png';
import kakaoChannelImg from 'resources/logo_kakao_channel.png';
import { getPaymentApi } from 'api/API';
import { useNavigate } from 'react-router-dom';

const OrderConfirm = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const { name, email, phoneNumber } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleGetPayment = () => {
    getPaymentApi(name, email, phoneNumber)
      .then((res) => {
        const responseData = res.data;
        if (!responseData) {
          alert('주문 정보가 없습니다.');
          return;
        }
        navigate(`/reservation/payment?moid=${encodeURIComponent(responseData.moid)}`);
      })
      .catch((err) => {
        console.error(err);
        alert('요청에 실패했습니다.');
      });
  };

  const handleClickKakaoChannel = () => {
    window.open('http://pf.kakao.com/_lxlKGG');
  };

  return (
    <PageLayout>
      <Wrap>
        <Container>
          <Title>수토폰 주문 확인 하기</Title>
          <Desc>구매시 기입한 주문자 정보를 입력해주세요</Desc>
          <LoginWrap>
            <InputWrap>
              <InputBox>
                <InputLabel>이름</InputLabel>
                <Input
                  name="name"
                  value={name}
                  placeholder="이름을 입력해주세요"
                  onChange={onChange}
                />
              </InputBox>
              <InputBox>
                <InputLabel>이메일</InputLabel>
                <Input
                  name="email"
                  value={email}
                  placeholder="이메일 주소를 입력해주세요"
                  onChange={onChange}
                />
              </InputBox>
              <InputBox>
                <InputLabel>휴대폰 번호</InputLabel>
                <Input
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="휴대폰번호를 입력해주세요"
                  onChange={onChange}
                />
              </InputBox>
            </InputWrap>
            <LoginButton onClick={handleGetPayment}>로그인</LoginButton>
          </LoginWrap>
          <ContentImg src={contentImg} alt="content-img" />
          <KakaoChannelWrap onClick={handleClickKakaoChannel}>
            <KakaoChannelTxt>문의하기</KakaoChannelTxt>
            <KakaoChannelImg src={kakaoChannelImg} alt="kakao-channel-img" />
          </KakaoChannelWrap>
        </Container>
      </Wrap>
    </PageLayout>
  );
};

const Wrap = styled.div``;

const Container = styled.div`
  width: 1070px;
  background-color: #f5f5f5;
  margin: 0 auto;
  padding: 50px 0 150px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${theme.laptop} {
    width: 100vw;
    padding: 50px 20px 150px;
  }

  @media ${theme.mobile} {
    width: 100vw;
    padding: 50px 20px 150px;
  }
`;

const Title = styled.p`
  font-size: 20px;
  color: #666666;
  font-family: nanumGothicB;
  text-align: center;
`;

const Desc = styled.p`
  font-size: 11px;
  color: #666666;
  font-family: nanumGothicR;
  text-align: center;
  margin: 7px 0 0 0;
`;

const LoginWrap = styled.div`
  display: flex;
  margin: 40px 0 0 0;

  @media ${theme.laptop} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${theme.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.laptop} {
    & + & {
      margin: 20px 0 0 0;
    }
  }

  @media ${theme.mobile} {
    & + & {
      margin: 20px 0 0 0;
    }
  }
`;

const InputLabel = styled.p`
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.4px;
  font-family: helveticaNeueB;
  margin: 0 10px 0 0;
  width: 80px;
  text-align: right;
`;

const Input = styled.input`
  width: 400px;
  height: 32px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 15px;
  font-family: nanumGothicR;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 4px inset;
  -webkit-appearance: none;

  &::placeholder {
    color: #b3b3b3;
  }

  @media ${theme.laptop} {
    width: 280px;
  }

  @media ${theme.mobile} {
    width: 280px;
  }
`;

const LoginButton = styled.button`
  width: 79px;
  height: 146px;
  background-color: #4ca1af;
  border-radius: 10px;
  font-family: nanumGothicB;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 0 15px;

  @media ${theme.laptop} {
    width: 250px;
    height: 50px;
    margin: 30px 0 0 60px;
  }

  @media ${theme.mobile} {
    width: 250px;
    height: 50px;
    margin: 30px 0 0 60px;
  }
`;

const ContentImg = styled.img`
  width: 230px;
  height: 242px;
  margin-top: 100px;
`;

const KakaoChannelWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const KakaoChannelTxt = styled.span`
  font-family: nanumGothicB;
  font-size: 10px;
  color: #4ca1af;
  text-decoration: underline;
  margin-top: 12px;
  cursor: pointer;
`;

const KakaoChannelImg = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 8px;
  cursor: pointer;
`;

export default OrderConfirm;
