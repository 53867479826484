import Main from 'components/pages/Main';
import ReservationMain from 'components/pages/reservation/Main';
import OrderConfirm from 'components/pages/reservation/OrderConfirm';
import Payment from 'components/pages/reservation/Payment';
import WordManagement from 'components/pages/Word/WordManagement';
import Dashboard from 'components/pages/Dashboard/Dashboard';
import Product from 'components/pages/Product';
import Current from 'components/pages/Current/Current';
import CertificationResult from 'components/pages/reservation/CertificationResult';

export const routes = [
  { path: '/admin/login', element: <Main /> },
  { path: '/', element: <ReservationMain /> },
  { path: '/reservation', element: <ReservationMain /> },
  { path: '/reservation/orderConfirm', element: <OrderConfirm /> },
  { path: '/reservation/payment', element: <Payment /> },
  { path: '/certificationResult', element: <CertificationResult /> },
];

export const adminAuthRoutes = [
  { path: '/admin/wordManagement', element: <WordManagement /> },
  { path: '/admin/dashboard', element: <Dashboard /> },
  { path: '/admin/current', element: <Current /> },
  { path: '/admin/product', element: <Product /> },
];
