import axios from 'axios';
import authStore from 'stores/AuthStore';
import { accessApi } from './API';

export function jwtInterceptor() {
  accessApi.interceptors.request.use((request) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const account = authStore;
    const isLoggedIn = account.isAuthenticated();

    if (isLoggedIn) {
      request.headers.Authorization = axios.defaults.headers.common['Authorization'];
    }
    return request;
  });
}
