import paymentStore from './PaymentStore';
import dashboardStore from './DashboardStore';
import authStore from './AuthStore';

const stores = {
  paymentStore,
  dashboardStore,
  authStore,
};

export default stores;
