import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import DefaultPageLayout from 'components/templates/PageLayout';
import stores from 'stores';
import { observer } from 'mobx-react';
import Pagination from 'components/atoms/Pagination';

import { LuArrowDownSquare } from 'react-icons/lu';
import { API_URL, cancelPaymentApi, getModels } from 'api/API';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import theme from 'styles/theme';
import RefundPopUp from './RefundPopUp';

const Product = observer(() => {
  const location = useLocation();
  const { paymentStore } = stores;
  const {
    getPayments,
    getTodayPayments,
    updateProduct,
    cancelRefundRequest,
    payments,
    totalPayments,
    todayPaymentCount,
  } = paymentStore;

  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const PageSize = 15;

  const [productAmounts, setProductAmounts] = useState({});

  const onChange = (e) => {
    const { name, value } = e.target;
    setProductAmounts({
      ...productAmounts,
      [name]: value?.replace(/[^\d.-]+/g, ''),
    });
  };

  useEffect(() => {
    getModels()
      .then((res) => {
        const modelData = res.data;
        setProducts(modelData);
        let productAmountMap = {};
        for (let i = 0; i < modelData?.length; i++) {
          productAmountMap[modelData[i]?.id] = 0;
        }
        console.log(productAmountMap);
        setProductAmounts(productAmountMap);
      })
      .catch((err) => {
        console.error(err);
        alert('제품 데이터 로딩에 실패했습니다.');
      });

    getTodayPayments();
  }, [location]);

  useEffect(() => {
    getPayments(currentPage - 1, PageSize);
  }, [getPayments, currentPage]);

  const handleSaveProduct = () => {
    let body = [];
    for (let i = 0; i < Object.keys(productAmounts).length; i++) {
      const productId = Object.keys(productAmounts)[i];
      body.push({ id: productId, amountDiff: parseInt(productAmounts[productId], 10) });
    }
    console.log('body', body);
    updateProduct(body);
  };

  const handleExportExcel = () => {
    window.location.href = `${API_URL}/admin/payments/export/by-excel?token=${axios.defaults.headers.common[
      'Authorization'
    ].replace('Bearer ', '')}`;
  };

  const [refundPopupShow, setRefundPopupShow] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();
  const showCancelPopup = (payment) => {
    setSelectedPayment(payment);
    setRefundPopupShow(true);
  };

  const handleDenyRefund = () => {
    if (!window.confirm('반품 요청을 반려하시겠습니까?')) {
      return;
    }
    cancelRefundRequest({ moid: selectedPayment?.moid });
    setRefundPopupShow(false);
  };

  const handleRefund = () => {
    if ('REQUEST_CANCEL' !== selectedPayment?.status) {
      alert('결제 취소 가능한 상태가 아닙니다.');
      return;
    }
    if (!window.confirm('PG사에 결제취소를 요청하시겠습니까?')) {
      return;
    }
    cancelPaymentApi({ moid: selectedPayment?.moid })
      .then((res) => {
        setRefundPopupShow(false);
        getPayments(currentPage - 1, PageSize);
        alert(res.data);
      })
      .catch((err) => {
        console.error(err);
        alert('결제 취소에 실패했습니다. 문제가 계속될 경우 고객센터로 문의해 주세요.');
      });
  };

  const today = useMemo(() => {
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1; // 월
    let date = today.getDate(); // 날짜

    return `${year}.${month}.${date}`;
  }, []);

  return (
    <DefaultPageLayout>
      <Wrap>
        <TopSection>
          <LeftWrap>
            <TitleWrap>
              <Title>수토폰 현황</Title>
            </TitleWrap>
          </LeftWrap>
        </TopSection>
        <TableWrap>
          <TableHeader>
            <ThShelf width={160}>모델</ThShelf>
            <ThShelf width={220}>모델명</ThShelf>
            <ThShelf width={120}>색상</ThShelf>
            <ThShelf width={90}>총 판매 현황</ThShelf>
            <ThShelf width={90}>현 재고 수량</ThShelf>
            <ThShelf width={110}>입고 수량(누적)</ThShelf>
            <ThShelf width={100}>추가 입고 수량</ThShelf>
          </TableHeader>
          <TableBody>
            {products.map((item) => (
              <Table>
                <TbShelf width={160}>{item.productName}</TbShelf>
                <TbShelf width={220}>{item.modelDetail}</TbShelf>
                <TbShelf width={120}>{item.colorName}</TbShelf>
                <TbShelf bold={true} width={90}>
                  {item?.totalSellCount?.toLocaleString()}
                </TbShelf>
                <TbShelf width={90} zero={item?.remainCount === 0}>
                  {item?.remainCount?.toLocaleString()}
                </TbShelf>
                <TbShelf width={110}>{item?.amount?.toLocaleString()}</TbShelf>
                <TbShelf width={100}>
                  <Input value={productAmounts[item?.id]} name={item?.id} onChange={onChange} />
                </TbShelf>
              </Table>
            ))}
          </TableBody>
        </TableWrap>
        <ButtonWrap2>
          <Button onClick={handleSaveProduct}>
            <ButtonText>저장</ButtonText>
          </Button>
        </ButtonWrap2>
      </Wrap>
      <Wrap>
        <TopSection>
          <LeftWrap>
            <TitleWrap>
              <Title>주문내역</Title>
              <TodayPaymentCountText>
                {today} 판매현황 : {todayPaymentCount}대
              </TodayPaymentCountText>
            </TitleWrap>
          </LeftWrap>
        </TopSection>
        <TableWrap>
          <TableHeader>
            <ThShelf type={2} width={50}>
              No.
            </ThShelf>
            <ThShelf type={2} width={120}>
              모델
            </ThShelf>
            <ThShelf type={2} width={180}>
              모델명
            </ThShelf>
            <ThShelf type={2} width={80}>
              색상
            </ThShelf>
            <ThShelf type={2} width={40}>
              수량
            </ThShelf>
            <ThShelf type={2} width={70}>
              고객명
            </ThShelf>
            <ThShelf type={2} width={100}>
              연락처
            </ThShelf>
            <ThShelf type={2} width={610}>
              배송주소
            </ThShelf>
            <ThShelf type={2} width={100}>
              구매일자
            </ThShelf>
            <ThShelf type={2} width={120}>
              구매취소
            </ThShelf>
          </TableHeader>
          <TableBody>
            {payments.map((item) => (
              <Table>
                <TbShelf type={2} width={50}>
                  {item.id}
                </TbShelf>
                <TbShelf type={2} width={120}>
                  {item.product.productName}
                </TbShelf>
                <TbShelf type={2} width={180}>
                  {item.product.modelDetail}
                </TbShelf>
                <TbShelf type={2} width={80}>
                  {item.product.colorName}
                </TbShelf>
                <TbShelf type={2} width={40}>
                  1
                </TbShelf>
                <TbShelf type={2} width={70}>
                  {item.receiverName}
                </TbShelf>
                <TbShelf type={2} width={100}>
                  {item.receiverCellphone}
                </TbShelf>
                <TbShelf
                  type={2}
                  width={610}
                >{`(${item.addressCode}) ${item.address} ${item.addressDetail}`}</TbShelf>
                <TbShelf type={2} width={100}>
                  {item.createdDate}
                </TbShelf>
                <TbShelf type={2} width={120}>
                  {item.refundDisabled && '취소 불가'}
                  {item.status === 'REQUEST_CANCEL' && !item.refundDisabled && (
                    <CancelButton onClick={() => showCancelPopup(item)}>반품 요청</CancelButton>
                  )}
                  {item.status === 'CANCELLED' && !item.refundDisabled && (
                    <CancelledButton disabled>반품 완료</CancelledButton>
                  )}
                </TbShelf>
              </Table>
            ))}
          </TableBody>
        </TableWrap>
        <PaginationWrap>
          <Pagination
            currentPage={currentPage}
            totalCount={totalPayments}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          ></Pagination>
        </PaginationWrap>
        <ButtonWrap>
          <Button onClick={handleExportExcel}>
            <ButtonText>엑셀 내보내기</ButtonText>
            <ButtonIcon>
              <LuArrowDownSquare />
            </ButtonIcon>
          </Button>
        </ButtonWrap>
      </Wrap>
      <RefundPopUp
        visible={refundPopupShow}
        payment={selectedPayment}
        handleClose={() => setRefundPopupShow(false)}
        handleConfirm={handleRefund}
        handleDeny={handleDenyRefund}
      />
    </DefaultPageLayout>
  );
});

const Wrap = styled.div`
  background-color: #f7f7f7;
  padding: 50px 0 20px 0;
`;

const TopSection = styled.div`
  width: 1660px;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftWrap = styled.div`
  display: flex;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

const IconImg = styled.img`
  width: 34px;
  height: 34px;
`;

const Title = styled.p`
  color: #000000;
  font-size: 20px;
  font-family: nanumGothicB;
`;

const TableWrap = styled.div`
  width: 1660px;
  margin: 0 auto;
  border-bottom: 1px solid #b3b3b3;
`;

const TableHeader = styled.div`
  background-color: #e6e6e6;
  display: flex;
  justify-content: space-between;
`;

const TableBody = styled.div`
  overflow-y: scroll;
  background: white;
`;

const Table = styled.div`
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: #d4e8fb;
  }
`;

const ThShelf = styled.div`
  width: ${(props) => props.width}px;
  color: #292d32;
  font-size: 16px;
  font-family: nanumGothicB;
  text-align: center;
  padding: 18px 0;

  ${(props) =>
    props.type === 2 &&
    css`
      font-size: 16px;
    `}
`;

const TbShelf = styled(ThShelf)`
  color: #666666;
  font-size: 20px;
  font-family: nanumGothicR;
  padding: 20px 0;

  ${(props) =>
    props.type === 2 &&
    css`
      font-size: 16px;
    `}
  ${(props) =>
    props.zero &&
    css`
      color: red;
    `}

  ${(props) =>
    props.bold &&
    css`
      font-family: nanumGothicB;
    `}
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 1660px;
  margin: 0px auto 0;
  justify-content: flex-end;
`;

const ButtonWrap2 = styled(ButtonWrap)`
  margin-top: 20px;
`;

const Button = styled.button`
  width: 180px;
  height: 36px;
  border-radius: 25px;
  background-color: #4ca1af;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin: 0 0 0 20px;
  }
`;

const ButtonText = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-family: nanumGothicR;
`;

const ButtonIcon = styled.p`
  color: #ffffff;
  font-size: 18px;
  margin: 4px 0 0 5px;
`;

const PaginationWrap = styled.div`
  margin: 12px 0 0 0;
  height: 34px;
  position: relative;
  padding: 6px 0;
`;

const Input = styled.input`
  width: 90px;
  line-height: 30px;
  border: none;
  border: 1px solid #b3b3b3;
  font-size: 20px;
  font-family: nanumGothicB;
  text-align: center;
  -webkit-appearance: none;

  &::placeholder {
    color: #b3b3b3;
  }

  @media ${theme.mobile} {
    width: 210px;
    font-size: 15px;
  }
`;

const CancelButton = styled.button`
  width: 100px;
  height: 23px;
  border-radius: 30px;
  background-color: #e81a0c;
  color: white;
  font-size: 13px;
`;

const CancelledButton = styled(CancelButton)`
  background: #039c55;
`;

const TodayPaymentCountText = styled.span`
  width: 240px;
  height: 33px;
  border-radius: 50px;
  background-color: #e54887;
  font-size: 16px;
  color: white;
  font-family: nanumGothicB;
  text-align: center;
  line-height: 33px;
  margin-left: 42px;
`;

export default Product;
