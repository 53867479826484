import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: `${API_URL}/api`,
});

export const accessApi = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    Authorization: '',
  },
});

export const getModels = (model) =>
  api.get(`/products?model=${model ? encodeURIComponent(model) : ''}`);

export const getDistinctModels = () => api.get(`/admin/products/by-model`);

export const getPaymentApi = (buyerName, buyerEmail, buyerCellphone, moid) => {
  if (moid) {
    return api.get(`/payment/order-info?moid=${encodeURIComponent(moid)}`);
  }
  return api.get(
    `/payment/order-info?buyerName=${encodeURIComponent(buyerName)}&buyerEmail=${encodeURIComponent(
      buyerEmail,
    )}&buyerCellphone=${encodeURIComponent(buyerCellphone)}`,
  );
};

export const createNiceCertificationSencdata = (selectedModel, frontWebOrigin) =>
  api.post(`/certifications/nice/sencdata`, {
    selectedModel: selectedModel,
    frontWebOrigin: frontWebOrigin,
  });

export const createNiceCertificationSencdataSuccess = (body) =>
  api.post(`/certifications/nice/sencdata/success`, body);

export const createNiceCertificationSencdataFail = (body) =>
  api.post(`/certifications/nice/sencdata/fail`, body);

export const createNicepayId = (body) => api.post('/payment/nicepay/id', body);

export const requestCancelPaymentApi = (body) => api.post('/payment/cancel/request', body);

export const countTodayPaymentCount = () => accessApi.get('/admin/payments/today/count');

export const signInApi = (body) => api.post(`/users/sign-in`, body);

export const refreshTokenApi = (token) =>
  api.post(
    `/users/refresh-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const getUsersApi = (keyword, pageNumber, pageSize) =>
  accessApi.get(
    `/admin/users?keyword=${keyword ? keyword : ''}&pageNumber=${
      pageNumber ? pageNumber : ''
    }&pageSize=${pageSize ? pageSize : ''}`,
  );

export const putUserApi = (body) => accessApi.put('/admin/users', body);

export const resetPasswordApi = (body) => accessApi.post('/admin/users/reset-password', body);

export const resetSettingPasswordApi = (body) =>
  accessApi.post('/admin/users/reset-setting-password', body);

export const getNewWordsApi = (levelType, keyword, pageNumber, pageSize) =>
  accessApi.get(
    `/admin/new-words?levelType=${levelType}&keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

export const putWordApi = (body) => accessApi.put('/admin/new-word', body);

export const getPaymentsApi = (pageNumber, pageSize) =>
  accessApi.get(`/admin/payments?pageNumber=${pageNumber}&pageSize=${pageSize}`);

export const putProductssApi = (body) => accessApi.put('/products', body);

export const cancelPaymentApi = (body) => accessApi.post('/payment/cancel', body);

export const cancelRefundPaymentApi = (body) =>
  accessApi.post('/payment/cancel/refund/request', body);

export const getAdminStatusApi = () => accessApi.get(`/admin/stats`);

export const getAdminDeviceCountApi = () => accessApi.get(`/admin/devices/count`);
