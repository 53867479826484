import { createNiceCertificationSencdataFail } from 'api/API';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from 'styles/theme';

const CertificationResult = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const result = JSON.parse(params.get('result'));
    const encodeData = params.get('EncodeData');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (result) {
      if (isMobile) {
        const selectedModel = params.get('selectedModel');
        navigate(
          `/reservation/payment?encodeData=${encodeURIComponent(
            encodeData,
          )}&selectedModel=${selectedModel}`,
        );
      } else {
        window.opener.goToNextPage(encodeData);
        window.close();
      }
    } else {
      createNiceCertificationSencdataFail({ EncodeData: encodeData })
        .then((res) => {
          alert('본인인증에 실패했습니다. 사유: ' + res.data);
        })
        .catch((err) => {
          console.error(err);
          alert('본인인증에 실패했습니다.');
          window.close();
        });
    }
  }, [location]);
};

export default CertificationResult;
