import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import PopUpDefault from 'components/atoms/PopUp';

import folderIcon from 'resources/folder.png';
import excelIcon from 'resources/ic_excel.png';
import { LuArrowUpSquare } from 'react-icons/lu';

const UploadExelPopUp = ({ visible, handleClose }) => {
  const [tab, setTab] = useState('advanced');

  const handleTab = (tab) => {
    setTab(tab);
  };

  const fileRef = useRef(null);

  const [file, setFile] = useState();

  const handleUploadFile = (e) => {
    fileRef.current.click();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <PopUpDefault width="800" height="310" title="엑셀 올리기" visible={visible}>
      <Content>
        <FlexWrap>
          <Box>
            <Label>
              <LabelIcon src={folderIcon} alt="folder-icon" />
              <LabelText>업로드 위치 선택</LabelText>
            </Label>
            <TabWrap>
              <Tab onClick={() => handleTab('basic')} active={tab === 'basic'}>
                초급 영어
              </Tab>
              <Tab onClick={() => handleTab('middle')} active={tab === 'middle'}>
                중급 영어
              </Tab>
              <Tab onClick={() => handleTab('advanced')} active={tab === 'advanced'}>
                고급 영어
              </Tab>
            </TabWrap>
          </Box>
          <Border></Border>
          <Box>
            <LabelWrap>
              <Label>
                <LabelIcon src={excelIcon} alt="excel-icon" />
                <LabelText>엑셀 파일 불러오기</LabelText>
              </Label>
              <LabelButton onClick={handleUploadFile}>파일 선택</LabelButton>
              <Input type="file" ref={fileRef} onChange={handleFileChange} />
            </LabelWrap>
            <FileName>{file && file.name}</FileName>
          </Box>
        </FlexWrap>
        <ButtonWrap>
          <ButtonGray>
            <ButtonText onClick={handleClose}>취 소</ButtonText>
          </ButtonGray>
          <ButtonBlue>
            <ButtonText>엑셀 올리기</ButtonText>
            <ButtonIcon>
              <LuArrowUpSquare />
            </ButtonIcon>
          </ButtonBlue>
        </ButtonWrap>
      </Content>
    </PopUpDefault>
  );
};

const Content = styled.div`
  padding: 50px 35px 0;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Box = styled.div``;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const LabelIcon = styled.img`
  width: 17px;
  height: 17px;
`;

const LabelText = styled.p`
  color: #3c8895;
  font-family: nanumGothicB;
  font-size: 16px;
  margin: 0 0 0 5px;
`;

const TabWrap = styled.div`
  display: flex;
  margin: 10px 0 0 0;
`;

const Tab = styled.div`
  cursor: pointer;
  width: 105px;
  height: 38px;
  background-color: #b3b3b3;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: nanumGothicR;
  text-align: center;
  padding: 9px 0;

  & + & {
    margin: 0 0 0 10px;
  }

  ${(props) =>
    props.active === true &&
    css`
      background-color: #000000;
      font-family: nanumGothicEB;
    `}
`;

const Border = styled.div`
  width: 1px;
  height: 100px;
  background-color: #e6e6e6;
`;

const LabelWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelButton = styled.button`
  width: 81px;
  height: 20px;
  background-color: #4ca1af;
  border-radius: 25px;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 10px;
`;

const FileName = styled.div`
  width: 334px;
  height: 36px;
  color: #666666;
  font-family: nanumGothicB;
  font-size: 16px;
  margin: 7px 0 0 0;
  border: 1px solid #e6e6e6;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px inset;
  border-radius: 5px;
  text-align: center;
  padding: 8px 0;
`;

const Input = styled.input`
  display: none;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
`;

const ButtonGray = styled.button`
  width: 235px;
  height: 38px;
  border-radius: 25px;
  background-color: #b3b3b3;
  color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;

  & + & {
    margin: 0 0 0 26px;
  }
`;

const ButtonBlue = styled(ButtonGray)`
  background-color: #4ca1af;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.p``;

const ButtonIcon = styled.p`
  margin: 5px 0 0 10px;
`;

export default UploadExelPopUp;
