import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { routes, adminAuthRoutes } from './route/routes';
import { observer } from 'mobx-react';
import authStore from 'stores/AuthStore';
import { jwtInterceptor } from 'api/interceptor';

const App = observer(() => {
  const { refreshToken, getCookie } = authStore;

  const refreshTokenCookie = getCookie('refreshToken');

  useEffect(() => {
    async function refreshTokenAndSetupInterval() {
      try {
        if (refreshTokenCookie) {
          await refreshToken(refreshTokenCookie);
          jwtInterceptor();

          return () => {};
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
      } finally {
      }
    }

    refreshTokenAndSetupInterval();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenCookie]);

  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route path={route.path} element={route.element} key={`route_${route.path}`} />
        ))}
        {adminAuthRoutes.map((route, i) => (
          <Route
            exact
            path={route.path}
            element={
              localStorage.getItem('accessTokenExpireTime') !== null &&
              parseInt(localStorage.getItem('accessTokenExpireTime')) >= new Date().getTime() ? (
                route.element
              ) : (
                <Navigate
                  to={{
                    pathname: '/admin/login',
                  }}
                />
              )
            }
          />
        ))}
      </Routes>
    </Router>
  );
});

export default App;
