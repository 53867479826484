import { createGlobalStyle } from 'styled-components';
import theme from 'styles/theme';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    cursor: default;
    box-sizing: border-box;
  }    
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
  }

  input:focus {
        outline: none
    }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  button {
    border: 0;
    cursor: pointer;
  }
  div {
  box-sizing: border-box;
  }
  li {
  box-sizing: border-box;
  list-style: none;
  }
  p {
    margin: 0;
  box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }

`;

export default GlobalStyle;
