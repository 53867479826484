import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { BsFillCaretDownFill } from 'react-icons/bs';
import theme from 'styles/theme';
import { Mobile, Others } from 'styles/MediaQuery';

import PageLayout from 'components/templates/ReservationPageLayout';
import SelectOptions from 'components/atoms/SelectOptions';
import Checkbox from 'components/atoms/Checkbox';

import phoneModel1 from 'resources/model_fold_5.png';
import phoneModel2 from 'resources/model_flip_5.png';
import phoneModel5 from 'resources/s_24_ultra.png';
import phoneModelS242 from 'resources/s_24.png';
import phoneModelS23FE from 'resources/model_s_23_fe.png';
import cardImg from 'resources/logo_lotte.png';
import { v4 as uuidv4 } from 'uuid';
import crypto from 'crypto-js';
import TermsPopUp from 'components/atoms/TermsPopUp';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  API_URL,
  requestCancelPaymentApi,
  createNiceCertificationSencdataSuccess,
  createNicepayId,
  getModels,
  getPaymentApi,
} from 'api/API';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const moid = params.get('moid');

  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [paymentData, setPaymentData] = useState();

  useEffect(() => {
    if (!document.getElementById('nicepayscript')) {
      const fileScript = document.createElement('script');
      fileScript.id = 'nicepayscript';
      fileScript.src = 'https://pg-web.nicepay.co.kr/v3/common/js/nicepay-pgweb.js';
      document.body.appendChild(fileScript);

      fileScript.onload = () => {
        console.log('nice pay js loaded');
      };

      fileScript.onerror = () => {
        console.log('load error!');
      };

      // if (isMobile) {
      // 모바일 결제창 진입
      //   formRef.current.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
      //   formRef.current.acceptCharset = "euc-kr";
      //   formRef.current.submit();
      // } else {
      // PC 결제창 진입
      if (typeof window !== 'undefined') {
        window.nicepaySubmit = nicepaySubmit;
        window.nicepayClose = nicepayClose;
      }
      // }
    }
  }, []);

  const [inputs, setInputs] = useState({
    certificationId: '',
    name: '',
    email: '',
    phoneNumber: '',
    recipient: '',
    recipientNumber: '',
    address: '',
    zipcode: '',
    detailAddress: '',
  });

  const { name, email, phoneNumber, recipient, recipientNumber, detailAddress, address, zipcode } =
    inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onChangeDeliveryEtc = (e) => {
    const { name, value } = e.target;
    setDeliverMessageEtc(value);
  };

  useEffect(() => {
    if (!moid) {
      return;
    }
    getPaymentApi(null, null, null, moid)
      .then((res) => {
        const paymentData = res.data;
        if (!paymentData) {
          alert('주문 데이터 로딩에 실패했습니다. ');
          window.history.back();
          return;
        }
        if (paymentData.product.model.charCodeAt(0) === 65279) {
          paymentData.product.model = paymentData.product.model.substr(
            1,
            paymentData.product.model.length,
          );
        }
        if (paymentData.product.colorHex.charCodeAt(0) === 65279) {
          paymentData.product.colorHex = paymentData.product.colorHex.substr(
            1,
            paymentData.product.colorHex.length,
          );
        }
        setProduct(paymentData.product);
        setInputs({
          name: paymentData.buyerName,
          email: paymentData.buyerEmail,
          phoneNumber: paymentData.buyerCellphone,
          recipient: paymentData.receiverName,
          recipientNumber: paymentData.receiverCellphone,
          address: paymentData.address,
          zipcode: paymentData.addressCode,
          detailAddress: paymentData.addressDetail,
        });
        setDeliverMessage(paymentData.shipmentMessage);

        setPaymentData(paymentData);
      })
      .catch((err) => {
        console.error(err);
        alert('주문 데이터 로딩에 실패했습니다.');
        window.history.back();
      });
  }, [moid]);

  const [deliverMessage, setDeliverMessage] = useState('');
  const [deliverMessageEtc, setDeliverMessageEtc] = useState('');

  const deliverMessageArray = [
    { text: '문 앞에 놓아주세요.', value: '1' },
    { text: '부재 시 연락 부탁드려요.', value: '2' },
    { text: '배송 전 미리 연락해 주세요. ', value: '3' },
    { text: '경비실에 부탁드려요.', value: '4' },
    { text: '직접입력', value: '5' },
  ];

  const [activePaymentMothod, setActivePaymentMethod] = useState('card');

  const handleClickActivePaymentMethod = (value) => {
    setActivePaymentMethod(value);
  };

  const [installmentPeriod, setInstallmentPeriod] = useState('');

  const installmentPeriodArray = [
    { text: '일시불', value: '1' },
    { text: '2개월', value: '2' },
    { text: '3개월', value: '3' },
    { text: '4개월', value: '4' },
    { text: '5개월', value: '5' },
    { text: '6개월', value: '6' },
    { text: '7개월', value: '7' },
    { text: '8개월', value: '8' },
    { text: '9개월', value: '9' },
    { text: '10개월', value: '10' },
    { text: '11개월', value: '11' },
    { text: '12개월', value: '12' },
  ];

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    console.log('moid', params.get('moid'));
    if (!!params.get('moid')) {
      return;
    }
    const encodeData = params.get('encodeData');
    const selectedModel = params.get('selectedModel');
    getModels(selectedModel)
      .then((res) => {
        const modelData = res.data.filter((i) => i.remainCount > 0);
        setProducts(modelData);
        if (modelData && modelData.length > 0) {
          for (let i = 0; i < modelData.length; i++) {
            if (modelData[i].productName.charCodeAt(0) === 65279) {
              modelData[i].productName = modelData[i].productName.substr(
                1,
                modelData[i].productName.length,
              );
            }
            if (modelData[i].model.charCodeAt(0) === 65279) {
              modelData[i].model = modelData[i].model.substr(1, modelData[i].model.length);
            }
            if (modelData[i].modelDetail.charCodeAt(0) === 65279) {
              modelData[i].modelDetail = modelData[i].modelDetail.substr(
                1,
                modelData[i].modelDetail.length,
              );
            }
            if (modelData[i].colorHex.charCodeAt(0) === 65279) {
              modelData[i].colorHex = modelData[i].colorHex.substr(1, modelData[i].colorHex.length);
            }
            if (modelData[i].colorName.charCodeAt(0) === 65279) {
              modelData[i].colorName = modelData[i].colorName.substr(
                1,
                modelData[i].colorName.length,
              );
            }
            modelData[i].colorHex = modelData[i].colorHex.replace(/[^\x00-\x7F]/g, '');
          }
          setProduct(modelData[0]);
        } else {
          alert('구매 가능한 색상이 없습니다.');
          window.history.back();
        }
      })
      .catch((err) => {
        console.error(err);
        alert('제품 데이터 로딩에 실패했습니다.');
      });
    createNiceCertificationSencdataSuccess({ EncodeData: encodeData })
      .then((res) => {
        const certificationData = res.data;
        if (!certificationData || certificationData.result === 'FAILED') {
          alert('본인인증 데이터 로딩에 실패했습니다. ' + certificationData?.message);
          return;
        }
        setInputs({
          ...inputs,
          certificationId: certificationData.id,
          name: certificationData.name,
          phoneNumber: certificationData.mobileNo,
        });
      })
      .catch((err) => {
        console.error(err);
        alert('본인인증 데이터 로딩에 실패했습니다.');
        window.history.back();
      });
  }, [location]);

  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );

  const handleClickDaumPostCode = () => {
    open({ onComplete: handleComplete });
  };

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setInputs({ ...inputs, address: fullAddress, zipcode: data.zonecode });
  };

  const [allAgree, setAllAgree] = useState(false);

  const handleAllAgree = () => {
    setAllAgree(!allAgree);

    if (termsOfUseAllAgree === true && privacyAllAgree === true && withdrawOrder === true) {
      setAllAgree(false);
      setTermsOfUseAllAgree(false);
      setPrivacyAllAgree(false);
      setWithdrawOrder(false);
      setTermsOfUseAgree1(false);
      setTermsOfUseAgree2(false);
      setPrivacyAgree1(false);
      setPrivacyAgree2(false);
    } else {
      setAllAgree(true);
      setTermsOfUseAllAgree(true);
      setPrivacyAllAgree(true);
      setWithdrawOrder(true);
    }
  };

  const [termsOfUseAllAgree, setTermsOfUseAllAgree] = useState(false);

  const handleTermsOfUseAllAgree = () => {
    setTermsOfUseAllAgree(!termsOfUseAllAgree);

    if (termsOfUseAgree1 === true && termsOfUseAgree2 === true) {
      setTermsOfUseAllAgree(false);
      setTermsOfUseAgree1(false);
      setTermsOfUseAgree2(false);
    } else {
      setTermsOfUseAllAgree(true);
      setTermsOfUseAgree1(true);
      setTermsOfUseAgree2(true);
    }
  };

  const [termsOfUseAgree1, setTermsOfUseAgree1] = useState(false);

  const handleTermsOfUseAgree1 = () => {
    setTermsOfUseAgree1(!termsOfUseAgree1);
  };

  const [termsOfUseAgree2, setTermsOfUseAgree2] = useState(false);

  const handleTermsOfUseAgree2 = () => {
    setTermsOfUseAgree2(!termsOfUseAgree2);
  };

  const [privacyAllAgree, setPrivacyAllAgree] = useState(false);

  const handlePrivacyAllAgree = () => {
    setPrivacyAllAgree(!privacyAllAgree);

    if (privacyAgree1 === true && privacyAgree2 === true) {
      setPrivacyAllAgree(false);
      setPrivacyAgree1(false);
      setPrivacyAgree2(false);
    } else {
      setPrivacyAllAgree(true);
      setPrivacyAgree1(true);
      setPrivacyAgree2(true);
    }
  };

  const [privacyAgree1, setPrivacyAgree1] = useState(false);

  const handlePrivacyAgree1 = () => {
    setPrivacyAgree1(!privacyAgree1);
  };

  const [privacyAgree2, setPrivacyAgree2] = useState(false);

  const handlePrivacyAgree2 = () => {
    setPrivacyAgree2(!privacyAgree2);
  };

  const [withdrawOrder, setWithdrawOrder] = useState(false);

  const handleWithdrawOrder = () => {
    setWithdrawOrder(!withdrawOrder);
  };

  useEffect(() => {
    if (termsOfUseAllAgree === true && privacyAllAgree === true && withdrawOrder === true) {
      setAllAgree(true);
    } else {
      setAllAgree(false);
    }
  }, [privacyAllAgree, termsOfUseAllAgree, withdrawOrder]);

  useEffect(() => {
    if (termsOfUseAgree1 === true && termsOfUseAgree2 === true) {
      setTermsOfUseAllAgree(true);
    } else {
      setTermsOfUseAllAgree(false);
    }
  }, [termsOfUseAgree1, termsOfUseAgree2]);

  useEffect(() => {
    if (privacyAgree1 === true && privacyAgree2 === true) {
      setPrivacyAllAgree(true);
    } else {
      setPrivacyAllAgree(false);
    }
  }, [privacyAgree1, privacyAgree2]);

  function pad(number, length) {
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  Date.prototype.YYYYMMDDHHMMSS = function () {
    var yyyy = this.getFullYear().toString();
    var MM = pad(this.getMonth() + 1, 2);
    var dd = pad(this.getDate(), 2);
    var hh = pad(this.getHours(), 2);
    var mm = pad(this.getMinutes(), 2);
    var ss = pad(this.getSeconds(), 2);

    return yyyy + MM + dd + hh + mm + ss;
  };

  function handleCancelPayment() {
    if (!moid) {
      return;
    }

    if (!window.confirm('결제 취소 신청하시겠습니까?')) {
      return;
    }
    requestCancelPaymentApi({ moid: moid })
      .then((res) => {
        alert('결제 취소 신청이 접수되었습니다.');
        navigate('/reservation/orderConfirm');
      })
      .catch((err) => {
        console.error(err);
        alert('결제 취소에 실패했습니다. 문제가 계속될 경우 고객센터로 문의해 주세요.');
      });
  }

  function handleSubmit() {
    if (!name) {
      alert('주문자 정보(이름)을 입력해 주세요.');
      return;
    }
    if (!phoneNumber) {
      alert('주문자 정보(휴대폰)을 입력해 주세요.');
      return;
    }
    if (!email) {
      alert('주문자 정보(이메일)을 입력해 주세요.');
      return;
    }
    if (!recipient) {
      alert('배송지 정보(수령인 이름)을 입력해 주세요.');
      return;
    }
    if (!recipientNumber) {
      alert('배송지 정보(연락처)을 입력해 주세요.');
      return;
    }
    if (!address) {
      alert('배송지 정보(주소)을 입력해 주세요.');
      return;
    }
    if (!detailAddress) {
      alert('배송지 정보(상세주소)을 입력해 주세요.');
      return;
    }
    if (!deliverMessage || (deliverMessage?.value === '5' && !deliverMessageEtc)) {
      alert('배송 메시지를 선택해 주세요.');
      return;
    }
    if (!termsOfUseAllAgree || !privacyAllAgree || !withdrawOrder) {
      alert('필수 약관에 동의해 주세요.');
      return;
    }
    let body = {
      buyerName: name,
      buyerEmail: email,
      buyerCellphone: phoneNumber,
      receiverName: recipient,
      receiverCellphone: recipientNumber,
      addressCode: zipcode,
      address: address,
      addressDetail: detailAddress,
      shipmentMessage: deliverMessage?.value !== '5' ? deliverMessage?.text : deliverMessageEtc,
      productId: product?.id,
      amount: product?.price,
      certificationId: inputs?.certificationId,
    };
    createNicepayId(body)
      .then((res) => {
        const moid = res.data;
        const mid = product?.mid;
        const key = product?.paymentKey;
        const goodsName = product?.productName;
        const amt = product?.price;
        const ediDate = new Date().YYYYMMDDHHMMSS();

        const plainText = ediDate + mid + amt + key;

        const hash = crypto.SHA256(plainText);
        const signedData = hash.toString(crypto.enc.Hex);

        const id = uuidv4();

        window.payForm.GoodsName.value = goodsName;
        window.payForm.ReqReserved.value = moid;
        window.payForm.Amt.value = amt;
        window.payForm.MID.value = mid;
        window.payForm.EdiDate.value = ediDate;
        window.payForm.Moid.value = id;
        window.payForm.SignData.value = signedData;
        if (installmentPeriod?.value && parseInt(installmentPeriod?.value, 10) > 1) {
          window.payForm.SelectQuota.value = installmentPeriod?.value;
          // let installmentPeriodStr = null;
          // if (parseInt(installmentPeriod?.value, 10) < 10) {
          //   installmentPeriodStr = `0${parseInt(installmentPeriod?.value, 10)}`;
          // } else {
          //   installmentPeriodStr = `${parseInt(installmentPeriod?.value, 10)}`;
          // }
          // console.log('QuotaInterest', `08:${installmentPeriodStr}`);
          // window.payForm.QuotaInterest.value = `08:${installmentPeriodStr}`;
        }
        if (product?.model === 'SM-S921N' || product?.model === 'SM-S711N') {
          window.payForm.ShopInterest.value = 1;
        } else {
          window.payForm.ShopInterest.value = 0;
        }
        window.goPay(window.payForm);
      })
      .catch((err) => {
        console.error(err);
        alert('결제 시도에 실패했습니다. 문제가 계속될 경우 고객센터로 문의해 주세요.');
      });
  }

  const nicepaySubmit = async () => {
    window.payForm.submit();
  };

  const nicepayClose = async () => {
    // TODO: 결제 실패시 처리
  };

  const [showTermsPopUp, setShowTermsPopUp] = useState(false);

  return (
    <PageLayout>
      <Wrap>
        <Container>
          <Title>주문 / 결제</Title>
          <FlexWrap>
            <LeftWrap>
              <ProductWrap>
                <Label>주문상품</Label>
                <ProductContainer>
                  {product?.model === 'SM-F731N' && (
                    <ProductFImg src={phoneModel2} alt="content-img" />
                  )}
                  {product?.model === 'SM-F946N' && (
                    <ProductImg src={phoneModel1} alt="content-img" />
                  )}
                  {product?.model === 'SM-S928N' && (
                    <Product5Img src={phoneModel5} alt="content-img" />
                  )}
                  {product?.model === 'SM-S921N' && (
                    <ProductSImg src={phoneModelS242} alt="content-img" />
                  )}
                  {product?.model === 'SM-S711N' && (
                    <ProductS23FEImg src={phoneModelS23FE} alt="content-img" />
                  )}
                  <ProductDetail>
                    <ProductTitleWrap>
                      <ProductName>
                        <ProductTitle>{product?.productName}</ProductTitle>
                        <ProductSubText>{product?.modelDetail}</ProductSubText>
                      </ProductName>
                      <ProductQuantity>1개</ProductQuantity>
                      <ProductPrice>{product?.price?.toLocaleString()}원</ProductPrice>
                    </ProductTitleWrap>
                    <ProductSubTextSpec>
                      <ProductSubStrong>저장용량</ProductSubStrong> / 256GB
                    </ProductSubTextSpec>
                    <ProductSubTextSpec>
                      <ProductSubStrong>색상선택</ProductSubStrong>
                    </ProductSubTextSpec>
                    <SpecFlexBox width={70 * products?.length}>
                      {!moid &&
                        products?.map((item) => (
                          <SpecColorItem>
                            {item.colorHex && (
                              <SpecOuterColor selected={item.id === product?.id}>
                                <SpecColor
                                  onClick={() => setProduct(item)}
                                  colorHex={item.colorHex}
                                />
                              </SpecOuterColor>
                            )}
                            <ProductSubTextSpec2>{item.colorName}</ProductSubTextSpec2>
                          </SpecColorItem>
                        ))}
                      {moid && (
                        <SpecColorItem>
                          {product?.colorHex && (
                            <SpecOuterColor selected={true}>
                              <SpecColor colorHex={product?.colorHex} />
                            </SpecOuterColor>
                          )}
                          <ProductSubTextSpec2>{product?.colorName}</ProductSubTextSpec2>
                        </SpecColorItem>
                      )}
                    </SpecFlexBox>
                  </ProductDetail>
                </ProductContainer>
              </ProductWrap>
              <DeliveryWrap>
                <Label>배송정보</Label>
                <InputInfoWrap>
                  <InputTitle>주문자 정보</InputTitle>
                  <InputWrap>
                    <InputBox>
                      <form
                        id="payForm"
                        action={`${API_URL}/callback/payment/nicepay`}
                        method="POST"
                        accept-charset="euc-kr"
                      >
                        <input type="hidden" name="GoodsName" />
                        <input type="hidden" name="Amt" />
                        <input type="hidden" name="MID" />
                        <input type="hidden" name="EdiDate" />
                        <input type="hidden" name="Moid" />
                        <input type="hidden" name="ShopInterest" value="0" />
                        <input type="hidden" name="SignData" />
                        <input type="hidden" name="CharSet" value="utf-8" />
                        <input type="hidden" name="ReqReserved" />
                        <input type="hidden" name="SelectQuota" />
                        <input type="hidden" name="PayMethod" value="CARD" />
                        <input
                          type="hidden"
                          name="ReturnURL"
                          value={`${API_URL}/callback/payment/nicepay`}
                        />
                      </form>
                      <InputLabel>이름</InputLabel>
                      <Input
                        name="name"
                        value={name}
                        placeholder="이름을 입력해주세요"
                        readOnly
                        onChange={onChange}
                      />
                    </InputBox>
                    <InputBox>
                      <InputLabel>휴대폰</InputLabel>
                      <Input
                        name="phoneNumber"
                        value={phoneNumber}
                        placeholder="숫자만 입력가능합니다.(‘-’제외)"
                        readOnly
                        onChange={onChange}
                      />
                    </InputBox>
                    <InputBox>
                      <InputLabel>이메일</InputLabel>
                      <Input
                        name="email"
                        value={email}
                        placeholder="이메일을 입력해 주세요."
                        onChange={onChange}
                        readOnly={!!moid}
                      />
                    </InputBox>
                  </InputWrap>
                </InputInfoWrap>
                <InputInfoWrap>
                  <InputTitle>배송지 정보</InputTitle>
                  <InputWrap>
                    <InputBox>
                      <InputLabel>수령인 이름</InputLabel>
                      <Input
                        name="recipient"
                        value={recipient}
                        placeholder="이름을 입력해주세요"
                        onChange={onChange}
                        readOnly={!!moid}
                      />
                    </InputBox>
                    <InputBox>
                      <InputLabel>연락처</InputLabel>
                      <Input
                        name="recipientNumber"
                        value={recipientNumber}
                        placeholder="숫자만 입력가능합니다.(‘-’제외)"
                        onChange={onChange}
                        readOnly={!!moid}
                      />
                    </InputBox>
                    <InputBox>
                      <InputLabel>주소</InputLabel>
                      <AddressWrap>
                        <PostCodeWrap>
                          <Others>
                            <PostCode active={zipcode !== ''}>
                              {zipcode === '' ? '우편번호를 선택해주세요.' : zipcode}
                            </PostCode>
                          </Others>
                          <Mobile>
                            <PostCode active={zipcode !== ''}>
                              {zipcode === '' ? '우편번호' : zipcode}
                            </PostCode>
                          </Mobile>
                          {!moid && (
                            <AddressButton type="button" onClick={handleClickDaumPostCode}>
                              주소 찾기
                            </AddressButton>
                          )}
                        </PostCodeWrap>
                        <BasicAddress active={address !== ''}>
                          {address === '' ? '기본주소 자동입력' : address}
                        </BasicAddress>
                        <Input
                          name="detailAddress"
                          value={detailAddress}
                          placeholder="상세주소를 입력하세요."
                          onChange={onChange}
                          readOnly={!!moid}
                        />
                        <SelectOptionWrap>
                          {!moid && (
                            <SelectOptions
                              value={deliverMessage}
                              onSelect={(val) => setDeliverMessage(val)}
                              options={deliverMessageArray}
                              active={deliverMessage === '' ? false : true}
                              defaultMessage="배송 메시지를 선택해 주세요."
                            />
                          )}
                          {!!moid && <Input value={deliverMessage} />}
                        </SelectOptionWrap>
                        {deliverMessage?.value === '5' && (
                          <div style={{ marginTop: 24 }}>
                            <Input
                              name="deliverMessageEtc"
                              value={deliverMessageEtc}
                              placeholder="배송 메시지를 입력해 주세요."
                              onChange={onChangeDeliveryEtc}
                            />
                          </div>
                        )}
                      </AddressWrap>
                    </InputBox>
                  </InputWrap>
                </InputInfoWrap>
              </DeliveryWrap>
              {!moid && (
                <PaymentMethodWrap>
                  <Label>결제 수단</Label>
                  <PaymentMethodContainer>
                    <PaymentMethodTabWrap>
                      <PaymentMethodTab
                        onClick={() => handleClickActivePaymentMethod('card')}
                        active={activePaymentMothod === 'card'}
                      >
                        신용카드
                      </PaymentMethodTab>
                    </PaymentMethodTabWrap>
                    {activePaymentMothod === 'card' ? (
                      <CardRow>
                        <CardWrap>
                          <CardBox>
                            <CardImg src={cardImg} />
                            <CardTxt>롯데카드</CardTxt>
                          </CardBox>
                        </CardWrap>
                        <InstallmentPeriodWrap>
                          <SelectOptions
                            value={installmentPeriod}
                            onSelect={(val) => setInstallmentPeriod(val)}
                            options={installmentPeriodArray}
                            active={deliverMessage === '' ? false : true}
                            defaultMessage="할부 기간을 선택해주세요."
                          />
                        </InstallmentPeriodWrap>
                      </CardRow>
                    ) : (
                      <></>
                    )}
                    <CardDescription>
                      <CardDescriptionItem>
                        롯데카드 최대 12개월 무이자 할부 지원{' '}
                      </CardDescriptionItem>
                      <CardDescriptionItem>
                        신규 또는 장기 무실적회원의 경우 10만원 캐시백 프로모션 참여 가능
                      </CardDescriptionItem>
                    </CardDescription>
                  </PaymentMethodContainer>
                </PaymentMethodWrap>
              )}
              <PaymentMethodWrap>
                <Label>보상판매</Label>
                <PaymentMethodContainer>
                  <RewardTxt>
                    수토폰을 구매하신 고객께서는 구매 시점으로부터 24개월 경과 후 보상 판매 신청을
                    통해 수토폰 기기 회수 신청을 할 수 있습니다. 휴대폰 모델 및 판정 등급별 보상가는
                    상이할 수 있으며 대상 고객에게 신청 문자를 개별 발송합니다.
                  </RewardTxt>
                </PaymentMethodContainer>
              </PaymentMethodWrap>
            </LeftWrap>
            <RightWrap>
              <PaymentInfoWrap>
                <Label>결제 정보</Label>
                <PaymentInfoContainer>
                  <PaymentInfoBox>
                    <PaymentInfoLabel>주문 금액</PaymentInfoLabel>
                    <PaymentInfo>{product?.originalPrice?.toLocaleString()}원</PaymentInfo>
                  </PaymentInfoBox>
                  <PaymentInfoBox>
                    <PaymentInfoLabel>할인</PaymentInfoLabel>
                    <PaymentInfo>
                      -{(product?.originalPrice - product?.price)?.toLocaleString()}원
                    </PaymentInfo>
                  </PaymentInfoBox>
                </PaymentInfoContainer>
              </PaymentInfoWrap>
              <PaymentAmountWrap>
                <PaymentAmountLabel>결제 금액</PaymentAmountLabel>
                <PaymentAmount>{product?.price?.toLocaleString()}원</PaymentAmount>
              </PaymentAmountWrap>
              {!moid && (
                <AgreeTermsWrap>
                  <AgreeTermsLabelWrap>
                    <AgreeTermsLabel>
                      <span>*</span>약관 동의
                    </AgreeTermsLabel>
                    <AgreeTermsShowMore>
                      <AgreeTermsShowMoreText>상세보기</AgreeTermsShowMoreText>
                      <BsFillCaretDownFill />
                    </AgreeTermsShowMore>
                  </AgreeTermsLabelWrap>
                  <CheckboxAllAgreeWrap>
                    <Checkbox
                      key="allAgree"
                      defaultValue={false}
                      type="checkbox"
                      name="allAgree"
                      handleClick={handleAllAgree}
                      isChecked={allAgree}
                    >
                      <CheckboxTextBold>아래 내용에 모두 동의합니다.</CheckboxTextBold>
                    </Checkbox>
                    <ShowTerms onClick={() => setShowTermsPopUp(true)}>약관보기</ShowTerms>
                  </CheckboxAllAgreeWrap>
                  <CheckboxWrap>
                    <Checkbox
                      key="termsOfUseAllAgree"
                      defaultValue={false}
                      type="checkbox"
                      name="termsOfUseAllAgree"
                      handleClick={handleTermsOfUseAllAgree}
                      isChecked={termsOfUseAllAgree}
                    >
                      <CheckboxText>
                        주문 상품정보 및 전자금융거래 이용약관에 모두 동의하십니까?
                      </CheckboxText>
                    </Checkbox>
                  </CheckboxWrap>
                  <CheckboxWrap>
                    <Checkbox
                      key="privacyAllAgree"
                      defaultValue={false}
                      type="checkbox"
                      name="privacyAllAgree"
                      handleClick={handlePrivacyAllAgree}
                      isChecked={privacyAllAgree}
                    >
                      <CheckboxText>
                        개인정보 수집·이용 및 제3자 제공에 모두 동의하십니까?
                      </CheckboxText>
                    </Checkbox>
                  </CheckboxWrap>
                  <CheckboxWrap>
                    <Checkbox
                      key="withdrawOrder"
                      defaultValue={false}
                      type="checkbox"
                      name="withdrawOrder"
                      handleClick={handleWithdrawOrder}
                      isChecked={withdrawOrder}
                      noalignCenter={true}
                    >
                      <CheckboxText>
                        실제 제품 배송시점이 카드사별 가승인 최대기간을 초과할 경우 해당 주문에 대해
                        재결제를 요청드릴 수 있으며, 재결제가 이루어 지지 않을 경우 주문 취소 될 수
                        있음에 동의하십니까? (카드사별 가승인 최대기간 : 삼성/현대/하나 87일, 그외
                        57일)
                      </CheckboxText>
                    </Checkbox>
                  </CheckboxWrap>
                </AgreeTermsWrap>
              )}
              {!moid && (
                <PurchaseButton onClick={handleSubmit}>
                  {product?.price?.toLocaleString()}원 결제하기
                </PurchaseButton>
              )}
              {!!moid && paymentData?.status === 'COMPLETED' && !paymentData?.refundDisabled && (
                <PurchaseButton onClick={handleCancelPayment}>반품 요청</PurchaseButton>
              )}
              {!!moid &&
                paymentData?.status === 'REQUEST_CANCEL' &&
                !paymentData?.refundDisabled && (
                  <PurchaseInProgresButton disabled>
                    판매자 반품 요청 확인 중입니다.
                  </PurchaseInProgresButton>
                )}
              {!!moid && paymentData?.status === 'CANCELLED' && !paymentData?.refundDisabled && (
                <PurchaseButton disabled>결제 취소 신청 완료</PurchaseButton>
              )}
              {!!moid && !!paymentData?.refundDisabled && (
                <PurchaseButton disabled>취소 불가</PurchaseButton>
              )}
              {!!moid && (
                <RefundInfo>
                  <RefundInfoTitle>[ 교환/반품 안내 ]</RefundInfoTitle>
                  <br />
                  <RefundInfoTitle>· 교환/반품 기간</RefundInfoTitle>
                  <br />
                  - 단순변심에 의한 상품의 교환・반품은 불가능합니다.
                  <br />
                  - 휴대폰 제품의 교환은 서비스센터에서 발급받은 불량판정서 지참시 배송완료+7일
                  가능합니다.
                  <br /> - 제품을 교환/반품 하기 전 고객센터로 미리 연락을 주셔야 합니다.
                  <br /> - 제품 자체에 하자가 있는 경우 삼성전자 서비스센터 (1588-3366)로 접수하시면
                  A/S 기사의 판정을 거친 후 소비자분쟁해결기준에 의거하여 삼성전자서비스 센터를 통해
                  교환/환불/수리해드립니다.
                  <br />
                  <br />
                  <RefundInfoTitle>· 교환/반품 배송비</RefundInfoTitle>
                  <br />
                  구매하신 상품의 교환•반품을 하시는 경우에는 상품 등의 반환에 필요한
                  비용(3,500원)율 고객님이 부담 하셔야 합니다. 단, 고객님이 받으신 상품 등의 내용이
                  표시・광고 내용과 다르거나 제품하자・오배송 등 계약 내용과 다르게 이행되어
                  교환/반품을 하시는 경우에는, 교환・반품 배송비는 무료입니다.
                  <br />
                  <br />
                  <RefundInfoTitle>· 교환/반품 안내</RefundInfoTitle>
                  <br />
                  지앤더블유랩 주식회사 / 문의전화 : 1588-3366
                </RefundInfo>
              )}
            </RightWrap>
          </FlexWrap>
        </Container>
      </Wrap>
      <TermsPopUp visible={showTermsPopUp} handleClose={() => setShowTermsPopUp(false)} />
    </PageLayout>
  );
};

const Wrap = styled.div``;

const Container = styled.div`
  width: 1070px;
  margin: 0 auto;
  padding: 70px 0 0 0;

  @media ${theme.laptop} {
    width: 100vw;
  }

  @media ${theme.mobile} {
    width: 100vw;
  }
`;

const Title = styled.p`
  font-size: 24px;
  color: #000000;
  font-family: nanumGothicEB;

  @media ${theme.laptop} {
    width: 320px;
    margin: 0 auto;
  }

  @media ${theme.mobile} {
    width: 320px;
    margin: 0 auto;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 45px 0 0 0;

  @media ${theme.laptop} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${theme.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LeftWrap = styled.div`
  width: 670px;

  @media ${theme.laptop} {
    width: 320px;
  }

  @media ${theme.mobile} {
    width: 320px;
  }
`;

const RefundInfo = styled.div`
  background: #4ca1af20;
  color: #666666;
  font-size: 12px;
  font-family: 'nanumGothic';
  padding: 26px 15px;
  border-radius: 20px;
  margin-top: 30px;
`;

const RefundInfoTitle = styled.div`
  font-family: 'nanumGothicEB';
`;

const RightWrap = styled.div`
  width: 320px;
`;

const Label = styled.p`
  font-size: 16px;
  color: #000000;
  font-family: nanumGothicEB;
  border-bottom: 2px solid #000000;
  padding: 0 0 10px 0;
`;

const RewardTxt = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  font-family: nanumGothicB;

  @media ${theme.laptop} {
    margin-top: 18px;
  }

  @media ${theme.mobile} {
    margin-top: 18px;
  }
`;

const ProductWrap = styled.div``;

const ProductContainer = styled.div`
  margin: 13px 0 0 0;
  display: flex;
  align-items: center;

  @media ${theme.laptop} {
    flex-direction: column;
    @media ${theme.laptop} {
      margin: 30px 0 0 0;
    }
  }

  @media ${theme.mobile} {
    flex-direction: column;
    @media ${theme.mobile} {
      margin: 30px 0 0 0;
    }
  }
`;

const ProductImg = styled.img`
  width: 138px;
  height: 181px;
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 20px;
  }

  @media ${theme.mobile} {
    margin: 20px;
  }
`;

const ProductFImg = styled.img`
  width: 137px;
  height: 180px;
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 20px;
  }

  @media ${theme.mobile} {
    margin: 20px;
  }
`;

const ProductSImg = styled.img`
  width: 132px;
  height: 172px;
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 20px;
  }

  @media ${theme.mobile} {
    margin: 20px;
  }
`;

const ProductS23FEImg = styled.img`
  width: 144px;
  height: 225px;
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 20px;
  }

  @media ${theme.mobile} {
    margin: 20px;
  }
`;

const Product5Img = styled.img`
  width: 144px;
  height: 145px;
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 20px;
  }

  @media ${theme.mobile} {
    margin: 20px;
  }
`;

const ProductDetail = styled.div`
  margin: 35px 0 0 75px;

  @media ${theme.laptop} {
    margin: 40px 0 0 0;
    width: 320px;
  }

  @media ${theme.mobile} {
    margin: 40px 0 0 0;
    width: 320px;
  }
`;

const SpecFlexBox = styled.div`
  width: ${(props) => props.width}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const SpecColorItem = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const SpecOuterColor = styled.div`
  margin: 0 8px 0 8px;
  width: 34px;
  height: 34px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.selected &&
    css`
      border: 3px solid black;
    `};
`;

const SpecColor = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 20px;
  border: 3px solid #ffffff;
  cursor: pointer;
  background-color: ${(props) => props.colorHex || 'white'};
`;

const ProductSubText = styled.p`
  font-family: nanumGothicR;
  font-size: 12px;
  color: #666666;
  margin: 10px 0 0 0;
`;

const ProductSubTextBlue = styled(ProductSubText)`
  font-family: nanumGothicEB;
  color: #2c81fb;
  margin: 0 0 10px 0;
`;

const ProductSubTextSpec = styled(ProductSubText)`
  margin: 30px 0 0 0;
`;

const ProductSubTextSpec2 = styled(ProductSubTextSpec)`
  margin: 6px 0 0 0;
  text-align: center;
`;

const ProductSubStrong = styled.span`
  font-family: nanumGothicR;
  font-size: 12px;
  color: #666666;
  margin: 10px 0 0 0;
  font-family: nanumGothicB;
  font-size: 12px;
  color: #000;
`;

const ProductTitleWrap = styled.div`
  display: flex;
  margin: 0 0 10px 0;

  @media ${theme.laptop} {
    flex-direction: column;
  }

  @media ${theme.mobile} {
    flex-direction: column;
  }
`;

const ProductName = styled.div``;

const ProductTitle = styled.p`
  font-family: nanumGothicEB;
  color: #292d32;
  font-size: 20px;
`;

const ProductQuantity = styled(ProductTitle)`
  font-family: nanumGothicR;
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 10px 0 0 0;
  }

  @media ${theme.mobile} {
    margin: 10px 0 0 0;
  }
`;

const ProductPrice = styled(ProductTitle)`
  margin: 0 0 0 40px;

  @media ${theme.laptop} {
    margin: 10px 0 0 0;
  }

  @media ${theme.mobile} {
    margin: 10px 0 0 0;
  }
`;

const DeliveryWrap = styled.div`
  margin: 143px 0 0 0;

  @media ${theme.laptop} {
    margin: 70px 0 0 0;
  }

  @media ${theme.mobile} {
    margin: 70px 0 0 0;
  }
`;

const InputInfoWrap = styled.div`
  padding: 40px 50px 40px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;

  @media ${theme.laptop} {
    flex-direction: column;
    padding: 40px 0;
  }

  @media ${theme.mobile} {
    flex-direction: column;
    padding: 40px 0;
  }
`;

const InputTitle = styled.p`
  width: 100px;
  font-family: nanumGothicB;
  color: #000000;
  font-size: 16px;

  @media ${theme.laptop} {
    margin: 0 0 20px 0;
    font-size: 16px;
  }

  @media ${theme.mobile} {
    margin: 0 0 20px 0;
    font-size: 16px;
  }
`;

const InputWrap = styled.div``;

const InputBox = styled.div`
  display: flex;

  & + & {
    margin: 25px 0 0 0;
  }
`;

const InputLabel = styled.p`
  font-family: nanumGothicB;
  color: #666666;
  font-size: 16px;
  width: 120px;

  @media ${theme.laptop} {
    width: 100px;
    font-size: 15px;
  }

  @media ${theme.mobile} {
    width: 100px;
    font-size: 15px;
  }
`;

const Input = styled.input`
  width: 320px;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  font-size: 16px;
  padding: 0 0 10px 0;
  font-family: nanumGothicB;
  -webkit-appearance: none;
  &::placeholder {
    color: #b3b3b3;
  }

  @media ${theme.laptop} {
    width: 210px;
    font-size: 15px;
  }

  @media ${theme.mobile} {
    width: 210px;
    font-size: 15px;
  }
`;

const AddressWrap = styled.div``;

const PostCodeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 320px;

  @media ${theme.laptop} {
    width: 210px;
  }

  @media ${theme.mobile} {
    width: 210px;
  }
`;

const PostCode = styled.p`
  width: 210px;
  border-bottom: 1px solid #b3b3b3;
  font-family: nanumGothicB;
  color: #b3b3b3;
  font-size: 16px;
  height: 30px;
  ${(props) =>
    props.active === true &&
    css`
      color: #000000;
    `}

  @media ${theme.laptop} {
    width: 100px;
    font-size: 15px;
  }

  @media ${theme.mobile} {
    width: 100px;
    font-size: 15px;
  }
`;

const AddressButton = styled.button`
  width: 97px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #292d32;
  background-color: #ffffff;
  margin-top: -7px;
  font-family: nanumGothicB;
  color: #292d32;
  font-size: 14px;
`;

const BasicAddress = styled.p`
  border-bottom: 1px solid #b3b3b3;
  font-family: nanumGothicB;
  color: #b3b3b3;
  font-size: 16px;
  height: 30px;
  margin: 32px 0;
  overflow-x: scroll;
  width: 320px;
  white-space: nowrap;

  ${(props) =>
    props.active === true &&
    css`
      color: #000000;
    `}

  @media ${theme.laptop} {
    width: 210px;
    font-size: 15px;
  }

  @media ${theme.mobile} {
    width: 210px;
    font-size: 15px;
  }
`;

const SelectOptionWrap = styled.div`
  width: 320px;
  margin: 32px 0 0 0;

  @media ${theme.laptop} {
    width: 210px;
  }

  @media ${theme.mobile} {
    width: 210px;
  }
`;

const DeliveryInfo = styled.ul`
  padding: 0;
  margin: 20px 0 0 97px;
`;

const DeliveryInfoList = styled.li`
  position: relative;
  list-style: none;
  font-family: nanumGothicB;
  color: #666666;
  font-size: 12px;

  & + & {
    margin: 10px 0 0 0;
  }

  &::before {
    content: '※';
    position: absolute;
    top: -1px;
    left: -16px;
  }
`;

const PaymentMethodWrap = styled.div`
  margin: 88px 0 70px 0;
`;

const PaymentMethodContainer = styled.div`
  margin: 40px 0 0 17px;

  @media ${theme.laptop} {
    margin: 0;
  }

  @media ${theme.mobile} {
    margin: 0;
  }
`;

const PaymentMethodTabWrap = styled.div`
  display: flex;
`;

const PaymentMethodTab = styled.div`
  cursor: pointer;
  width: 280px;
  height: 34px;
  border-radius: 25px;
  background-color: #e6e6e6;
  font-family: nanumGothicEB;
  color: #666666;
  font-size: 14px;
  text-align: center;
  padding: 8px 0;

  & + & {
    margin: 0 0 0 10px;
  }

  ${(props) =>
    props.active === true &&
    css`
      background-color: #292d32;
      color: #ffffff;
    `}

  @media ${theme.laptop} {
    margin: 40px auto 0;
  }

  @media ${theme.mobile} {
    margin: 40px auto 0;
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;

  @media ${theme.laptop} {
    flex-direction: column;
    justify-content: center;
  }

  @media ${theme.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 15px;
`;

const CardImg = styled.img`
  width: 50px;
  height: 50px;
`;

const CardTxt = styled.div`
  font-family: nanumGothicB;
  color: #000000;
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
`;

const CardBox = styled.div`
  width: 150px;
  height: 103px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 12px 0 12px 0;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InstallmentPeriodWrap = styled.div`
  width: 320px;
  margin-left: 20px;
  margin-top: 100px;

  @media ${theme.laptop} {
    margin-left: 0;
  }

  @media ${theme.mobile} {
    margin-left: 0;
  }
`;

const CardDescription = styled.ul`
  font-family: nanumGothicB;
  color: #b3b3b3;
  margin-top: 16px;

  @media ${theme.laptop} {
    flex-direction: column;
    justify-content: center;
  }

  @media ${theme.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

const CardDescriptionItem = styled.li`
  list-style: unset;
`;

const PurchaseNoticeWrap = styled.div`
  margin: 70px 0 0 0;
`;

const PurchaseNoticeContainer = styled.div`
  padding: 40px 0 70px 0;
  display: flex;
  justify-content: space-between;
`;

const PurchaseItemWrap = styled.div`
  display: flex;
`;

const PurchaseItemLabel = styled.p`
  width: 140px;
  color: #666666;
  font-family: nanumGothicR;
  font-size: 14px;
`;

const PurchaseItem = styled(PurchaseItemLabel)``;

const ShowMoreButton = styled.button`
  background-color: transparent;
  font-size: 12px;
  border-bottom: 1px solid #292d32;
  font-size: 12px;
  color: #292d32;
  font-family: nanumGothicB;
  padding: 0;
`;

const PaymentInfoWrap = styled.div``;

const PaymentInfoContainer = styled.div`
  padding: 45px 0;
`;

const PaymentInfoBox = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin: 30px 0 0 0;
  }
`;

const PaymentInfoLabel = styled.p`
  font-size: 16px;
  color: #b3b3b3;
  font-family: nanumGothicB;
  width: 80px;
  text-align: left;
`;

const PaymentInfo = styled(PaymentInfoLabel)`
  color: #000000;
  width: 100px;
  text-align: right;
  font-family: nanumGothicEB;
`;

const PaymentAmountWrap = styled.div`
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding: 25px 0 20px 0;
`;

const PaymentAmountLabel = styled.p`
  font-size: 20px;
  color: #000000;
  font-family: nanumGothicEB;
`;

const PaymentAmount = styled(PaymentAmountLabel)`
  margin: 20px 0 0 0;
  font-size: 36px;
  text-align: right;
`;

const AgreeTermsWrap = styled.div``;

const AgreeTermsLabelWrap = styled.p`
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
`;

const AgreeTermsLabel = styled.p`
  font-size: 20px;
  color: #292d32;
  font-family: nanumGothicEB;
  & > span {
    font-size: 24px;
    color: #e81a0c;
  }
`;

const AgreeTermsShowMore = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const AgreeTermsShowMoreText = styled.p`
  font-size: 14px;
  color: #000000;
  font-family: nanumGothicEB;
  margin: 0 7px 0 0;
`;

const CheckboxAllAgreeWrap = styled.div`
  padding: 28px 0;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxText = styled.p`
  font-size: 15px;
  color: #000000;
  font-family: nanumGothicR;
  line-height: 22px;
  word-break: keep-all;
`;

const CheckboxTextBold = styled(CheckboxText)`
  font-size: 16px;
  font-family: nanumGothicEB;
`;

const CheckboxWrap = styled.div`
  padding: 15px 0;
`;

const CheckboxMultiWrap = styled.div`
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px 10px 20px 24px;
`;

const CheckboxWithButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin: 14px 0 0 0;
  }
`;

const CheckboxTextSmall = styled(CheckboxText)`
  font-size: 12px;
  width: 200px;
`;

const ShowTerms = styled.button`
  font-size: 12px;
  font-family: nanumGothicB;
  border-bottom: 1px solid #000000;
  padding: 0;
  height: 20px;
  background: white;
`;

const PurchaseButton = styled.button`
  height: 68px;
  border-radius: 50px;
  background-color: #2c81fb;
  width: 100%;
  margin: 30px 0 0 0;
  font-size: 24px;
  color: #ffffff;
  font-family: nanumGothicEB;

  @media ${theme.laptop} {
    margin: 50px 0 100px 0;
  }

  @media ${theme.mobile} {
    margin: 50px 0 50px 0;
  }
`;

const PurchaseInProgresButton = styled.button`
  width: 100%;
  height: 80px;
  margin-top: 50px;
  border-radius: 20px;
  border: none;
  background-color: #4ca1af;
  font-size: 16px;
  font-family: nanumGothicB;
  color: white;
`;

export default Payment;
