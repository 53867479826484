import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

import { setPreventScroll, clearPreventScroll } from 'components/utils/usePreventScroll';

import { TbSquareRoundedArrowRightFilled } from 'react-icons/tb';

import popUpBackground from 'resources/popup_black.png';
import popup03 from 'resources/popup_03.png';
import closeButtonImg from 'resources/close_white.png';
import { useMediaQuery } from 'react-responsive';

const ReservationPopUp2 = ({ visible, handleClose, handleCloseDay, handleGoDetail }) => {
  useEffect(() => {
    if (visible) {
      setPreventScroll();
      return () => clearPreventScroll();
    }
  }, [visible]);

  if (!visible) return null;

  const handleGoToNaverMall = () => {
    window.open('https://smartstore.naver.com/sutophone');
  };

  const handleGoToSutoPhoneMall = () => {
    window.open('http://www.sktsmart.com');
  };

  const handleGoToArea1 = () => {
    window.open('http://www.sktsmart.com');
  };

  const handleGoToArea2 = () => {
    window.open('http://url.kr/siaYMd');
  };

  return (
    <Container>
      <DarkBackground />
      <PopupContainer>
        <EventImg src={popup03} alt="event-img" />
        <Area1 onClick={handleGoToArea1} />
        <Area2 onClick={handleGoToArea2} />
        <CloseButton onClick={handleClose}>
          <CloseBtnImg src={closeButtonImg} alt="close-button-img" />
        </CloseButton>
        <CloseOneDayButton onClick={handleCloseDay}>오늘 그만 보기</CloseOneDayButton>
      </PopupContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const PopupContainer = styled.div`
  position: relative;
  width: 920px;
  border-radius: 25px;
  z-index: 10;

  @media ${theme.laptop} {
    width: 450px;
    background-size: cover;
  }

  @media ${theme.mobile} {
    width: 90%;
    background-size: cover;
  }
`;

const EventImg = styled.img`
  display: block;
  width: 800px;
  height: 500px;
  margin: -30px auto;
  cursor: pointer;

  @media ${theme.laptop} {
    width: 100%;
    height: auto;
    margin: 120px auto 50px;
    object-fit: cover;
  }

  @media ${theme.mobile} {
    width: 100%;
    height: auto;
    margin: 120px auto 50px;
    object-fit: cover;
  }
`;

const Area1 = styled.div`
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 80px;
  top: 120px;
  cursor: pointer;

  @media ${theme.laptop} {
    top: 200px;
  }

  @media ${theme.mobile} {
    top: 200px;
    height: 50px;
  }
`;
const Area2 = styled.div`
  background-color: transparent;
  position: absolute;
  top: 240px;
  width: 100%;
  height: 80px;
  cursor: pointer;

  @media ${theme.laptop} {
    top: 240px;
    right: 0%;
  }

  @media ${theme.mobile} {
    top: 250px;
    height: 50px;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  top: -20px;
  right: 60px;

  @media ${theme.laptop} {
    top: 120px;
    right: 0%;
  }

  @media ${theme.mobile} {
    top: 120px;
    right: 10%;
  }
`;

const CloseBtnImg = styled.img`
  widdth: 50px;
  height: 50px;
  opacity: 0;

  @media ${theme.laptop} {
    right: 0;
  }

  @media ${theme.mobile} {
    right: 0;
    width: 25px;
    height: 25px;
  }
`;

const CloseOneDayButton = styled.button`
  width: 136px;
  height: 32px;
  background-color: transparent;
  border-radius: 25px;
  color: transparent;
  position: absolute;
  top: -10px;
  left: 80px;
  font-family: nanumGothicB;
  font-size: 16px;

  @media ${theme.laptop} {
    top: 120px;
    left: 0;
  }

  @media ${theme.mobile} {
  }
`;

const GoToNaverMallButton = styled.button`
  width: 136px;
  height: 32px;
  background-color: transparent;
  border-radius: 25px;
  color: transparent;
  position: absolute;
  bottom: 200px;
  right: 120px;
  font-family: nanumGothicB;
  font-size: 16px;

  @media ${theme.laptop} {
    width: 100px;
    top: 65%;
    right: 30px;
  }

  @media ${theme.mobile} {
    width: 50px;
    top: 280px;
    right: 50px;
  }
`;

export default ReservationPopUp2;
