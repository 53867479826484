import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import AuthStore from 'stores/AuthStore';

import { getNewWordsApi, getAdminStatusApi, getAdminDeviceCountApi, getUsersApi } from 'api/API';

class DashboardStore {
  users = [];
  words = [];
  totalWords = 0;
  totalUsersCount = 0;
  adminstats = {};
  adminDeviceCount = [];

  listSettingData = [
    {
      id: 1,
      list: 'No.',
    },
    {
      id: 2,
      list: 'ID',
    },
    {
      id: 3,
      list: '이름',
    },
    {
      id: 4,
      list: '학교',
    },
    {
      id: 5,
      list: '학년',
    },
    {
      id: 6,
      list: '시작일',
    },
    {
      id: 7,
      list: '만점횟수',
    },
    {
      id: 8,
      list: '정답률',
    },
    {
      id: 9,
      list: '오답률',
    },
    {
      id: 10,
      list: '잠금해제 횟수',
    },
    {
      id: 11,
      list: '잠금해제 평균',
    },
    {
      id: 12,
      list: 'Grade',
    },
    {
      id: 13,
      list: 'Class',
    },
  ];

  constructor() {
    makeAutoObservable(this);
    this.authStore = AuthStore;
  }

  setListSettingData = (param) => {
    this.listSettingData = param;
    console.log(param, 'param');
  };

  getUsers = async (keyword, pageNumber, pageSize) => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    getUsersApi(keyword, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.users = [...res.data.content];
          this.totalUsersCount = res.data.totalElements;
        });
      })
      .catch((e) => console.log(e));
  };

  getNewWords = async (levelType, keyword, pageNumber, pageSize) => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    getNewWordsApi(levelType, keyword, pageNumber, pageSize)
      .then((res) => {
        runInAction(() => {
          this.words = [...res.data.content];
          this.totalWords = res.data.totalElements;
          console.log(res.data);
        });
      })
      .catch((e) => console.log(e));
  };

  getAdminStatus = async () => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    getAdminStatusApi()
      .then((res) => {
        runInAction(() => {
          console.log(res.data);
          this.adminstats = res.data;
        });
      })
      .catch((e) => console.log(e));
  };

  getAdminDeviceCount = async () => {
    if (!this.authStore.isAuthenticated()) {
      await this.authStore.refreshToken(this.authStore.getRefreshToken());
    }
    getAdminDeviceCountApi()
      .then((res) => {
        runInAction(() => {
          console.log(res.data);
          this.adminDeviceCount = res.data;
        });
      })
      .catch((e) => console.log(e));
  };
}

const dashboardStore = new DashboardStore();

export default dashboardStore;
