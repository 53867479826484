import React, { useState } from 'react';
import styled from 'styled-components';

import appIcon from 'resources/app_icon.png';

import stores from 'stores';

const Main = () => {
  const { authStore } = stores;
  const [inputs, setInputs] = useState({ loginId: '', password: '' });
  const { loginId, password } = inputs;
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const handleLogin = () => {
    if (!loginId || !password) {
      alert('계정 정보를 입력해 주세요.');
      return;
    }
    authStore.signIn({ username: loginId, password: password });
  };
  return (
    <Wrap>
      <Content>
        <Title>G&W</Title>
        <AppIcon src={appIcon} alt="app-icon" />
        <LoginWrap>
          <InputWrap>
            <InputBox>
              <Label>아이디</Label>
              <Input name="loginId" value={loginId} onChange={onChange} />
            </InputBox>
            <InputBox>
              <Label>비밀번호</Label>
              <Input name="password" value={password} onChange={onChange} type="password" />
            </InputBox>
          </InputWrap>
          <LoginButton onClick={handleLogin}>로그인</LoginButton>
        </LoginWrap>
      </Content>
      <Footer>G&W LAB</Footer>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  width: 500px;
`;

const Title = styled.p`
  font-size: 36px;
  color: #666666;
  font-family: helveticaNeueB;
  text-align: center;
`;

const AppIcon = styled.img`
  width: 142px;
  height: 142px;
  margin: 10px auto 0;
  display: block;
`;

const LoginWrap = styled.div`
  display: flex;
  margin: 70px 0 0 0;
  align-items: center;
`;

const InputWrap = styled.div``;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 15px;
  }
`;

const Label = styled.p`
  font-family: nanumGothicB;
  font-size: 16px;
  margin: 0 10px 0 0;
  width: 65px;
  text-align: center;
`;

const Input = styled.input`
  width: 314px;
  height: 40px;
  border: 1px solid #a3cac4;
  border-radius: 5px;
  background-color: #ffffff;
  font-family: nanumGothicB;
  font-size: 16px;
  padding: 0 10px;
  -webkit-appearance: none;
`;

const LoginButton = styled.button`
  width: 95px;
  height: 95px;
  background-color: #4ca1af;
  font-family: nanumGothicB;
  color: #ffffff;
  font-size: 20px;
  border-radius: 10px;
  margin: 0 0 0 15px;
`;

const Footer = styled.p`
  font-size: 12px;
  font-family: helveticaNeueB;
  color: #666666;
  position: fixed;
  bottom: 35px;
  text-align: center;
`;

export default Main;
