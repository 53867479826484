import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme, { fonts } from 'styles/theme';

import checkIcon from 'resources/ic_check.png';
import checkIconBlue from 'resources/ic_check_blue.png';

const Checkbox = ({
  defaultValue,
  id,
  type,
  name,
  handleClick,
  isChecked,
  children,
  warning,
  size,
  noalignCenter,
  ...rest
}) => {
  return (
    <CheckboxWrapper {...rest}>
      <CheckBoxLabel noalignCenter={noalignCenter}>
        <CheckBoxInput
          defaultValue={defaultValue}
          id={id}
          name={name}
          type={type}
          onChange={handleClick}
          checked={isChecked}
        />
        <CheckBoxIcon checked={isChecked} warning={warning} size={size}>
          {isChecked && <CheckBoxIconImg src={checkIcon} alt="check-icon" size={size} />}
        </CheckBoxIcon>
        {children && <CheckBoxText checked={isChecked}>{children}</CheckBoxText>}
      </CheckBoxLabel>
    </CheckboxWrapper>
  );
};

const CheckBoxSpan = styled.span`
  display: inline-block;
  transform: translate3d(0, 0, 0);
`;

const CheckBoxIcon = styled(CheckBoxSpan)`
  position: relative;
  width: 26px;
  height: 26px;
  border: 2px solid #b3b3b3;
  border-radius: 20px;
  vertical-align: middle;
  transition: all 0.2s ease;
  ${(props) =>
    props.checked === true &&
    css`
      background-color: #2c81fb;
      border: 2px solid #2c81fb;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      width: 20px;
      height: 20px;
    `}
`;

const CheckBoxLabel = styled.label`
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-weight: 400;
  line-height: 19px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${(props) =>
    props.noalignCenter === true &&
    css`
      align-items: flex-start;
    `}
`;

const CheckBoxText = styled(CheckBoxSpan)`
  display: inline-block;
  margin: 0 0 0 13px;
  font-family: nanumGothicR;
  font-size: 18px;
  color: #292d32;
  flex: 1;

  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

const CheckBoxInput = styled.input`
  display: none;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  & + & {
    margin: 12px 0 0 0;
  }
`;

const CheckBoxIconImg = styled.img`
  width: 14px;
  height: 12px;
  position: absolute;
  top: 7px;
  left: 6px;

  ${(props) =>
    props.size === 'small' &&
    css`
      width: 10px;
      height: 8px;
      top: 7px;
      left: 5px;
    `}
`;

export default Checkbox;

export const SquareCheckbox = ({
  defaultValue,
  id,
  type,
  name,
  handleClick,
  isChecked,
  children,
  warning,
  size,
  noalignCenter,
  ...rest
}) => {
  return (
    <CheckboxWrapper {...rest}>
      <CheckBoxSquareLabel noalignCenter={noalignCenter}>
        <CheckBoxInput
          defaultValue={defaultValue}
          id={id}
          name={name}
          type={type}
          onChange={handleClick}
          checked={isChecked}
        />
        <CheckBoxIconSquare checked={isChecked}>
          {isChecked && <CheckBoxIconSquareImg src={checkIconBlue} alt="check-icon" />}
        </CheckBoxIconSquare>
        {children && <CheckBoxSquareText checked={isChecked}>{children}</CheckBoxSquareText>}
      </CheckBoxSquareLabel>
    </CheckboxWrapper>
  );
};

const CheckBoxIconSquare = styled(CheckBoxSpan)`
  position: relative;
  width: 16px;
  height: 16px;
  border: 2px solid #b3b3b3;
  border-radius: 5px;
  vertical-align: middle;
  background-color: #ffffff;
  transition: all 0.2s ease;
  ${(props) =>
    props.checked === true &&
    css`
      background-color: #ffffff;
      border: 2px solid #b3b3b3;
    `}
`;

const CheckBoxIconSquareImg = styled.img`
  width: 10px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 3px;
`;

const CheckBoxSquareLabel = styled(CheckBoxLabel)`
  line-height: 16px;
`;

const CheckBoxSquareText = styled(CheckBoxSpan)`
  display: inline-block;
  margin: 0 0 0 10px;
  font-family: nanumGothicB;
  font-size: 14px;
  color: #666666;
  line-height: 16px;
`;
