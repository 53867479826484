import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import setAuthToken from 'api/setAuthtoken';
import { Cookies } from 'react-cookie';
import { jwtInterceptor } from 'api/interceptor';

import { signInApi, refreshTokenApi } from 'api/API';

class AuthStore {
  cookies = new Cookies();
  loading = false;
  userInfo = {};

  constructor() {
    makeAutoObservable(this);
  }

  getCookie = (name) => {
    return this.cookies.get(name);
  };

  setCookie = (name, value) => {
    if (process.env.REACT_APP_MODE === 'production') {
      this.cookies.set(name, value, {
        path: '/',
        // secure: true, // todo enable
        // httpOnly: true,
      });
    } else {
      this.cookies.set(name, value, {
        path: '/',
      });
    }
  };

  getRefreshToken = () => {
    return this.cookies.get('refreshToken');
  };

  setSignInData = (data) => {
    const { accessToken, refreshToken, accessTokenExpireTime } = data;
    setAuthToken(accessToken);
    localStorage.setItem('accessTokenExpireTime', accessTokenExpireTime);
    this.setCookie('refreshToken', refreshToken);
  };

  removeSignInData = () => {
    localStorage.removeItem('accessTokenExpireTime');
    this.cookies.remove('refreshToken', { path: '/' });
  };

  isAuthenticated = () => {
    return axios.defaults.headers.common['Authorization'] !== undefined;
  };

  toggleLoading = () => {
    this.loading = !this.loading;
  };

  signIn = async (body) => {
    this.toggleLoading();
    this.signInError = false;
    await signInApi(body)
      .then((res) => {
        this.setSignInData(res.data);
        window.location.href = '/admin/dashboard';
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.signInError = true;
          alert('계정정보가 올바르지 않습니다.');
        }
        this.toggleLoading();
      });
  };

  refreshToken = async (token) => {
    await refreshTokenApi(token)
      .then((res) => {
        this.setSignInData(res.data);
        jwtInterceptor();
      })
      .catch((error) => {
        console.log(error);
        this.signOut();
      });
  };

  signOut = (param) => {
    runInAction(() => {
      this.removeSignInData();
      if (param === 'auto') {
        window.location.href = '/admin/login';
      } else window.location.replace('/admin/login');
      // window.location.reload();
    });
  };
}

const authStore = new AuthStore();

export default authStore;
